const url = window.url ? window.url : process.env.REACT_APP_API_URL as string;


export const Loading = (props: any) => {
  return (
    <div className={
      props.classes ?? `flex align-items-center justify-content-center w-full h-8rem`}>
      <i
        className="pi pi-spin pi-spinner bancor-color mr-3"
        style={{ fontSize: '3rem' }}
      ></i>
      <img className="w-6rem" src={url + '/assets/appto.svg'} alt="" />
    </div>
  );
};
