import { Button } from './Button';
import { MenuItem } from './MenuItem';
import { Menubar } from 'primereact/menubar';
import './Header.scss';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import { SecondInbursementForm } from '../home/SecondInbursementForm';
import { useLocation, useNavigate } from 'react-router-dom';

const url = window.url ? window.url : (process.env.REACT_APP_API_URL as string);

const ClickeableMenuItem = (item: any) => {
  return (
    <a
      onClick={(e) => {
        e.preventDefault();
        item.onClick();
      }}
      href=""
      className="p-menuitem-link"
      role="menuitem"
    >
      <span className="p-menuitem-text">{item.label}</span>
    </a>
  );
};

const activeItemStyle = { backgroundColor: '#dbdfdd' }

const MENU_KEYS = {
  CALIFICACION: 'calificacion',
  COMPRAR: 'comprar',
  CONSTRUIR: 'construir',
  DESARROLLISTA: 'desarrollista',
  CERTIFICACION: 'certificación'
}

const ITEM_KEYS = {
  COMPRAR_CREDITLINE: "comprarCreditLine",
  REQUEST_BUILD: "requestBuild",
  REQUEST_REFORM: "requestReform",
  REQUEST_FINISH: "requestFinish",
  REQUEST_BUY: "requestBuy",
  REQUEST_DEVELOP: "requestDevelop",
  REQUEST_DEVELOP_BUY: "requestDevelopBuy",
  REQUEST_SEED: "requestSeed",
  QUALIFY_BUY: "qualifyBuy",
  QUALIFY_BUILD_REFORM: 'qualifyBuildReform',
  QUALIFY_DEVELOP: 'qualifyDevelop',
  BUILD_CREDITLINE: 'buildCreditLine',
  BUILD_PAYBACK: 'buildPayback',
  DEVELOP_CREDITLINE: 'developCreditLine',
  PRE_QUALIFY_BUY: "preQualifyBuy",
  PRE_QUALIFY_BUILD_REFORM: 'preQualifyBuildReform',
  PRE_QUALIFY_DEVELOP: 'preQualifyDevelop',
  DEVELOP_PAYBACK: 'developPayback',
  AGENCY: 'agency',
  OWNER: 'owner',
  PREQUALIFY: 'preQualify'
}

const activeByPathname: any = {
  "/request/build": {
    activeMenu: MENU_KEYS.CONSTRUIR,
    itemKey: ITEM_KEYS.REQUEST_BUILD
  },
  "/request/reform": {
    activeMenu: MENU_KEYS.CONSTRUIR,
    itemKey: ITEM_KEYS.REQUEST_REFORM
  },
  "/request/finish": {
    activeMenu: MENU_KEYS.CONSTRUIR,
    itemKey: ITEM_KEYS.REQUEST_FINISH
  },
  "/request/qualify?loanType=build-reform": {
    activeMenu: MENU_KEYS.CONSTRUIR,
    itemKey: ITEM_KEYS.QUALIFY_BUILD_REFORM
  }, 
  "/request/seed": {
    activeMenu: MENU_KEYS.CONSTRUIR,
    itemKey: ITEM_KEYS.REQUEST_SEED
  },
  "/request/buy": {
    activeMenu: MENU_KEYS.COMPRAR,
    itemKey: ITEM_KEYS.REQUEST_BUY
  },
  "/request/develop": {
    activeMenu: MENU_KEYS.DESARROLLISTA,
    itemKey: ITEM_KEYS.REQUEST_DEVELOP
  },
  "/request/develop-buy": {
    activeMenu: MENU_KEYS.DESARROLLISTA,
    itemKey: ITEM_KEYS.REQUEST_DEVELOP_BUY
  },
  "/creditLines/buy": {
    activeMenu: MENU_KEYS.COMPRAR,
    itemKey: ITEM_KEYS.COMPRAR_CREDITLINE
  },
  "/creditLines/build": {
    activeMenu: MENU_KEYS.CONSTRUIR,
    itemKey: ITEM_KEYS.BUILD_CREDITLINE
  },
  "/creditLines/develop": {
    activeMenu: MENU_KEYS.DESARROLLISTA,
    itemKey: ITEM_KEYS.DEVELOP_CREDITLINE
  },
  "/propertyRequest/agency": {
    activeMenu: MENU_KEYS.CERTIFICACION,
    itemKey: ITEM_KEYS.AGENCY
  },
  "/propertyRequest/owner": {
    activeMenu: MENU_KEYS.CERTIFICACION,
    itemKey: ITEM_KEYS.OWNER
  },
  "/request/qualify?loanType=buy": {
    activeMenu: MENU_KEYS.COMPRAR,
    itemKey: ITEM_KEYS.QUALIFY_BUY
  },
  "/request/preQualify": {
    activeMenu: MENU_KEYS.CALIFICACION,
    itemKey: ITEM_KEYS.PREQUALIFY
  },
  "/request/preQualify?loanType=buy": {
    activeMenu: MENU_KEYS.COMPRAR,
    itemKey: ITEM_KEYS.PRE_QUALIFY_BUY
  },
  "/request/qualify?loanType=develop": {
    activeMenu: MENU_KEYS.DESARROLLISTA,
    itemKey: ITEM_KEYS.QUALIFY_DEVELOP
  },
  "/request/preQualify?loanType=develop": {
    activeMenu: MENU_KEYS.DESARROLLISTA,
    itemKey: ITEM_KEYS.PRE_QUALIFY_DEVELOP
  },
}

export const Header = () => {
  const { pathname, search } = useLocation()
  const key = !search ? pathname : pathname + search
  const activeMenu = activeByPathname[key]?.activeMenu ?? null
  const selectedItem = activeByPathname[key]?.itemKey ?? null

  const navigate = useNavigate()
  const [showSecondInbursement, setShowSecondInbursement] = useState(false);

  const gotoFaqs = () => {
    navigate('/faq')
  };

  const openSecondInbursement = () => {
    setShowSecondInbursement(true);
  };
  const SecondInbursement = () => {
    return (
      <Dialog header="Solicitá tu segundo desembolso" visible={showSecondInbursement} className="custom-dialog w-full md:w-6" onHide={() => setShowSecondInbursement(false)}>
        <SecondInbursementForm />
      </Dialog>
    );
  };

  const items = [
    {
      label: 'Comprar',
      key: MENU_KEYS.COMPRAR,
      items: [
        {
          key: ITEM_KEYS.COMPRAR_CREDITLINE,
          label: 'Conocé nuestra línea', command: () => {
            navigate('/creditLines/buy')
          },
          style: selectedItem === ITEM_KEYS.COMPRAR_CREDITLINE ? activeItemStyle : {}
        },
        {
          key: ITEM_KEYS.PRE_QUALIFY_BUY,
          label: 'Pedí tu calificación',
          command: () => {
            navigate('/request/preQualify?loanType=buy')
          },
          style: selectedItem === ITEM_KEYS.PRE_QUALIFY_BUY ? activeItemStyle : {}
        },
        {
          label: 'Busca tu casa',
          command: () => {
            window.location.href = '/#encontraTuHogar'
          }
        },
        {
          key: ITEM_KEYS.REQUEST_BUY,
          label: 'Solicitá tu préstamo',
          command: () => {
            navigate('/request/buy')
          },
          style: selectedItem === ITEM_KEYS.REQUEST_BUY ? activeItemStyle : {}
        },
      ],
    },
    {
      key: MENU_KEYS.CONSTRUIR,
      label: 'Construir / Ampliar / Terminar',
      items: [
        {
          key: ITEM_KEYS.BUILD_CREDITLINE,
          label: 'Conocé nuestra línea',
          command: () => {
            navigate('/creditLines/build')
          },
          style: selectedItem === ITEM_KEYS.BUILD_CREDITLINE ? activeItemStyle : {}
        },
        {
          key: ITEM_KEYS.QUALIFY_BUILD_REFORM,
          label: 'Pedí tu calificación',
          command: () => navigate('/request/preQualify?loanType=build-reform'),
          style: selectedItem === ITEM_KEYS.PRE_QUALIFY_BUILD_REFORM ? activeItemStyle : {}
        },        
        {
          key: ITEM_KEYS.REQUEST_BUILD,
          label: 'Solicitá tu préstamo para Construcción',
          command: () => {
            navigate('/request/build')
          },
          style: selectedItem === ITEM_KEYS.REQUEST_BUILD ? activeItemStyle : {}
        },
        {
          key: ITEM_KEYS.REQUEST_REFORM,
          label: 'Solicitá tu préstamo para Ampliación',
          command: () => {
            navigate('/request/reform')
          },
          style: selectedItem === ITEM_KEYS.REQUEST_REFORM ? activeItemStyle : {}
        },
        {
          key: ITEM_KEYS.REQUEST_FINISH,
          label: 'Solicitá tu préstamo para Terminación',
          command: () => {
            navigate('/request/finish')
          },
          style: selectedItem === ITEM_KEYS.REQUEST_FINISH ? activeItemStyle : {}
        },
        {
          key: ITEM_KEYS.BUILD_PAYBACK,
          label: 'Quiero mi desembolso',
          onClick: () => openSecondInbursement(),
          template: ClickeableMenuItem
        },
        {
          key: ITEM_KEYS.REQUEST_SEED,
          label: 'Programa vivienda semilla',
          command: () => {
            navigate('/request/seed')
          },
          style: selectedItem === ITEM_KEYS.REQUEST_SEED ? activeItemStyle : {}
        },
      ],
    },
    {
      key: MENU_KEYS.DESARROLLISTA,
      label: 'Desarrollistas',
      items: [
        {
          key: ITEM_KEYS.DEVELOP_CREDITLINE,
          label: 'Conocé nuestra línea',
          command: () => {
            navigate('/creditLines/develop')
          },
          style: selectedItem === ITEM_KEYS.DEVELOP_CREDITLINE ? activeItemStyle : {}
        },
        {
          key: ITEM_KEYS.PRE_QUALIFY_DEVELOP,
          label: 'Pedí tu calificación',
          command: () => {
            navigate('/request/preQualify?loanType=develop')
          },
          style: selectedItem === ITEM_KEYS.PRE_QUALIFY_DEVELOP ? activeItemStyle : {}
        },        
        {
          label: 'Conocé nuestros convenios',
          url: 'https://www.bancor.com.ar/personas/prestamos/casa-bancor-desarrollistas/',
        },
        { 
          key: ITEM_KEYS.REQUEST_DEVELOP_BUY,
          label: 'Solicitá tu préstamo (Compra)',
          command: () => {
            navigate('/request/develop-buy')
          },
          style: selectedItem === ITEM_KEYS.REQUEST_DEVELOP_BUY ? activeItemStyle : {}
        },
        { 
          key: ITEM_KEYS.REQUEST_DEVELOP,
          label: 'Solicitá tu préstamo (Construcción)',
          command: () => {
            navigate('/request/develop')
          },
          style: selectedItem === ITEM_KEYS.REQUEST_DEVELOP ? activeItemStyle : {}
        },
        { 
          key: ITEM_KEYS.DEVELOP_PAYBACK,
          label: 'Quiero mi desembolso',
          onClick: () => openSecondInbursement(),
          template: ClickeableMenuItem
        },
      ],
    },
    {
      key: MENU_KEYS.CERTIFICACION,
      label: 'Certificá tu inmueble',
      items: [
        { 
          key: ITEM_KEYS.AGENCY,
          label: 'Inmobiliaria',
          command: () => {
            navigate('/propertyRequest/agency')
          },
          style: selectedItem === ITEM_KEYS.AGENCY ? activeItemStyle : {}
        },
        { 
          key: ITEM_KEYS.OWNER,
          label: 'Dueño',
          command: () => {
            navigate('/propertyRequest/owner')
          },
          style: selectedItem === ITEM_KEYS.OWNER ? activeItemStyle : {}
        },
      ],
    },
  ];

  const end = <Button label="Preguntas frecuentes" onClick={gotoFaqs} />;

  return (
    <div style={{ backgroundColor: 'white' }} className="custom-header mx-auto md:w-full navbar-shadow grid grid-nogutter py-3 px-0 md:px-4 mt-0 md:mt-0 lg:fixed lg:px-7">
      <div className="col-12 lg:col-2 flex align-items-center justify-content-center lg:justify-content-start my-4 sm:my-0 lg:pl-2">
        <a href={url + '/'}>
          <img className="brand-logo" src={url + '/assets/appto.svg'} alt="" />
        </a>
      </div>
      <div className="col-12 lg:col-7 flex align-items-center justify-content-between px-2">
        <div className="hidden lg:flex align-items-center">
          {items?.map((item, index) => {
            return <MenuItem activeMenu={activeMenu} item={item} key={index} />;
          })}
        </div>
        <Menubar className="w-full custom-menubar flex lg:hidden px-0" model={items} end={end} />
      </div>
      <div className="lg:col-3 hidden lg:flex justify-content-end lg:pr-2">
        <Button className="right-m-20" label="Preguntas frecuentes" onClick={gotoFaqs} />
      </div>
      <SecondInbursement />
    </div>
  );
};
