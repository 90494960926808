import { useRef } from "react";
import { Form, Row, Col, Typography, Input, Alert, AlertProps, Select, DatePicker, Checkbox } from "antd"
import { useQuery } from '@tanstack/react-query';
import requestApi from "../../../api/requests"
import cuitValidator from "../../../utils/CuitValidator";
import { buildRequiredRule, buildMaxRule, buildEmailRule } from "../../../utils/ruleBuilders";
import { REQUEST_TYPES, FAMILIAR_GROUP_OPTIONS, entityPrefixNames, DOCUMENT_TYPES } from '../../../utils/consts';
import { availableFormFields, formStyles, isFieldVisible } from "../LoanRequest";
import { AddressForm } from "./AddressForm";
import { VisibleFormItem } from "./VisibleFormItem";


const { Item } = Form
const { Title, Text } = Typography


const TITLES_BY_ENTITY = {
  [entityPrefixNames.REQUESTER]: 'Datos de la persona solicitante',
  [entityPrefixNames.COSIGNER_ONE]: 'Garante (Suma ingresos)',
  [entityPrefixNames.COSIGNER_TWO]: 'Garante 2 (Suma ingresos)',
  [entityPrefixNames.SPOUSE]: 'Cónyuge / Conviviente',
}

const TYPE_DOCUMENT_BY_ENTITY = {
  [entityPrefixNames.REQUESTER]: DOCUMENT_TYPES.CUIL,
  [entityPrefixNames.COSIGNER_ONE]: DOCUMENT_TYPES.CUIL,
  [entityPrefixNames.COSIGNER_TWO]: DOCUMENT_TYPES.CUIL,
  [entityPrefixNames.SPOUSE]: DOCUMENT_TYPES.CUIL,
}

const getAlertMessages = (type: string) => {
  switch (type) {
    case REQUEST_TYPES.seed:
      return (
        <Text className="default-font" style={{ fontSize: '13px' }}>
          No registramos una postulación aprobada para ese C.U.I.L. No podrás enviar la solicitud. Por favor comunicate con el Centro de Negocios a:
          <a className="bancor-color" style={{ fontWeight: '600' }} href="mailto:solicitudesappto@bancor.com.ar"> solicitudesappto@bancor.com.ar</a>
        </Text>
      )  
    default:
      return (
        <Text className="default-font" style={{ fontSize: '13px' }}>
          Ya existe una solicitud registrada para este C.U.I.L. No podrás enviar una nueva. Por favor comunicate con el Centro de Negocios a:
          <a className="bancor-color" style={{ fontWeight: '600' }} href="mailto:solicitudesappto@bancor.com.ar"> solicitudesappto@bancor.com.ar</a>
        </Text>
      )
  }
}

export const PersonalDataForm = (props: any) => {
  const { form, type, entityPrefix, isFormBlocked, setIsFormBlocked, invalidCuils } = props

  const cuil = Form.useWatch(`${entityPrefix}Document`, form)
  const spouseIsCoSigner = Form.useWatch(`spouseIsCoSigner`, form)
  const previousCuitRef = useRef<any>(null)

  const validateCuit = (_: any, value: any) => {
    if (!cuitValidator(value)) return Promise.reject('El CUIL no es válido')
    else return Promise.resolve()
  }

  const validateCuitNotRepeated = (fields: any, value: any) => {
    const { field: fieldName } = fields
    const documentFields = [
      `${entityPrefixNames.REQUESTER}Document`,
      `${entityPrefixNames.SPOUSE}Document`,
      `${entityPrefixNames.COSIGNER_ONE}Document`,
      `${entityPrefixNames.COSIGNER_TWO}Document`
    ]
    const cuitFound = Object.entries(form.getFieldsValue(documentFields)).find(([field, cuit]) => cuit === value && field !== fieldName)
    if (cuitFound) return Promise.reject('Este CUIL ya ha sido utilizado en este formulario')
    else return Promise.resolve()
  }

  const { isRefetching, refetch } = useQuery({
    queryKey: ['validateCuil', entityPrefix],
    queryFn: () => requestApi.validateCuit(cuil, type).then(data => data),
    onError: (error: any) => { console.log(error)},
    onSuccess: ({ valid, seedAuthorized }) => {
      if (((!valid && valid !== undefined) || (!seedAuthorized && seedAuthorized !== undefined)) && cuitValidator(cuil)) {
        setIsFormBlocked(({ invalidCuils }: { invalidCuils: string[], blocked: boolean }) => {
          // revisamos si en los cuit no tenemos ya un bloqueo para esta entidad.
          if (!invalidCuils.find((entity: any) => entity === entityPrefix)) invalidCuils.push(entityPrefix)
          return ({ invalidCuils, blocked: true })
        })
      }
      else {
        setIsFormBlocked(({ invalidCuils }: { invalidCuils: string[], blocked: boolean }) => {
          // buscamos para esa entidad si tenía un bloqueo previo
          const invalidCuilsFiltered = invalidCuils.filter((entity: any) => entity !== entityPrefix)
          const blocked = invalidCuilsFiltered.length === 0 ? false : true
          return ({ invalidCuils: invalidCuilsFiltered, blocked })
        })
      }
    },
    initialData: {
      valid: undefined,
      seedAuthorized: undefined
    },
    enabled: false,
    refetchOnWindowFocus: false
  })

  const alertConfig: AlertProps = {
    style: { marginBottom: '10px' },
    message: getAlertMessages(type),
    showIcon: true,
    type: 'warning'
  }

  const isQualifyAndRequester = type === REQUEST_TYPES.qualify && entityPrefix === entityPrefixNames.REQUESTER

  return (
    <Row gutter={[16, 0]} style={{ width: '100%', margin: '20px auto' }}>
      <Title style={formStyles.formTitle} className="default-font bancor-color" level={3}>
        {TITLES_BY_ENTITY[entityPrefix]}
      </Title>
      {entityPrefix === entityPrefixNames.SPOUSE &&
        <Col span={24}>
          <Item
            valuePropName="checked"
            name="spouseIsCoSigner">
            <Checkbox disabled={isFormBlocked}>Es codeudor (Suma ingresos)</Checkbox>
          </Item>
        </Col>
      }
      <Col xs={24} md={12}>
        <Item
          name={`${entityPrefix}LastName`}
          label="Apellido/s"
          rules={[buildRequiredRule('Apellido/s'), buildMaxRule(200)]}>
          <Input disabled={isFormBlocked} placeholder="Apellido/s" />
        </Item>
      </Col>
      <Col xs={24} md={12}>
        <Item
          name={`${entityPrefix}Name`}
          label="Nombre/s"
          rules={[buildRequiredRule('Nombre/s'), buildMaxRule(200)]}>
          <Input disabled={isFormBlocked} placeholder="Nombre/s" />
        </Item>
      </Col>
      <Col xs={24} md={!isQualifyAndRequester ? 12 : 8 } lg={12}>
        <Item
          name={`${entityPrefix}Document`}
          style={{ marginBottom: isFormBlocked ? '6px' : '24px' }}
          label={
            <Text className="default-font">
              Número de C.U.I.L. <Text style={formStyles.helpText}>(Ingrese sólo números)</Text>
            </Text>
          }
          rules={[buildRequiredRule('C.U.I.L.'), { validator: validateCuit }, { validator: validateCuitNotRepeated } ]}>
          <Input
            placeholder="Número de C.U.I.L."
            onChange={e => {
              form.setFieldValue(`${entityPrefix}Document`, e.target.value.replace(/[^0-9]/g, ''))
            }}
            onBlur={e => {
              form.setFieldValue(`${entityPrefix}DocumentType`, TYPE_DOCUMENT_BY_ENTITY[entityPrefix])
              const currentValue = e.target.value
              if (currentValue !== previousCuitRef.current) {
                previousCuitRef.current = currentValue;
                if (cuitValidator(currentValue)) refetch()
              }
            }}
          />
        </Item>
        {invalidCuils.includes(entityPrefix) && !isRefetching && <Alert { ...alertConfig } />}
      </Col>     
      <Col xs={24} md={!isQualifyAndRequester ? 12 : 8 } lg={isQualifyAndRequester ? 5 : 12}>
        <Item
          name={`${entityPrefix}Birthdate`}
          label="Fecha de nacimiento"
          rules={[buildRequiredRule('Fecha de nacimiento')]}>
          <DatePicker
            placeholder="Fecha de nacimiento"
            disabled={isFormBlocked}
            style={{ width: '100%' }}
            format={['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY']} />
        </Item>
      </Col>
      {(!(entityPrefix === entityPrefixNames.SPOUSE && !spouseIsCoSigner) && type !== REQUEST_TYPES.qualify) &&
        <AddressForm type={type} entityPrefix={entityPrefix} isFormBlocked={isFormBlocked} />
      }
      <VisibleFormItem type={type} fieldName={availableFormFields.FAMILIAR_GROUP} prefix={entityPrefix}>
        <Col xs={24} md={8} lg={isQualifyAndRequester ? 7 : 8}>
          <Item
            name={`${entityPrefix}CivilStatus`}
            label="Integro grupo familiar"
            initialValue={isFieldVisible(availableFormFields.FAMILIAR_GROUP, type, entityPrefix)
              ? FAMILIAR_GROUP_OPTIONS[3].value
              : ''
            }
            rules={[buildRequiredRule('Grupo familiar')]}>
            <Select
              disabled={isFormBlocked}
              options={FAMILIAR_GROUP_OPTIONS}
              onChange={(_, option) => {
                let hasSpouse = false
                if (!Array.isArray(option)) hasSpouse = option.hasSpouse
                form.setFieldValue('hasSpouse', hasSpouse)
              }}
              placeholder="Integro grupo familiar" />
          </Item>
        </Col>
      </VisibleFormItem>
      <VisibleFormItem type={type} fieldName={availableFormFields.EMAIL} prefix={entityPrefix}>
        <Col xs={24} md={16}>
          <Item
            name={`${entityPrefix}Email`}
            label="Correo Electrónico"
            rules={[buildRequiredRule('Email'), buildMaxRule(200), buildEmailRule()]}>
            <Input disabled={isFormBlocked} placeholder="Correo Electrónico" />
          </Item>
        </Col>
      </VisibleFormItem>
      <VisibleFormItem type={type} fieldName={availableFormFields.PHONE} prefix={entityPrefix}>
        <Col xs={24} md={8}>
          <Item
            name={`${entityPrefix}PhoneNumber`}
            label={
              <Text className="default-font" ellipsis={{ tooltip: true }}>
                Teléfono <Text style={formStyles.helpText}>(Ingrese sólo números)</Text>
              </Text>
            }
            rules={[buildRequiredRule('Teléfono'), buildMaxRule(10)]}>
            <Input
              disabled={isFormBlocked}
              placeholder="Teléfono"
              type="number"
              showCount={false}
            />
          </Item>
        </Col>
      </VisibleFormItem>
    </Row>
  )
}