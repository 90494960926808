import axios from 'axios';
const url = window.url ? window.url : process.env.REACT_APP_API_URL as string;

const config = {
  headers: {
    accept: 'application/json, text/plain, */*',
    'accept-language': 'en-US,en;q=0.9',
    'content-type': 'application/json;charset=UTF-8',
  },
  mode: 'cors',
  credentials: 'include',
};

export default {

  sendPreQualify: async (request: any) => {
    const { data } = await axios.post(
      `${url}/prequalify`,
      request,
      config
    );

    return data;
  },
  sendVerificationCode: async (body: { formId: string, requesterEmail: string, recaptchaResponse: string | null }) => {
    const { data } = await axios.post(
      `${url}/prequalify/generateCode`,
      body,
      config
    );
    return data;
    
  },
  validateCode: async (body: { code: string, formId: string, requesterEmail: string }) => {
    const { data } = await axios.post(
      `${url}/prequalify/validateCode`,
      body,
      config
    );

    return data;
  },
  getProductTypes: async () => {
    const { data } = await axios.get(
      `${url}/prequalify/types`,
      config
    );

    return data;
  },
};