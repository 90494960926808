import { useNavigate } from 'react-router-dom';
import './Item.scss';

export const GridItem = ({ item, initial }: any) => {

  const apiUrl = window.url ? window.url : process.env.REACT_APP_API_URL as string;
  const navigate = useNavigate();
  const onClick = () => navigate(`/properties/${item.id}`);

  const currencyFormatter = Intl.NumberFormat('es-AR');

  return (
    <div
      className="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-3 mb-4 cursor-pointer"
      onClick={onClick}
    >
      <div
        className="border-none border-round px-2"
        style={{ backgroundColor: "#F8F8F8" }}>
        <div className='hoverable' style={{ boxShadow: '0px 6px 10px 4px #00000014' }}>
          <div className="flex flex-column align-items-start relative">
            {item.priority > 0 && initial &&
              <div className='highlight-priority absolute flex justify-content-center align-item-center item-label --oportunity'>Oportunidad</div>
            }
            <figure
              className="bg-white grid-item-card overflow-hidden m-0 property-cover w-full flex align-items-center justify-content-center relative">
              <img
                className="w-full"
                src={`${apiUrl}/property/photo/${item.cover}`}
                alt={item.address}
              />
              {item.sold && (
                <div className='absolute flex align-items-center justify-content-center item-label --sold'>Vendida</div>
              )}
              {item.reserved && (
                <div className='absolute flex align-items-center justify-content-center item-label --reserved'>Reservada</div>
              )}
            </figure>
          </div>
          <div
            className="flex flex-column align-items-start justify-content-between p-3 gap-1 card-content"
            style={{ backgroundColor: "white", borderRadius: '0' }}>
            <span className='font-bold uvas-text'>
              {currencyFormatter.format(item.priceLow)} a {currencyFormatter.format(item.priceHigh)} UVAs
            </span>
            <span className="text-sm font-bold capitalize">
              {item.coarseAddress.toLowerCase()}
            </span>
            <span className="text-sm capitalize">{item.city.toLowerCase()}</span>
            <div className="flex align-items-start">
              <div className="flex align-items-center justify-content-start mr-2 gap-2">
                <i className="pi pi-building"></i>
                <p className="m-0">
                  {item.rooms} dormitorio{item.rooms > 1 ? 's' : ''}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
