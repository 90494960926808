import { Form, Col, Typography, Row,  Upload, Button, notification, Alert, Popover, List } from "antd"
import { DownloadOutlined, FilePdfOutlined, InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import './UploadDocumentationForm.scss'
import { ACCEPT_FILE, EMPLOYMENT_TYPES, FAMILIAR_GROUP_OPTIONS, INDEPENDENT_EMPLOYMENT_TYPES, REQUEST_TYPES, entityPrefixNames } from '../../../utils/consts';
import { FORM_LINKS } from "./InitialModal";
import Link from "antd/es/typography/Link";
import { useState } from "react";

const { Item } = Form
const { Text, Title } = Typography

// DOCUMENTATION CONFIG BY TYPES
type DocumentationBlock = {
  blockTitle?: Function
  uploads: {
    label: string | Function,
    name: string,
    required: boolean | Function,
    active: boolean,
    order: number,
    description?: any,
    isVisible?: any,
    availableForms?: any,
    accept: keyof typeof ACCEPT_FILE
  }[]
}

type DocumentationType = {
  [key in keyof typeof REQUEST_TYPES]?: DocumentationBlock[]
}

const CRM_DOCUMENT_TYPES = {
  [REQUEST_TYPES["develop-buy"]]: {
    DNI: "DNI",
    DNI_CONYUGE: "DNI Conyuge",
    DNI_HIJOS: "DNI Hijos",
    LIBRETA_FAMILIA: "Libreta de familia",
    DIR: "DIR",
    DIVORCE_WIDOW: "Sentencia de divorcio / Acta de defuncion",
    INGRESOS: "Ingresos",
    INGRESO_CONYUGE: "Ingresos Conyuge",
    DDJJ_SALUD: "DDJJ Salud",
    EXAMEN_MEDICO: "Examen Medico",
    DOCUMENTACION_DESARROLLISTA: "Documentación aportada por la Empresa Desarrollista"
  },
  [REQUEST_TYPES.develop]: {
    DNI: "DNI",
    LIBRETA_FAMILIA: "Libreta de familia",
    DIR: "DIR",
    INGRESOS: "Ingresos",
    DDJJ_SALUD: "DDJJ Salud",
    EXAMEN_MEDICO: "Examen Medico",
    PLANO_APROBADO: "Plano aprobado",
    BOLETO_VENTA: "Boleto Compra Venta"
  },
  [REQUEST_TYPES.seed]: {
    DNI: "DNI",
    DNI_DUEÑO: 'DNI Dueño del Lote',
    LIBRETA_FAMILIA: 'Libreta de Familia',
    ESCRITURA: "Escritura inscripta",
    PLANO_APROBADO: "Plano aprobado"
  },
  [REQUEST_TYPES.qualify]: {
    DNI: "DNI",
    DNI_CONYUGE: "DNI Conyuge",
    DNI_HIJOS: "DNI Hijos",
    LIBRETA_FAMILIA: "Libreta de familia",
    DIVORCE_WIDOW: "Sentencia de divorcio / Acta de defuncion",
    DNI_CODEUDOR: 'DNI Codeudor',
    INGRESOS: "Ingresos",
    INGRESO_CONYUGE: "Ingresos Conyuge",
    INGRESO_CODEUDOR: "Ingresos Codeudor"
  },
  [REQUEST_TYPES.build]: {
    DNI: "DNI",
    LIBRETA_FAMILIA: "Libreta de Familia",
    DIR: "DIR",
    INGRESOS: "Ingresos",
    DDJJ_SALUD: "DDJJ Salud",
    EXAMEN_MEDICO: 'Examen Medico',
    ESCRITURA: "Escritura inscripta",
    MATRICULA: 'Informe de Matricula',
    PLANCHA_CATASTRAL: 'Plancha catastral',
    CEDULON_RENTAS: 'Cedulón de rentas',
    PLANO_APROBADO: 'Plano aprobado',
    PRESUPUESTO: 'Presupuesto de obra'
  },
  [REQUEST_TYPES.finish]: {
    DNI: "DNI",
    LIBRETA_FAMILIA: "Libreta de Familia",
    DIR: "DIR",
    INGRESOS: "Ingresos",
    DDJJ_SALUD: "DDJJ Salud",
    EXAMEN_MEDICO: 'Examen Medico',
    ESCRITURA: "Escritura inscripta",
    MATRICULA: 'Informe de Matricula',
    PLANCHA_CATASTRAL: 'Plancha catastral',
    CEDULON_RENTAS: 'Cedulón de rentas',
    PLANO_APROBADO: 'Plano aprobado',
    PRESUPUESTO: 'Presupuesto de obra'
  },
  [REQUEST_TYPES.reform]: {
    DNI: "DNI",
    LIBRETA_FAMILIA: "Libreta de Familia",
    DIR: "DIR",
    INGRESOS: "Ingresos",
    DDJJ_SALUD: "DDJJ Salud",
    EXAMEN_MEDICO: 'Examen Medico',
    ESCRITURA: "Escritura inscripta",
    MATRICULA: 'Informe de Matricula',
    PLANCHA_CATASTRAL: 'Plancha catastral',
    CEDULON_RENTAS: 'Cedulón de rentas',
    PLANO_APROBADO: 'Plano aprobado ampliación',
    PRESUPUESTO: 'Presupuesto de obra'
  },
  [REQUEST_TYPES.buy]: {
    DNI: "DNI",
    DNI_CONYUGE: "DNI Conyuge",
    DNI_HIJOS: "DNI Hijos",
    LIBRETA_FAMILIA: "Libreta de familia",
    DIR: "DIR",
    DIVORCE_WIDOW: "Sentencia de divorcio / Acta de defuncion",
    INGRESOS: "Ingresos",
    INGRESO_CONYUGE: "Ingresos Conyuge",
    DDJJ_SALUD: "DDJJ Salud",
    MATRICULA: 'Informe de matrícula'
  },
}

const TITLE_BY_TYPES = {
  [REQUEST_TYPES.qualify]: "Los documentos PDF no deben superar los 50 MB. Deben ser legibles y completos.", 
  default: "Los documentos y fotos no deben superar los 50 MB. Los archivos PDF deben ser legibles y completos."
}

const getEmploymentDocumentationInfo = (watchedFiles: any, entityName: string) => {

  const MESSAGE_BY_TYPES: any = {
    [EMPLOYMENT_TYPES.EMPLOYEE]:
      <Row align="middle">
        <InfoCircleOutlined className="bancor-color" style={{ marginRight: '8px' }}/>
        Recibos de haberes para personas en relación de dependencia:
        <ul style={{ margin: 0, fontSize: '13px' }}>
          <li>Mensualizados: 3 últimos recibos de haberes.</li>
          <li>Quincenales: 6 últimos recibos de haberes.</li>
        </ul>
      </Row>,
    [EMPLOYMENT_TYPES.INDEPENDENT]: {
      [INDEPENDENT_EMPLOYMENT_TYPES.MONOTRIBUTE]:
        <Row align="middle">
          <InfoCircleOutlined className="bancor-color" style={{ marginRight: '8px' }}/>
          Monotributistas:
          <ul style={{ margin: 0, fontSize: '13px' }}>
            <li>Constancia de Opción + Detalle CCMA (Cuenta Corriente de Contribuyentes Monotributistas y Autónomos).</li>
          </ul>
        </Row>,
      [INDEPENDENT_EMPLOYMENT_TYPES.AUTONOMOUS]:
        <Row align="middle">
          <InfoCircleOutlined className="bancor-color" style={{ marginRight: '8px' }}/>
          Autónomos:
          <ul style={{ margin: 0, fontSize: '13px' }}>
            <li>Opción 1: F711 + Detalle CCMA (Cuenta Corriente de Contribuyentes Monotributistas y Autónomos).</li>
            <li>Opción 2: Certificación de ingresos + Manifestación de Bienes + Estado de ingresos y egresos: todo ello firmado por Contador Público y Certificado por C.P.C.E. + Detalle CCMA (Cuenta Corriente de Contribuyentes Monotributistas y Autónomos).</li>
          </ul>
        </Row>
    }
  }

  let message

  if (watchedFiles[`${entityName}EmploymentType`] === EMPLOYMENT_TYPES.EMPLOYEE)
    message = MESSAGE_BY_TYPES[EMPLOYMENT_TYPES.EMPLOYEE]
  
  if (
    watchedFiles[`${entityName}EmploymentType`] === EMPLOYMENT_TYPES.INDEPENDENT &&
    watchedFiles[`${entityName}IndependentEmploymentType`] === INDEPENDENT_EMPLOYMENT_TYPES.MONOTRIBUTE
  ) message = MESSAGE_BY_TYPES[EMPLOYMENT_TYPES.INDEPENDENT][INDEPENDENT_EMPLOYMENT_TYPES.MONOTRIBUTE] 
  
  if (
    watchedFiles[`${entityName}EmploymentType`] === EMPLOYMENT_TYPES.INDEPENDENT &&
    watchedFiles[`${entityName}IndependentEmploymentType`] === INDEPENDENT_EMPLOYMENT_TYPES.AUTONOMOUS
  ) message = MESSAGE_BY_TYPES[EMPLOYMENT_TYPES.INDEPENDENT][INDEPENDENT_EMPLOYMENT_TYPES.AUTONOMOUS]
  
  if (message) {
    return (
      <Alert
        onClick={e => e.stopPropagation()}
        style={{ margin: '10px 0', width: '100%' }}
        type="info"
        message={message}
      />
    )
  }
}

const DOCUMENTATION_BY_TYPES: DocumentationType = {
  [REQUEST_TYPES.reform]: [
    {
      blockTitle: (watchFields: any) => 'Documentación personal',
      uploads: [
        {
          label: 'DNI (Titular / Cónyuge o Conviviente / Garante)',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.reform].DNI,
          required: true,
          active: true,
          order: 1,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Libreta de Familia / Sentencia de divorcio',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.reform].LIBRETA_FAMILIA,
          required: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (option && option.hasSpouse) return true
            return false
          },
          active: true,
          isVisible: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (!option || !(option.hasSpouse || option.divorcedOrWidow)) return false
            return true
          },
          order: 2,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'DIR (Titular / Cónyuge o Conviviente)',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.reform].DIR,
          required: true,
          active: true,
          order: 3,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Ingresos - 3 últimos recibos de haberes / Constancia de opción Monotributo, 3 últimos pagos /F711, 3 últimos pagos (Titular / Cónyuge o Conviviente / Garante)',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.reform].INGRESOS,
          required: true,
          active: true,
          order: 4,
          accept: ACCEPT_FILE.PDF
        },
        {
          label: 'DDJJ de salud completada y firmada por el solicitante y conyuge si suma ingresos.',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.reform].DDJJ_SALUD,
          required: true,
          active: true,
          order: 5,
          accept: ACCEPT_FILE.PDF,
          availableForms: [FORM_LINKS.DDJJ_SALUD]
        },
        {
          label: 'Examen médico clínico” (suscripto por solicitante y médico clínico) y “Examen médico cardiológico” (solo adjuntarlo en caso que si el crédito está conformado por un único titular del crédito -no suma ingresos-, ese titular tiene 50 años o más y solicita un préstamo de 55.000 UVAs o más)',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.reform].EXAMEN_MEDICO,
          required: false,
          active: true,
          order: 6,
          accept: ACCEPT_FILE.PDF,
          availableForms: [FORM_LINKS.EXAMEN_MEDICO_CARDIOVASCULAR, FORM_LINKS.EXAMEN_MEDICO_GENERAL]
        },
      ]
    },
    {
      blockTitle: (watchFields: any) => 'Documentación del inmueble',
      uploads: [
        {
          label: 'Escritura inscripta',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.reform].ESCRITURA,
          required: true,
          active: true,
          order: 1,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Informe de Matricula',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.reform].MATRICULA,
          required: true,
          active: true,
          order: 2,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Plancha catastral',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.reform].PLANCHA_CATASTRAL,
          required: true,
          active: true,
          order: 3,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Cedulón de rentas',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.reform].CEDULON_RENTAS,
          required: true,
          active: true,
          order: 4,
          accept: ACCEPT_FILE.PDF
        },
        {
          label: 'Plano aprobado ampliación',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.reform].PLANO_APROBADO,
          required: true,
          active: true,
          order: 5,
          accept: ACCEPT_FILE.PDF
        },
        {
          label: 'Presupuesto de obra',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.reform].PRESUPUESTO,
          required: true,
          active: true,
          order: 6,
          accept: ACCEPT_FILE.PDF
        },
      ]
    },
  ],
  [REQUEST_TYPES.build]: [
    {
      blockTitle: (watchFields: any) => 'Documentación personal',
      uploads: [
        {
          label: 'DNI (Titular / Cónyuge o Conviviente / Garante)',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.build].DNI,
          required: true,
          active: true,
          order: 1,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Libreta de Familia / Sentencia de divorcio',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.build].LIBRETA_FAMILIA,
          required: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (option && option.hasSpouse) return true
            return false
          },
          active: true,
          isVisible: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (!option || !(option.hasSpouse || option.divorcedOrWidow)) return false
            return true
          },
          order: 2,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'DIR (Titular / Cónyuge o Conviviente)',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.build].DIR,
          required: true,
          active: true,
          order: 3,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Ingresos - 3 últimos recibos de haberes / Constancia de opción Monotributo, 3 últimos pagos /F711, 3 últimos pagos (Titular / Cónyuge o Conviviente / Garante)',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.build].INGRESOS,
          required: true,
          active: true,
          order: 4,
          accept: ACCEPT_FILE.PDF
        },
        {
          label: 'DDJJ de salud completada y firmada por el solicitante y conyuge si suma ingresos.',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.build].DDJJ_SALUD,
          required: true,
          active: true,
          order: 5,
          accept: ACCEPT_FILE.PDF,
          availableForms: [FORM_LINKS.DDJJ_SALUD]
        },
        {
          label: 'Examen médico clínico” (suscripto por solicitante y médico clínico) y “Examen médico cardiológico” (solo adjuntarlo en caso que si el crédito está conformado por un único titular del crédito -no suma ingresos-, ese titular tiene 50 años o más y solicita un préstamo de 55.000 UVAs o más)',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.build].EXAMEN_MEDICO,
          required: false,
          active: true,
          order: 6,
          accept: ACCEPT_FILE.PDF,
          availableForms: [FORM_LINKS.EXAMEN_MEDICO_CARDIOVASCULAR, FORM_LINKS.EXAMEN_MEDICO_GENERAL]
        },
      ]
    },
    {
      blockTitle: (watchFields: any) => 'Documentación del inmueble',
      uploads: [
        {
          label: 'Escritura inscripta',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.build].ESCRITURA,
          required: true,
          active: true,
          order: 1,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Informe de Matricula',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.build].MATRICULA,
          required: true,
          active: true,
          order: 2,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Plancha catastral',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.build].PLANCHA_CATASTRAL,
          required: true,
          active: true,
          order: 3,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Cedulón de rentas',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.build].CEDULON_RENTAS,
          required: true,
          active: true,
          order: 4,
          accept: ACCEPT_FILE.PDF
        },
        {
          label: 'Plano aprobado',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.build].PLANO_APROBADO,
          required: true,
          active: true,
          order: 5,
          accept: ACCEPT_FILE.PDF
        },
        {
          label: 'Presupuesto de obra',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.build].PRESUPUESTO,
          required: true,
          active: true,
          order: 6,
          accept: ACCEPT_FILE.PDF
        },
      ]
    },
  ],
  // Finish es igual que build. Comparten la línea de crédito
  [REQUEST_TYPES.finish]: [
    {
      blockTitle: (watchFields: any) => 'Documentación personal',
      uploads: [
        {
          label: 'DNI (Titular / Cónyuge o Conviviente / Garante)',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.finish].DNI,
          required: true,
          active: true,
          order: 1,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Libreta de Familia / Sentencia de divorcio',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.finish].LIBRETA_FAMILIA,
          required: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (option && option.hasSpouse) return true
            return false
          },
          active: true,
          isVisible: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (!option || !(option.hasSpouse || option.divorcedOrWidow)) return false
            return true
          },
          order: 2,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'DIR (Titular / Cónyuge o Conviviente)',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.finish].DIR,
          required: true,
          active: true,
          order: 3,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Ingresos - 3 últimos recibos de haberes / Constancia de opción Monotributo, 3 últimos pagos /F711, 3 últimos pagos (Titular / Cónyuge o Conviviente / Garante)',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.finish].INGRESOS,
          required: true,
          active: true,
          order: 4,
          accept: ACCEPT_FILE.PDF
        },
        {
          label: 'DDJJ de salud completada y firmada por el solicitante y conyuge si suma ingresos.',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.finish].DDJJ_SALUD,
          required: true,
          active: true,
          order: 5,
          accept: ACCEPT_FILE.PDF,
          availableForms: [FORM_LINKS.DDJJ_SALUD]
        },
        {
          label: 'Examen médico clínico” (suscripto por solicitante y médico clínico) y “Examen médico cardiológico” (solo adjuntarlo en caso que si el crédito está conformado por un único titular del crédito -no suma ingresos-, ese titular tiene 50 años o más y solicita un préstamo de 55.000 UVAs o más)',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.finish].EXAMEN_MEDICO,
          required: false,
          active: true,
          order: 6,
          accept: ACCEPT_FILE.PDF,
          availableForms: [FORM_LINKS.EXAMEN_MEDICO_CARDIOVASCULAR, FORM_LINKS.EXAMEN_MEDICO_GENERAL]
        },
      ]
    },
    {
      blockTitle: (watchFields: any) => 'Documentación del inmueble',
      uploads: [
        {
          label: 'Escritura inscripta',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.finish].ESCRITURA,
          required: true,
          active: true,
          order: 1,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Informe de Matricula',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.finish].MATRICULA,
          required: true,
          active: true,
          order: 2,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Plancha catastral',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.finish].PLANCHA_CATASTRAL,
          required: true,
          active: true,
          order: 3,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Cedulón de rentas',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.finish].CEDULON_RENTAS,
          required: true,
          active: true,
          order: 4,
          accept: ACCEPT_FILE.PDF
        },
        {
          label: 'Plano aprobado',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.finish].PLANO_APROBADO,
          required: true,
          active: true,
          order: 5,
          accept: ACCEPT_FILE.PDF
        },
        {
          label: 'Presupuesto de obra',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.finish].PRESUPUESTO,
          required: true,
          active: true,
          order: 6,
          accept: ACCEPT_FILE.PDF
        },
      ]
    },
  ],
  [REQUEST_TYPES.seed]: [
    {
      blockTitle: (watchFields: any) => 'Documentación personal',
      uploads: [
        {
          label: 'DNI (Titular / Cónyuge o Conviviente / Garante)',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.seed].DNI,
          required: true,
          active: true,
          order: 1,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'DNI (Dueño del Lote)',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.seed].DNI_DUEÑO,
          required: true,
          active: true,
          order: 2,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Libreta de Familia / Sentencia de divorcio',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.seed].LIBRETA_FAMILIA,
          required: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (option && option.hasSpouse) return true
            return false
          },
          active: true,
          isVisible: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (!option || !(option.hasSpouse || option.divorcedOrWidow)) return false
            return true
          },
          order: 3,
          accept: ACCEPT_FILE.IMAGE_PDF
        }
      ]

    },
    {
      blockTitle: (watchFields: any) => 'Documentación del inmueble',
      uploads: [
        {
          label: 'Boleto de Compra Venta o Escritura del lote',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.seed].ESCRITURA,
          required: true,
          active: true,
          order: 1,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Plano de proyecto propio firmado por profesional competente / Croquis de tipología ofrecida por el Programa Semilla / Nota compromiso de presentación de plano de proyecto propio en los próximos 60 días',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.seed].PLANO_APROBADO,
          required: true,
          active: true,
          order: 2,
          accept: ACCEPT_FILE.IMAGE_PDF
        }
      ]
    },
  ],
  [REQUEST_TYPES.develop]: [
    {
      blockTitle: (watchFields: any) => 'Documentación personal',
      uploads: [
        {
          label: 'DNI (Titular / Cónyuge o Conviviente / Garante)',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.develop].DNI,
          required: true,
          active: true,
          order: 1,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Libreta de Familia / Sentencia de divorcio',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.develop].LIBRETA_FAMILIA,
          required: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (option && option.hasSpouse) return true
            return false
          },
          active: true,
          isVisible: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (!option || !(option.hasSpouse || option.divorcedOrWidow)) return false
            return true
          },
          order: 2,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'DIR (Titular / Cónyuge o Conviviente)',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.develop].DIR,
          required: true,
          active: true,
          order: 3,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Ingresos - 3 últimos recibos de haberes / Constancia de opción Monotributo, 3 últimos pagos /F711, 3 últimos pagos (Titular / Cónyuge o Conviviente / Garante)',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.develop].INGRESOS,
          required: true,
          active: true,
          order: 4,
          accept: ACCEPT_FILE.PDF
        },
        {
          label: 'DDJJ de salud completada y firmada por el solicitante y conyuge si suma ingresos.',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.develop].DDJJ_SALUD,
          required: true,
          active: true,
          order: 5,
          accept: ACCEPT_FILE.PDF,
          availableForms: [FORM_LINKS.DDJJ_SALUD]
        },
        {
          label: 'Examen médico clínico” (suscripto por solicitante y médico clínico) y “Examen médico cardiológico” (solo adjuntarlo en caso que si el crédito está conformado por un único titular del crédito -no suma ingresos-, ese titular tiene 50 años o más y solicita un préstamo de 55.000 UVAs o más)',
          name: CRM_DOCUMENT_TYPES[REQUEST_TYPES.develop].EXAMEN_MEDICO,
          required: false,
          active: true,
          order: 6,
          accept: ACCEPT_FILE.PDF,
          availableForms: [FORM_LINKS.EXAMEN_MEDICO_CARDIOVASCULAR, FORM_LINKS.EXAMEN_MEDICO_GENERAL]
        },
      ]
    },
    {
      blockTitle: (watchFields: any) => 'Documentación del inmueble',
      uploads: [
        {
          label: 'Plano aprobado',
          name: 'Plano aprobado',
          required: true,
          active: true,
          order: 1,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Boleto de compra venta con escritura antecedente',
          name: 'Boleto de compra venta',
          required: true,
          active: true,
          order: 2,
          accept: ACCEPT_FILE.IMAGE_PDF
        }
      ]
    },
  ],
  [REQUEST_TYPES["develop-buy"]]: [
    {
      blockTitle: (watchFields: any) => 'Documentación personal',
      uploads: [
        {
          label: 'DNI Frente y Dorso (Titular)',
          name: CRM_DOCUMENT_TYPES["develop-buy"].DNI,
          required: true,
          active: true,
          order: 1,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'DNI Frente y Dorso (Cónyuge o Conviviente)',
          name: CRM_DOCUMENT_TYPES["develop-buy"].DNI_CONYUGE,
          required: true,
          active: true,
          isVisible: (watchFields: any) => watchFields.hasSpouse,
          order: 2,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'DNI Frente y Dorso (Hijos) ó certificado de nacimiento (en caso que no figuren los datos de los padres en el DNI)',
          name: CRM_DOCUMENT_TYPES["develop-buy"].DNI_HIJOS,
          required: true,
          active: true,
          isVisible: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (!option || !option.hasChildren) return false
            return true
          },
          order: 3,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Libreta de familia / Certificado notarial de convivencia',
          name: CRM_DOCUMENT_TYPES["develop-buy"].LIBRETA_FAMILIA,
          required: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (option && option.hasSpouse) return true
            return false
          },
          active: true,
          isVisible: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (!option || !option.hasSpouse) return false
            return true
          },
          order: 4,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Sentencia de divorcio / Acta de defunción.',
          name: CRM_DOCUMENT_TYPES["develop-buy"].DIVORCE_WIDOW,
          required: false,
          active: true,
          isVisible: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (!option || !option?.divorcedOrWidow) return false
            return true
          },
          order: 3,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: (watchedFields: any) => `DIR (Titular${watchedFields.hasSpouse ? ' y Cónyuge/Conviviente' : "" })`,
          name: CRM_DOCUMENT_TYPES["develop-buy"].DIR,
          required: true,
          active: true,
          order: 5,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'DNI Frente y Dorso (Garante)',
          name: CRM_DOCUMENT_TYPES.qualify.DNI_CODEUDOR,
          required: true,
          active: true,
          isVisible: (watchFields: any) => watchFields.hasCosignerOne,
          order: 2,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'DDJJ de salud completa y firmada de quienes sumen ingresos.',
          name: CRM_DOCUMENT_TYPES["develop-buy"].DDJJ_SALUD,
          required: true,
          active: true,
          order: 6,
          accept: ACCEPT_FILE.PDF,
          availableForms: [FORM_LINKS.DDJJ_SALUD]
        },
        {
          label: 'Examen médico clínico” (suscripto por solicitante y médico clínico) y “Examen médico cardiológico” (solo adjuntarlo en caso que si el crédito está conformado por un único titular del crédito -no suma ingresos-, ese titular tiene 50 años o más y solicita un préstamo de 55.000 UVAs o más)',
          name: CRM_DOCUMENT_TYPES["develop-buy"].EXAMEN_MEDICO,
          required: false,
          active: true,
          order: 7,
          accept: ACCEPT_FILE.PDF,
          availableForms: [FORM_LINKS.EXAMEN_MEDICO_GENERAL, FORM_LINKS.EXAMEN_MEDICO_CARDIOVASCULAR]
        },
      ]
    },
    {
      blockTitle: (watchFields: any) => 'Documentación laboral',
      uploads: [
        {
          label: 'Ingresos (Titular)',
          name: CRM_DOCUMENT_TYPES["develop-buy"].INGRESOS,
          description: (watchedFiles: any) => getEmploymentDocumentationInfo(watchedFiles, entityPrefixNames.REQUESTER),
          required: true,
          active: true,
          order: 1,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Ingreso (Cónyuge o Conviviente)',
          name: CRM_DOCUMENT_TYPES["develop-buy"].INGRESO_CONYUGE,
          required: true,
          active: true,
          description: (watchedFiles: any) => getEmploymentDocumentationInfo(watchedFiles, entityPrefixNames.SPOUSE),
          isVisible: (watchFields: any) => watchFields.hasSpouse,
          order: 2,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Ingreso (Garante)',
          name: CRM_DOCUMENT_TYPES.qualify.INGRESO_CODEUDOR,
          required: true,
          active: true,
          description: (watchedFiles: any) => getEmploymentDocumentationInfo(watchedFiles, entityPrefixNames.COSIGNER_ONE),
          isVisible: (watchFields: any) => watchFields.hasCosignerOne,
          order: 2,
          accept: ACCEPT_FILE.IMAGE_PDF
        }
      ]
    },
    {
      blockTitle: (watchFields: any) => 'Documentación del inmueble',
      uploads: [
        {
          label: 'Documentación aportada por la Empresa Desarrollista',
          name: CRM_DOCUMENT_TYPES['develop-buy'].DOCUMENTACION_DESARROLLISTA,
          required: true,
          active: true,
          order: 1,
          accept: ACCEPT_FILE.IMAGE_PDF
        }
      ]
    },
  ],
  [REQUEST_TYPES.buy]: [
    {
      blockTitle: (watchFields: any) => 'Documentación personal',
      uploads: [
        {
          label: 'DNI Frente y Dorso (Titular)',
          name: CRM_DOCUMENT_TYPES.buy.DNI,
          required: true,
          active: true,
          order: 1,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'DNI Frente y Dorso (Cónyuge o Conviviente)',
          name: CRM_DOCUMENT_TYPES.buy.DNI_CONYUGE,
          required: true,
          active: true,
          isVisible: (watchFields: any) => watchFields.hasSpouse,
          order: 2,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'DNI Frente y Dorso (Hijos) ó certificado de nacimiento (en caso que no figuren los datos de los padres en el DNI)',
          name: CRM_DOCUMENT_TYPES.buy.DNI_HIJOS,
          required: true,
          active: true,
          isVisible: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (!option || !option.hasChildren) return false
            return true
          },
          order: 3,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'DNI Frente y Dorso (Garante)',
          name: CRM_DOCUMENT_TYPES.qualify.DNI_CODEUDOR,
          required: true,
          active: true,
          isVisible: (watchFields: any) => watchFields.hasCosignerOne,
          order: 2,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Libreta de familia / Certificado notarial de convivencia',
          name: CRM_DOCUMENT_TYPES.buy.LIBRETA_FAMILIA,
          required: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (option && option.hasSpouse) return true
            return false
          },
          active: true,
          isVisible: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (!option || !option.hasSpouse) return false
            return true
          },
          order: 4,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Sentencia de divorcio / Acta de defunción.',
          name: CRM_DOCUMENT_TYPES.buy.DIVORCE_WIDOW,
          required: false,
          active: true,
          isVisible: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (!option || !option?.divorcedOrWidow) return false
            return true
          },
          order: 3,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: (watchedFields: any) => `DIR (Titular${watchedFields.hasSpouse ? ' y Cónyuge/Conviviente' : "" })`,
          name: CRM_DOCUMENT_TYPES.buy.DIR,
          required: true,
          active: true,
          order: 5,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'DDJJ de salud completa y firmada de quienes sumen ingresos.',
          name: CRM_DOCUMENT_TYPES.buy.DDJJ_SALUD,
          required: true,
          active: true,
          order: 6,
          accept: ACCEPT_FILE.PDF,
          availableForms: [FORM_LINKS.DDJJ_SALUD]
        },
        {
          label: 'Examen médico clínico” (suscripto por solicitante y médico clínico) y “Examen médico cardiológico” (solo adjuntarlo en caso que si el crédito está conformado por un único titular del crédito -no suma ingresos-, ese titular tiene 50 años o más y solicita un préstamo de 55.000 UVAs o más)',
          name: CRM_DOCUMENT_TYPES.buy.EXAMEN_MEDICO,
          required: false,
          active: true,
          order: 7,
          accept: ACCEPT_FILE.PDF,
          availableForms: [FORM_LINKS.EXAMEN_MEDICO_GENERAL, FORM_LINKS.EXAMEN_MEDICO_CARDIOVASCULAR]
        },
      ]
    },
    {
      blockTitle: (watchFields: any) => 'Documentación laboral',
      uploads: [
        {
          label: 'Ingresos (Titular)',
          name: CRM_DOCUMENT_TYPES.buy.INGRESOS,
          description: (watchedFiles: any) => getEmploymentDocumentationInfo(watchedFiles, entityPrefixNames.REQUESTER),
          required: true,
          active: true,
          order: 1,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Ingreso (Cónyuge o Conviviente)',
          name: CRM_DOCUMENT_TYPES.buy.INGRESO_CONYUGE,
          required: true,
          active: true,
          description: (watchedFiles: any) => getEmploymentDocumentationInfo(watchedFiles, entityPrefixNames.SPOUSE),
          isVisible: (watchFields: any) => watchFields.hasSpouse,
          order: 2,
          accept: ACCEPT_FILE.IMAGE_PDF
        },
        {
          label: 'Ingreso (Garante)',
          name: CRM_DOCUMENT_TYPES.qualify.INGRESO_CODEUDOR,
          required: true,
          active: true,
          description: (watchedFiles: any) => getEmploymentDocumentationInfo(watchedFiles, entityPrefixNames.COSIGNER_ONE),
          isVisible: (watchFields: any) => watchFields.hasCosignerOne,
          order: 2,
          accept: ACCEPT_FILE.IMAGE_PDF
        }
      ]
    },
    {
      blockTitle: (watchFields: any) =>  watchFields.isCertificateExpired ? 'Documentación del inmueble' : null,
      uploads: [
        {
          label: 'Informe de matrícula actualizado',
          name: CRM_DOCUMENT_TYPES.buy.MATRICULA,
          required: true,
          active: true,
          order: 1,
          accept: ACCEPT_FILE.IMAGE_PDF,
          isVisible: (watchedFields: any) => watchedFields.isCertificateExpired,
        }
      ]
    },
  ],
  [REQUEST_TYPES.qualify]: [
    {
      blockTitle: (watchFields: any) => (watchFields: any) => 'Documentación personal',
      uploads: [
        {
          label: 'DNI Frente y Dorso (Titular)',
          name: CRM_DOCUMENT_TYPES["develop-buy"].DNI,
          required: true,
          active: true,
          order: 1,
          accept: ACCEPT_FILE.PDF
        },
        {
          label: 'DNI Frente y Dorso (Cónyuge o Conviviente)',
          name: CRM_DOCUMENT_TYPES["develop-buy"].DNI_CONYUGE,
          required: true,
          active: true,
          isVisible: (watchFields: any) => watchFields.hasSpouse,
          order: 2,
          accept: ACCEPT_FILE.PDF
        },
        {
          label: 'DNI Frente y Dorso (Hijos) ó certificado de nacimiento (en caso que no figuren los datos de los padres en el DNI)',
          name: CRM_DOCUMENT_TYPES["develop-buy"].DNI_HIJOS,
          required: true,
          active: true,
          isVisible: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (!option || !option.hasChildren) return false
            return true
          },
          order: 3,
          accept: ACCEPT_FILE.PDF
        },
        {
          label: 'Libreta de familia / Certificado notarial de convivencia o acta de nacimiento de hijos en común en caso que no figuren los datos de los padres en el DNI',
          name: CRM_DOCUMENT_TYPES["develop-buy"].LIBRETA_FAMILIA,
          required: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (option && option.hasSpouse) return true
            return false
          },
          active: true,
          isVisible: (watchFields: any) => {
            const option = FAMILIAR_GROUP_OPTIONS.find(option => option.value === watchFields.familiarGroup)
            if (!option || !(option.hasSpouse || option.hasChildren)) return false
            return true
          },
          order: 4,
          accept: ACCEPT_FILE.PDF
        },
        {
          label: 'DNI Frente y Dorso (Garante)',
          name: CRM_DOCUMENT_TYPES.qualify.DNI_CODEUDOR,
          required: true,
          active: true,
          isVisible: (watchFields: any) => watchFields.hasCosignerOne,
          order: 2,
          accept: ACCEPT_FILE.PDF
        },
      ]
    },
    {
      blockTitle: (watchFields: any) => (watchFields: any) => 'Documentación laboral',
      uploads: [
        {
          label: 'Ingresos (Titular)',
          name: CRM_DOCUMENT_TYPES["develop-buy"].INGRESOS,
          description: (watchedFiles: any) => getEmploymentDocumentationInfo(watchedFiles, entityPrefixNames.REQUESTER),
          required: true,
          active: true,
          order: 1,
          accept: ACCEPT_FILE.PDF
        },
        {
          label: 'Ingreso (Cónyuge o Conviviente)',
          name: CRM_DOCUMENT_TYPES["develop-buy"].INGRESO_CONYUGE,
          required: true,
          active: true,
          description: (watchedFiles: any) => getEmploymentDocumentationInfo(watchedFiles, entityPrefixNames.SPOUSE),
          isVisible: (watchFields: any) => watchFields.hasSpouse && watchFields.spouseIsCoSigner,
          order: 2,
          accept: ACCEPT_FILE.PDF
        },
        {
          label: 'Ingreso (Garante)',
          name: CRM_DOCUMENT_TYPES.qualify.INGRESO_CODEUDOR,
          required: true,
          active: true,
          description: (watchedFiles: any) => getEmploymentDocumentationInfo(watchedFiles, entityPrefixNames.COSIGNER_ONE),
          isVisible: (watchFields: any) => watchFields.hasCosignerOne,
          order: 2,
          accept: ACCEPT_FILE.PDF
        }
      ]
    }
  ]
}

const styles = {
  helpText: {
    color: '#464646',
    fontSize: '12px'
  },
  button: {
    fontSize: '20px'
  },
  formTitle: {
    fontSize: '16px',
    width: '100%',
    marginBottom: '20px',
    fontWeight: 700
  },
  required: {
    fontFamily: 'SimSun, sans-serif',
    lineHeight: 1,
    fontSize: '14px',
    color: '#ff4d4f',
    marginInlineEnd: '4px',
    display: 'inline-block'
  }
}

const MAX_SIZE_ALLOWED = 50

export const UploadDocumentationForm = (props: any) => {
  const { form, isFormBlocked, isCertificateExpired } = props
  const [fileUploads, setFileUploads] = useState<any>({})

  // Watchs
  const watchedFields = {
    hasSpouse: Form.useWatch("hasSpouse", form),
    hasCosignerOne: Form.useWatch("hasCosignerOne", form),
    hasCosignerTwo: Form.useWatch("hasCosignerTwo", form),
    familiarGroup: Form.useWatch("requesterCivilStatus", form),
    [`${entityPrefixNames.REQUESTER}EmploymentType`]: Form.useWatch(`${entityPrefixNames.REQUESTER}EmploymentType`, form),
    [`${entityPrefixNames.REQUESTER}IndependentEmploymentType`]: Form.useWatch(`${entityPrefixNames.REQUESTER}IndependentEmploymentType`, form),
    [`${entityPrefixNames.SPOUSE}EmploymentType`]: Form.useWatch(`${entityPrefixNames.SPOUSE}EmploymentType`, form),
    [`${entityPrefixNames.SPOUSE}IndependentEmploymentType`]: Form.useWatch(`${entityPrefixNames.SPOUSE}IndependentEmploymentType`, form),
    [`${entityPrefixNames.COSIGNER_ONE}EmploymentType`]: Form.useWatch(`${entityPrefixNames.COSIGNER_ONE}EmploymentType`, form),
    [`${entityPrefixNames.COSIGNER_ONE}IndependentEmploymentType`]: Form.useWatch(`${entityPrefixNames.COSIGNER_ONE}IndependentEmploymentType`, form),
    [`${entityPrefixNames.COSIGNER_TWO}EmploymentType`]: Form.useWatch(`${entityPrefixNames.COSIGNER_TWO}EmploymentType`, form),
    [`${entityPrefixNames.COSIGNER_TWO}IndependentEmploymentType`]: Form.useWatch(`${entityPrefixNames.COSIGNER_TWO}IndependentEmploymentType`, form),
    spouseIsCoSigner: Form.useWatch("spouseIsCoSigner", form),
    isCertificateExpired: isCertificateExpired
  }
  
  const type: keyof typeof REQUEST_TYPES = props.type

  const checkFileSize = (file: File, maxSize: number) => {
    const sizeInMB = file.size / 1024 / 1024;
    return sizeInMB <= maxSize;
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) return e;
    return e && e.fileList;
  };

  return (
    <Row gutter={[16, 0]} style={{ width: '100%', margin: '20px auto' }}>
      <Title style={styles.formTitle} className="default-font bancor-color" level={3}>
        Documentación Adjunta
        <br />
        <small style={{ fontWeight: 'normal', color: 'black' }}>
          {TITLE_BY_TYPES[type] ?? TITLE_BY_TYPES.default}
        </small>
      </Title>
      {type && DOCUMENTATION_BY_TYPES?.[type]?.map(({ blockTitle, uploads }, index) => 
        <Row key={`documentation-${index}`} gutter={[16, 0]} style={{ width: '100%', margin: '0 auto' }}>
          {blockTitle && <Row className="default-font bancor-color">{blockTitle(watchedFields)}</Row>}
          {uploads.map((upload, index) => {
            const isVisible = upload.hasOwnProperty('isVisible') ? upload.isVisible(watchedFields) : true
            const isRequired = typeof upload.required === 'boolean' ? upload.required : upload.required(watchedFields)
            return (
              <Row key={`block-${index}`} gutter={[16, 0]} style={{ width: '100%', margin: '0 auto' }}>
                {upload.active && isVisible &&
                  <Col span={24} key={upload.name}>
                    <Item
                      name={["documentation", upload.name]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      rules={[{ required: isRequired, message: 'Seleccione al menos 1 archivo.' }]}
                    >
                      <Upload
                        onChange={e => {
                          const { fileList } = e
                          setFileUploads((prev: any) => ({ ...prev, [`${index}${upload.name}`]: !!fileList.length }))
                        }}
                        className="certification-upload"
                        listType="picture"
                        disabled={isFormBlocked}
                        beforeUpload={(file) => {
                          let messageNotification = ""
                          if (upload.accept === ACCEPT_FILE.IMAGE && file.type !== 'image/jpeg')
                            messageNotification = 'Sólo se aceptan archivos JPG, JPEG o PNG'
                          if (upload.accept === ACCEPT_FILE.PDF && file.type !== 'application/pdf')
                            messageNotification = 'Sólo se aceptan archivos PDF'
                          if (upload.accept === ACCEPT_FILE.IMAGE_PDF && (file.type !== 'image/jpeg' && file.type !== 'application/pdf'))
                            messageNotification = 'Sólo se aceptan archivos JPG, JPEG, PNG o PDF'
                          if (!checkFileSize(file, MAX_SIZE_ALLOWED))
                            messageNotification = `Los archivos deben ser menores de ${MAX_SIZE_ALLOWED}MB`
                          
                          if (!messageNotification) return false
                          else {
                            notification.error({ duration: 2, message: messageNotification })
                            return Upload.LIST_IGNORE;
                          }
                        }}
                        accept={upload.accept}
                        multiple
                      >
                        <Row style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                          <Col md={18} style={{ padding: 0 }} onClick={e => e.stopPropagation()}>
                            <Text style={{ flexGrow: 1 }} onClick={(e :any) => e.stopPropagation()}>
                              {isRequired && <Text style={styles.required}>*</Text>}
                              {typeof upload.label === 'string' ? upload.label : upload.label(watchedFields)}
                              {upload.availableForms?.length > 0 &&
                                <Popover
                                  content={
                                    <List
                                      style={{ marginTop: '5px' }}
                                      dataSource={upload.availableForms || []}
                                      size="small"
                                      renderItem={(item: { label: string, url: string }) =>
                                        <Link className="bancor-color" style={{ display: 'block', color: 'black' }} href={item.url} target="_blank">
                                          <FilePdfOutlined style={{ color: '#ff4d4f' }} /> {item.label}
                                        </Link>
                                      }
                                    />
                                  }
                                  title="Documentos disponibles"
                                  trigger="hover">
                                    <Button
                                      className="bancor-color"
                                      style={{ lineHeight: 1 }}
                                      size="small"
                                      type="link"
                                      icon={<DownloadOutlined />} />
                                </Popover>
                              }
                            </Text>
                          </Col>
                          <Col md={6} style={{ padding: 0, textAlign: 'right' }}>
                            <Button type="default" disabled={isFormBlocked} icon={<UploadOutlined />}>
                              {fileUploads[`${index}${upload.name}`] ? "Subir otro" : "Subir"}
                            </Button>
                          </Col>
                        </Row>
                        {upload.hasOwnProperty('description') && upload.description(watchedFields)}
                      </Upload>
                    </Item>
                  </Col> 
                }
              </Row>
            )
          })
          }
        </Row>
      )}
    </Row>
  )
}