import { Galleria } from 'primereact/galleria';
import { useRef } from 'react';
import './Masonry.scss';
const url = window.url ? window.url : process.env.REACT_APP_API_URL as string;

export const Masonry = ({ photos, sold, reserved }: any) => {
  let render;
  const galleria = useRef<any>(null);

  const itemTemplate = (item: any) => {
    return (
      <img src={`${url}/property/photo/${item}`} alt={item} style={{ width: '100%', display: 'block' }} />
    );
  };

  if (photos.length === 1) {
    render = (
      <div className="grid">
        <div className="col-12">
          <div
            className="item item-1 relative flex align-items-center justify-content-center"
            onClick={() => galleria.current.show()}
            style={{
              backgroundImage: `url(${url}/property/photo/${photos[0]})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            {sold && (
              <div className='absolute text-2xl flex align-items-center justify-content-center item-label --sold'>Vendida</div>
            )}
            {reserved && (
              <div className='absolute text-2xl flex align-items-center justify-content-center item-label --reserved'>Reservada</div>
            )}

          </div>
        </div>
      </div>
    );
  } else if (photos.length > 1) {
    render = (
      <div className="grid">
        <div className="col-8">
          <div
            className="item item-1 relative flex align-items-center justify-content-center"
            onClick={() => galleria.current.show()}
            style={{
              backgroundImage: `url(${url}/property/photo/${photos[0]})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            {sold && (
              <div className='absolute text-2xl flex align-items-center justify-content-center item-label --sold'>Vendida</div>
            )}
            {reserved && (
              <div className='absolute text-2xl flex align-items-center justify-content-center item-label --reserved'>Reservada</div>
            )}
          </div>
        </div>
        <div className="col-4 flex flex-column align-items-center justify-content-between">
          <div
            className="item item-2"
            onClick={() => galleria.current.show()}
            style={{
              backgroundImage: `url(${url}/property/photo/${photos[1]})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          ></div>
          {photos.length >= 3 && (
            <div
              className="item item-2"
              onClick={() => galleria.current.show()}
              style={{
                backgroundImage: `url(${url}/property/photo/${photos[0]})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            >
              {photos.length > 3 && (
                <div
                  className="cover cursor-pointer flex align-items-center justify-content-center"
                  onClick={() => galleria.current.show()}
                >
                  {photos.length - 3} mas...
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      <Galleria
        ref={galleria}
        value={photos}
        numVisible={9}
        style={{ maxWidth: '50%' }}
        circular
        fullScreen
        showItemNavigators
        showThumbnails={false}
        item={itemTemplate}
      />
      {render}
    </div>
  );
};
