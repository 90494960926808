export const MAP_MAX_SEARCH = 50
export const DEFAULT_RADIUS = '50km'
export const DEFAULT_PAGE_SIZE = 48
export const INITIAL_ZOOM = 14
export const MIN_ZOOM = 9
export const DEFAULT_CENTER = {
  LAT: -31.4210052,
  LNG: -64.1893411
}
export const GOOGLE_MAP_TYPES = {
  NEIGHBORHOOD: 'neighborhood',
  LOCALITY: 'locality',
  AREA: 'administrative_area_level_2'
}

export const GOOGLE_MAP_OPTIONS = {
  disableDefaultUI: true,
  styles: [
    {
      featureType: 'water',
      stylers: [{ color: '#7d7d7d' }, { visibility: 'on' }],
    },
    {
      featureType: 'landscape',
      stylers: [{ color: '#fbfbfb' }],
    },
    {
      featureType: 'road',
      stylers: [{ saturation: -100 }, { lightness: 45 }],
    },
    {
      featureType: 'road.highway',
      stylers: [{ visibility: 'simplified' }],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#eb5b38' }],
    },
    {
      featureType: 'transit',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    },
  ],
};

export const PROVINCE_OPTIONS = [
  { value: 0, label: 'Capital Federal' },
  { value: 1, label: 'Buenos Aires' },
  { value: 2, label: 'Catamarca' },
  { value: 3, label: 'Córdoba' },
  { value: 4, label: 'Corrientes' },
  { value: 5, label: 'Entre Rios' },
  { value: 6, label: 'Jujuy' },
  { value: 7, label: 'Mendoza' },
  { value: 8, label: 'La Rioja' },
  { value: 9, label: 'Salta' },
  { value: 10, label: 'San Juan' },
  { value: 11, label: 'San Luis' },
  { value: 12, label: 'Santa Fé' },
  { value: 13, label: 'Santiago del Estero' },
  { value: 14, label: 'Tucumán' },
  { value: 16, label: 'Chaco' },
  { value: 17, label: 'Chubut' },
  { value: 18, label: 'Formosa' },
  { value: 19, label: 'Misiones' },
  { value: 20, label: 'Neuquén' },
  { value: 21, label: 'La Pampa' },
  { value: 22, label: 'Rio Negro' },
  { value: 23, label: 'Santa Cruz' },
  { value: 24, label: 'Tierra del Fuego' },
]

export const NEIGHBOORHOOD_TYPE = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED'
}

export const PROPERTY_ROOM_OPTIONS = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: '4+', value: 4 }
]

export const PRICE_RANGES = [
  { label: '20.000 a 60.000 UVAS', value: '20.000 a 60.000 UVAS' },
  { label: '60.000 a 100.000 UVAS', value: '60.000 a 100.000 UVAS' },
  { label: '100.000 a 140.000 UVAS', value: '100.000 a 140.000 UVAS' },
  { label: '140.000 a 180.000 UVAS', value: '140.000 a 180.000 UVAS' },
  { label: '180.000 a 220.000 UVAS', value: '180.000 a 220.000 UVAS' },
  { label: '220.000 a 260.000 UVAS', value: '220.000 a 260.000 UVAS' },
  { label: '260.000 a 300.000 UVAS', value: '260.000 a 300.000 UVAS' },
  { label: '300.000 a 340.000 UVAS', value: '300.000 a 340.000 UVAS' },
  { label: '340.000 a 400.000 UVAS', value: '340.000 a 400.000 UVAS' }
]

export const REQUEST_TYPES = {
  agency: 'agency',
  owner: 'owner',
  develop: 'develop',
  "develop-buy": 'develop-buy',
  reform: 'reform',
  finish: 'finish',
  buy: 'buy',
  seed: 'seed',
  build: 'build',
  qualify: 'qualify'
}

export const ACCEPT_FILE = {
  PDF: '.pdf',
  IMAGE: '.jpg,.jpeg,.png',
  IMAGE_PDF: '.jpg,.jpeg,.png,.pdf'
}

export const FAMILIAR_GROUP_OPTIONS = [
  {
    label: 'Cónyuges/Convivientes con hijos',
    value: 'Cónyuges/Convivientes con hijos',
    hasSpouse: true,
    hasChildren: true,
  },
  {
    label: 'Familia monoparental con hijos',
    value: 'Familia monoparental con hijos',
    hasSpouse: false,
    hasChildren: true,
    divorcedOrWidow: true
  },
  {
    label: 'Cónyuges/Convivientes sin hijos',
    value: 'Cónyuges/Convivientes sin hijos',
    hasSpouse: true,
    hasChildren: false
  },
  {
    label: 'Soltero/a sin hijos',
    value: 'Soltero/a sin hijos',
    hasSpouse: false,
    hasChildren: false
  },
]

export const entityPrefixNames = {
  REQUESTER: 'requester',
  COSIGNER_ONE: 'cosignerOne',
  COSIGNER_TWO: 'cosignerTwo',
  SPOUSE: 'spouse',
  PROPERTY: 'property'
}

export const entityPrefixNamesPreQualify = {
  REQUESTER: 'requester',
  COSIGNER_ONE: 'cosigner',
  COSIGNER_TWO: 'cosignerTwo',
  SPOUSE: 'spouse',
  PROPERTY: 'property'
}

export const EMPLOYMENT_TYPES = {
  OTHER: 'OTHER',
  EMPLOYEE: 'EMPLOYEE',
  INDEPENDENT: 'INDEPENDENT'
}

export const INDEPENDENT_EMPLOYMENT_TYPES = {
  AUTONOMOUS: "AUTONOMOUS",
  MONOTRIBUTE: 'MONOTRIBUTE'
}

export const DOCUMENT_TYPES = {
  DNI: "DNI",
  CUIL: "CUIL",
  CUIT: "CUIT"
}

export const QUALIFY_TYPES = {
  BUY: "buy",
  ALL: "",
  BUILD_REFORM: "build-reform",
  DEVELOP: "develop"
}



export const QUALIFY_TYPES_CODES = {
  [QUALIFY_TYPES.ALL]: [REQUEST_TYPES.buy, REQUEST_TYPES.build, REQUEST_TYPES.finish, REQUEST_TYPES.reform, REQUEST_TYPES.develop, REQUEST_TYPES["develop-buy"]],
  [QUALIFY_TYPES.BUY]: [REQUEST_TYPES.buy],
  [QUALIFY_TYPES.BUILD_REFORM]: [REQUEST_TYPES.build, REQUEST_TYPES.finish, REQUEST_TYPES.reform],
  [QUALIFY_TYPES.DEVELOP]: [REQUEST_TYPES.develop, REQUEST_TYPES["develop-buy"]]
}

export const QUALIFY_LOAN_OPTIONS = {
  [QUALIFY_TYPES.BUY]: [
    { value: "buy", label: "Compra" }
  ],
  [QUALIFY_TYPES.DEVELOP]: [
    { value: 'develop-buy', label: "Comprar (Acuerdo desarrollista)" },
    { value: 'develop-build', label: "Construir (Acuerdo desarrollista)" }
  ],
  [QUALIFY_TYPES.BUILD_REFORM]: [
    { value: 'reform', label: "Ampliación vivienda" },
    { value: 'build', label: "Construcción vivienda" },
    { value: 'finish', label: "Terminación vivienda" }
  ]
}

export const PREQUALIFY_STEP_SECTIONS = {
  STEP_1: 'SOLICITAR_CODIGO',
  STEP_2: 'VERIFICAR_CODIGO',
  STEP_3: 'COMPLETAR_FORM'
}

export const PREQUALIFY_STEP_TITLES = {
  [PREQUALIFY_STEP_SECTIONS.STEP_1]: 'Paso 1/3: Solicitar código',
  [PREQUALIFY_STEP_SECTIONS.STEP_2]: 'Paso 2/3: Verificar código',
  [PREQUALIFY_STEP_SECTIONS.STEP_3]: 'Paso 3/3: Completar formulario'
}