import { Carousel as PrimeCarousel } from 'primereact/carousel';
import { useQuery } from '@tanstack/react-query';
import './Carousel.scss';
import { Loading } from '../common/Loading';
import api from '../../api';
import { useEffect, useState } from 'react';
const url = window.url ? window.url : process.env.REACT_APP_API_URL as string;

export const Carousel = () => {
  const [bannerImages, setBannerImages] = useState<any[]>([]);
  const { isLoading, data: banners } = useQuery({
    queryKey: ['banners'],
    queryFn: api.getBanners,
  });

  const responsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: '991px',
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const template = (product: any) => {
    const time = new Date().getTime()
    return (
      <a
        href=""
        className="w-full h-full m-0"
        onClick={(e: any) => {
          e.preventDefault();
          if (product.link) window.open(product.link);
        }}
      >
        <div className="w-full m-0">
          <img
            style={{ width: '100%' }}
            src={`${url}/banner/${product.image}`} alt={product.id} />
        </div>
      </a>
    );
  };

  useEffect(() => {
    if(banners) {
      const data = banners.sort((a: any, b: any) => a.position - b.position)
      setBannerImages(data)
    }
  }, [banners])
  
  return (
    <div className="py-4 px-0 lg:px-8">
      {isLoading && bannerImages.length === 0 ? (
        <Loading />
      ) : (
        <PrimeCarousel
          value={bannerImages}
          showNavigators={true}
          responsiveOptions={responsiveOptions}
          className="custom-carousel"
          circular
          autoplayInterval={6000}
          itemTemplate={template}
        />
      )}
    </div>
  );
};
