import { useQuery } from '@tanstack/react-query';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import api from '../../api';

export const CalculatorForm = ({ uvas }: any) => {
  const [totalPrice, setTotalPrice] = useState('0');
  const [inputUvas, setInputUvas] = useState('0');
  const {
    data: currentUvaData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['currentUva'],
    queryFn: api.getCurrentUva,
  });

  const calculate = (e: any) => {
    const value = currentUvaData.value || 1;
    let inputValue = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
    inputValue = parseFloat(inputValue)
    if(!isNaN(inputValue)) {
      setInputUvas(inputValue);
      setTotalPrice((parseFloat(inputValue) * value).toFixed(2));
    }
  };

  useEffect(() => {
    const value = currentUvaData?.value || 1;
    if (uvas) {
      setInputUvas(uvas.toString());
      setTotalPrice((uvas * value).toFixed(2));
    }
  }, [uvas]);

  return (
    <div className="grid grid-nogutter">
      <div className="col-12 grid">
        <div className="col-12">
          <div className="flex flex-column gap-2">
            <label>Cantidad de UVAs</label>
            <InputText
              placeholder="0"
              value={inputUvas}
              className="w-full md:w-14rem"
              onInput={calculate}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="flex flex-column gap-2">
            <label>Cotización de 1 UVA al {currentUvaData?.date}</label>
            <b>$ {currentUvaData?.value}</b>
          </div>
        </div>
        <div className="col-6">
          <div className="flex flex-column gap-2">
            <label>Monto en Pesos</label>
            <b className="bancor-color">$ {totalPrice}</b>
          </div>
        </div>
      </div>
    </div>
  );
};
