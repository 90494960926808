import { Form, Row, Col, Typography, Radio, Input, Card, Divider, DatePicker } from "antd"
import { buildRequiredRule, buildMaxRule } from "../../../utils/ruleBuilders";
import { EMPLOYMENT_TYPES, INDEPENDENT_EMPLOYMENT_TYPES, entityPrefixNames } from '../../../utils/consts';
import { formStyles, availableFormFields } from "../LoanRequest";
import { VisibleFormItem } from "./VisibleFormItem";


const { Item } = Form
const { Title, Text } = Typography

const TITLES_BY_ENTITY = {
  [entityPrefixNames.REQUESTER]: 'Información laboral de la persona solicitante',
  [entityPrefixNames.COSIGNER_ONE]: 'Información laboral del garante',
  [entityPrefixNames.COSIGNER_TWO]: 'Información laboral del segundo garante',
  [entityPrefixNames.SPOUSE]: 'Información laboral del Cónyuge / Conviviente',
}

const independmentTypeOptions = [
  { value: INDEPENDENT_EMPLOYMENT_TYPES.AUTONOMOUS, label: 'Autónomo / Responsable Inscripto' },
  { value: INDEPENDENT_EMPLOYMENT_TYPES.MONOTRIBUTE, label: 'Monotributista' },
]

export const EmploymentDataForm = (props: any) => {
  const { form, entityPrefix, isFormBlocked, type } = props

  const employmentTypeOptions = [
    { value: EMPLOYMENT_TYPES.EMPLOYEE, label: 'En Relación de Dependencia' },
    { value: EMPLOYMENT_TYPES.INDEPENDENT, label: 'Independiente' },
  ]

  if (entityPrefix !== entityPrefixNames.REQUESTER) {
    employmentTypeOptions.push({ value: EMPLOYMENT_TYPES.OTHER, label: 'Otro' })
  }

  // Watchs
  const employmentType = Form.useWatch(`${entityPrefix}EmploymentType`, form)
  const independentType = Form.useWatch(`${entityPrefix}IndependentEmploymentType`, form)

  console.log('employmentTypeOptions: ', employmentTypeOptions)

  return (
    <Row gutter={[16, 0]} style={{ width: '100%', margin: '20px auto' }}>
      <Title style={formStyles.formTitle} className="default-font bancor-color" level={3}>
        {TITLES_BY_ENTITY[entityPrefix] ?? 'Información laboral'}
      </Title>
      <Card style={{ width: '100%' }}>
        <Row gutter={[16, 0]} style={{ width: '100%' }}>
          <Col span={24}>
            <Item
              name={`${entityPrefix}EmploymentType`}
              initialValue={EMPLOYMENT_TYPES.EMPLOYEE}
              rules={[buildRequiredRule('Tipo de empleo')]}>
              <Radio.Group disabled={isFormBlocked}>
                {employmentTypeOptions.map(option => {
                  return (
                    <Radio key={option.value} value={option.value}>{option.label}</Radio>
                  )
                })
                }
              </Radio.Group>
            </Item>
          </Col>
          {employmentType === EMPLOYMENT_TYPES.EMPLOYEE &&
            <>
              <Col xs={24} md={12}>
                <Item
                  name={`${entityPrefix}Employer`}
                  label="Nombre o Razón social del empleador"
                  rules={[buildRequiredRule('Nombre o Razón Social'), buildMaxRule(200)]}>
                  <Input disabled={isFormBlocked} placeholder="Nombre o Razón social del empleador"/>
                </Item>
              </Col>
              <VisibleFormItem type={type} fieldName={availableFormFields.EMPLOYER_CUIT} prefix={entityPrefix}>
                <Col xs={24} md={12}>
                  <Item
                    name={`${entityPrefix}EmployerCuit`}
                    label={
                      <Text className="default-font">
                        C.U.I.T. <Text style={formStyles.helpText}>(Ingrese sólo números)</Text>
                      </Text>
                    }
                    rules={[buildRequiredRule('C.U.I.T.')]}>
                    <Input
                      disabled={isFormBlocked}
                      type="number"
                      placeholder="C.U.I.T. del empleador"
                      showCount={false}
                    />
                  </Item>
                </Col>
              </VisibleFormItem>
              <VisibleFormItem type={type} fieldName={availableFormFields.EMPLOYER_PHONE} prefix={entityPrefix}>
                <Col xs={24} md={12}>
                  <Item
                    name={`${entityPrefix}EmployerPhone`}
                    label={
                      <Text className="default-font">
                        Teléfono del empleador <Text style={formStyles.helpText}>(Ingrese sólo números)</Text>
                      </Text>
                    }
                    rules={[buildRequiredRule('Teléfono'), buildMaxRule(10)]}>
                    <Input
                      disabled={isFormBlocked}
                      type="number"
                      placeholder="Teléfono del empleador"
                      showCount={false}
                    />
                  </Item>
                </Col>
              </VisibleFormItem>
            </>
          }
          {employmentType === EMPLOYMENT_TYPES.INDEPENDENT &&
            <>
              <Col span={24}>
                <Divider style={{ margin: '0 0 20px' }} />
                <Item
                  name={`${entityPrefix}IndependentEmploymentType`}
                  rules={[buildRequiredRule('Tipo de empleo independiente')]}>
                  <Radio.Group disabled={isFormBlocked}>
                    {independmentTypeOptions.map(option => 
                      <Radio key={option.value} value={option.value}>{option.label}</Radio>)
                    }
                  </Radio.Group>
                </Item>
              </Col>
              { independentType &&
                <>
                  {independentType === INDEPENDENT_EMPLOYMENT_TYPES.MONOTRIBUTE &&
                    <Col xs={24} md={12}>
                      <Item
                        name={`${entityPrefix}MonotributeCategory`}
                        label="Categoría"
                        rules={[buildRequiredRule('Categoría'), buildMaxRule(2)]}>
                        <Input disabled={isFormBlocked} placeholder="Categoría" />
                      </Item>
                    </Col>
                  }
                  <VisibleFormItem type={type} fieldName={availableFormFields.INDEPENDENT_START} prefix={entityPrefix}>
                    <Col xs={24} md={12}>
                      <Item
                        name={`${entityPrefix}IndependentStart`}
                        label={
                          <Text className="default-font">
                            Fecha de inicio de actividad
                          </Text>
                        }
                        rules={[buildRequiredRule('Fecha de inicio de actividad')]}>
                        <DatePicker
                          placeholder="Fecha de inicio de actividad"
                          disabled={isFormBlocked}
                          style={{ width: '100%' }}
                          format={['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY']} />
                      </Item>
                    </Col>
                  </VisibleFormItem>
                  <VisibleFormItem type={type} fieldName={availableFormFields.INDEPENDENT_YEARS} prefix={entityPrefix}>
                    <Col xs={24} md={12}>
                      <Item
                        name={`${entityPrefix}IndependentYears`}
                        label={
                          <Text className="default-font">
                            Años de Antigüedad <Text style={formStyles.helpText}>(Ingrese sólo números)</Text>
                          </Text>
                        }
                        rules={[buildRequiredRule('Años de Antigüedad'), buildMaxRule(2)]}>
                        <Input
                          disabled={isFormBlocked}
                          onChange={e => form.setFieldValue(`${entityPrefix}IndependentYears`, e.target.value.replace(/[^0-9]/g, ''))}
                          placeholder="Años de Antigüedad"
                        />
                      </Item>
                    </Col>
                  </VisibleFormItem>
                </>
              }
            </>
          }
          {employmentType === EMPLOYMENT_TYPES.OTHER &&
            <Col xs={24} md={12}>
              <Item
                name={`${entityPrefix}Employer`}
                label="Por favor, especifique"
                rules={[buildRequiredRule(''), buildMaxRule(200)]}>
                <Input
                  disabled={isFormBlocked}
                  placeholder={TITLES_BY_ENTITY[entityPrefix]}
                />
              </Item>
            </Col>
          }
        </Row>
      </Card>
    </Row>
  )
}