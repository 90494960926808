import { DataView } from 'primereact/dataview';
import { useState, useEffect } from 'react';
import { GridItem } from '../common/GridItem';
import { Loading } from '../common/Loading';
import { Paginator } from 'primereact/paginator';
import { Button } from '../common/Button';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from '../../utils/consts';
import './Properties.scss';


export const Properties = ({ data = [], initial, loading, total, onPage, isNewSearch }: any) => {
  const navigate = useNavigate()
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(DEFAULT_PAGE_SIZE);

  useEffect(() => {
    if (isNewSearch) setFirst(0)
  }, [isNewSearch])

  const itemTemplate = (item: any) => {
    if (!item) return;
    return <GridItem item={item} initial={initial} />;
  };

  const header = () => {
    return (
      <div className="flex justify-content-between align-items-center px-2">
        <h3 className="m-0 sm:text-2xl text-900">
          {initial ? 'Propiedades destacadas' : `${total} propiedades encontradas`}
        </h3>
        {!initial ? (
          <Button
            label="Ver mapa"
            icon="pi-map"
            className="cursor-pointer py-0.75 px-3"
            onClick={() => navigate('/search/map')}
          />
        ) : (
          ''
        )}
      </div>
    );
  };

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
    onPage(event);
  }

  return (
    <div className="w-full py-5 px-2">
      {loading ? (
        <Loading />
      ) : (
        <>
          <DataView
            value={data}
            className="custom-dataview"
            itemTemplate={itemTemplate}
            header={header()}
            emptyMessage="No se encontraron resultados para tu búsqueda"
            onPage={onPage}
          />
          { !initial &&
            (<Paginator
              first={first}
              rows={rows}
              rowsPerPageOptions={[6, 12, 24, 48]}
              totalRecords={total}
              onPageChange={onPageChange}
            />)
          }
        </>
      )}
    </div>
  );
};
