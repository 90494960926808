import { Modal, Typography, Grid, Row, Col } from "antd"
import { FilePdfOutlined } from "@ant-design/icons";
const url = window.url ? window.url : process.env.REACT_APP_API_URL as string;

const { Link, Title } = Typography
const { useBreakpoint } = Grid
const preQualifyCountLimit = window.preQualifyCountLimit ? window.preQualifyCountLimit : process.env.REACT_APP_PRE_QUALIFY_COUNT_LIMIT as string;

export const FORM_LINKS = {
  INGRESO_DECLARADO: { url: `${url}/assets/Declaracion_de_ingresos.pdf`, label: '¿Cómo completar mi declaración de ingresos?' },
}
 
const INITIAL_MODAL_BY_TYPES: any = {
  default: {
    title: "Información importante",
    body:
      <Row style={{ width: '100%' }} justify="center">
        <Col span={20} className="default-font">
          <Title level={5}>Para realizar tu simulación de calificación, seguí estos pasos:</Title>
          <ol>
            <li><strong>Ingresá</strong> tu correo electrónico y presioná "Solicitar Código". Te llegará un código que deberás ingresar en el formulario.</li>
            <li><strong>Completá</strong> el formulario con los datos requeridos después de ingresar el código correctamente.</li>
            <li><strong>Confirmá</strong> la solicitud. Aparecerá en pantalla el resultado de tu calificación y también recibirás un correo electrónico con el detalle.</li>
          </ol>
          <Title level={5} style={{ fontWeight: 400 }}>Consideraciones:</Title>
          <ul>
            <li>Tenés un límite de {preQualifyCountLimit} intentos de calificación por mes calendario.</li>
            <li>Para completar el campo “Ingreso declarado”, descargá el instructivo: 
              <Link className="bancor-color" style={{ color: 'black', marginLeft: '8px' }} href={FORM_LINKS.INGRESO_DECLARADO.url} target="_blank">
                <FilePdfOutlined style={{ color: '#ff4d4f' }} /> {FORM_LINKS.INGRESO_DECLARADO.label}
              </Link>
            </li>
            <li>Si tenés más de una actividad, podés sumar tus ingresos.</li>
          </ul>
        </Col>
      </Row>
  }
}

export const InitialModal = (props: any) => {
  const { open, onCancel, type } = props
  const currentModal = INITIAL_MODAL_BY_TYPES[type] && type ? INITIAL_MODAL_BY_TYPES[type] : INITIAL_MODAL_BY_TYPES.default
  const screens = useBreakpoint()

  const getModalWidth = () => {
    if (screens.xl || screens.xxl || screens.lg) return '75%';
    else if (screens.md) return '90%';
    else return '100%';
  }

  return (
    <Modal
      width={getModalWidth()}
      open={open}
      onCancel={onCancel}
      okText="Cerrar"
      onOk={onCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
      title={currentModal.title}
    >
      {currentModal.body}
    </Modal>
  )
}