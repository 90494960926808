import { Row, Col, Typography, Divider } from "antd"

type LinePropsType = {
  line: {
    id: number,
    name: string,
    description?: string
  }
}

const { Title, Paragraph } = Typography

const styles = {
  line: {
    color: '#666',
    fontSize: '18px',
    fontWeight: 'normal',
    lineHeight: 1,
    marginTop: '35px'
  }
}

export const Line = (props: LinePropsType) => {
  const { line } = props
  return (
    <Row style={{ width: '100%' }}>
      <Col span={24} style={{ padding: 0 }}>
        <Title style={styles.line} className="default-font" level={4}>{line.name}</Title>
        <Divider style={{ marginTop: '15px' }}/>
      </Col>
      {line.description &&
        <Col span={24} style={{ padding: 0 }}>
          <Paragraph className="default-font">{line.description}</Paragraph>
          <Divider style={{ marginTop: '15px' }}/>
        </Col>
      }
    </Row>
  )
}