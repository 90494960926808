import { Form, Col, Input, Select } from "antd"
import { buildRequiredRule, buildMaxRule } from "../../../utils/ruleBuilders";
import { PROVINCE_OPTIONS, entityPrefixNames } from '../../../utils/consts';
import { availableFormFields } from "../LoanRequest";
import { VisibleFormItem } from "./VisibleFormItem";


const { Item } = Form

export const AddressForm = (props: any) => {
  const { type, entityPrefix, isFormBlocked } = props
  
  return (
    <>
      <VisibleFormItem type={type} fieldName={availableFormFields.ADDRESS} prefix={entityPrefix}>
        <Col xs={24} md={12}>
          <Item
            name={`${entityPrefix}Address`}
            label="Calle o Lote y Manzana"
            rules={[buildRequiredRule('Calle'), buildMaxRule(200)]}>
            <Input disabled={isFormBlocked} placeholder="Calle" />
          </Item>
        </Col>
      </VisibleFormItem>
      <VisibleFormItem type={type} fieldName={availableFormFields.ADDRESS_NUMBER} prefix={entityPrefix}>
        <Col xs={24} md={4}>
          <Item name={`${entityPrefix}AddressNumber`} label="Número" rules={[buildRequiredRule('Número'), buildMaxRule(10)]}>
            <Input disabled={isFormBlocked} placeholder="Número" />
          </Item>
        </Col>
      </VisibleFormItem>
      <VisibleFormItem type={type} fieldName={availableFormFields.ADDRESS_FLOOR} prefix={entityPrefix}>
        <Col xs={24} md={4}>
          <Item name={`${entityPrefix}AddressFloor`} label="Piso" rules={[buildMaxRule(10)]}>
            <Input disabled={isFormBlocked} placeholder="Piso" />
          </Item>
        </Col>
      </VisibleFormItem>
      <VisibleFormItem type={type} fieldName={availableFormFields.ADDRESS_APPARTMENT} prefix={entityPrefix}>
        <Col xs={24} md={4}>
          <Item name={`${entityPrefix}AddressAppartment`} label="Departamento" rules={[buildMaxRule(20)]}>
            <Input disabled={isFormBlocked} placeholder="Departamento" />
          </Item>
        </Col>
      </VisibleFormItem>
      <VisibleFormItem type={type} fieldName={availableFormFields.NEIGHBOORHOOD} prefix={entityPrefix}>
        <Col xs={24} md={12}>
          <Item name={`${entityPrefix}Neighborhood`} label="Barrio" rules={[buildRequiredRule('Barrio'), buildMaxRule(200)]}>
            <Input disabled={isFormBlocked} placeholder="Barrio" />
          </Item>
        </Col>
      </VisibleFormItem>
      <VisibleFormItem type={type} fieldName={availableFormFields.CITY} prefix={entityPrefix}>
        <Col xs={24} md={12}>
          <Item name={`${entityPrefix}City`} label="Localidad" rules={[buildRequiredRule('Localidad'), buildMaxRule(200)]}>
            <Input disabled={isFormBlocked} placeholder="Localidad" />
          </Item>
        </Col>
      </VisibleFormItem>
      <VisibleFormItem type={type} fieldName={availableFormFields.PROVINCE} prefix={entityPrefix}>
        <Col xs={24} md={[entityPrefixNames.PROPERTY, entityPrefixNames.SPOUSE].includes(entityPrefix) ? 12 : 8}>
          <Item
            name={`${entityPrefix}Province`}
            label="Provincia"
            initialValue={3}
            rules={[buildRequiredRule('Provincia')]}>
            <Select
              showSearch
              filterOption={(input, option) => (option?.label || '').toLowerCase().includes(input.toLowerCase())}
              options={PROVINCE_OPTIONS || []}
              disabled={isFormBlocked || !PROVINCE_OPTIONS.length}
              placeholder="Provincia" />
          </Item>
        </Col>
      </VisibleFormItem>
      <VisibleFormItem type={type} fieldName={availableFormFields.POSTAL_CODE} prefix={entityPrefix}>
        <Col xs={24} md={[entityPrefixNames.PROPERTY, entityPrefixNames.SPOUSE].includes(entityPrefix) ? 12 : 8}>
          <Item name={`${entityPrefix}PostalCode`} label="Código Postal" rules={[buildRequiredRule('Código Postal'), buildMaxRule(10)]}>
            <Input disabled={isFormBlocked} placeholder="Código Postal" />
          </Item>
        </Col>
      </VisibleFormItem>
    </>
  )
}