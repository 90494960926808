import { Breadcrumb, Divider, Form, Modal, Row, Typography, notification, Grid, Alert } from "antd"
import { useParams, useNavigate } from "react-router"
import { PropertyValidateForm } from "./components/PropertyValidateForm"
import { useState } from "react"
import { AgencyDataForm } from "./components/AgencyDataForm"
import { OwnerDataForm } from "./components/OwnerDataForm"
import { PropertyDataForm } from "./components/PropertyDataForm"
import { UploadDocumentationForm } from "./components/UploadDocumentationForm"
import { NewButton } from "../../components/common/NewButton"
import { useMutation } from "@tanstack/react-query"
import { REQUEST_TYPES } from "../../utils/consts"
import requestsApi from "../../api/propertyRequests"
import useRecaptcha from "../../hooks/useReCaptcha"


const { Text, Title } = Typography
const { useBreakpoint } = Grid

const Types = {
  [REQUEST_TYPES.agency]: 'inmobiliaria',
  [REQUEST_TYPES.owner]: 'dueño'
}

const styles = {
  title: {
    color: '#666',
    fontSize: '24px',
    fontWeight: 'normal',
    lineHeight: 1,
    marginTop: '25px'
  }
}

const labelSubmitButton = "Confirmar Solicitud"

export const PropertyCertification = () => {
  const { type } = useParams<{ type: 'agency' | 'owner' }>()
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const [isValid, setIsValid] = useState(false)
  const [hasAgreement, setHasAgreement] = useState(undefined)
  const [showConfirm, setShowConfirm] = useState(false)
  const [finished, setFinished] = useState(false)
  const [existPrevious, setExistPrevious] = useState(false)
  const [sendingError, setSendingError] = useState({ open: false, config: { title: "", message: "" } })
  const { sm, md, lg } = useBreakpoint()
  const minHeight = lg ? 'calc(100vh - 118px)' : md ? 'calc(100vh - 260px)' : sm ? 'calc(100vh - 430px)' : 'calc(100vh - 90px)'
  const { renderRecaptcha, captchaValue } = useRecaptcha();


  const { mutate: sendRequest, isLoading: isSending } = useMutation({
    mutationFn: async values => {
      // primero creamos la nueva solicitud
      const { success, request, existPreviousRequest } = await requestsApi.sendRequest(type, values)
      // recorremos todas las imagenes disponibles y las guardamos en el servidor.
      // recorremos todos los documents
      const documentation: Record<string, any[]> = form.getFieldValue('documentation')
      const uploadErrors = []
      if (!request?.id) return ({ success: false, existPreviousRequest })
      for (const [fileType, files] of Object.entries(documentation)) {
        for (const file of files) {
          const formData = new FormData()
          formData.append('file', file.originFileObj)
          const { success } = await requestsApi.uploadFile(request.id, fileType, formData)
          if (!success) {
            // que hacemos cuando haya un error en las imagenes
            uploadErrors.push({ fileType, file })
          }
        }
      }

      return { success, request, uploadErrors }
    },
    onSuccess: ({ success, request, existPreviousRequest }) => {
      if (success && request.id) setFinished(true)
      else if (existPreviousRequest) {
        setIsValid(false)
        setExistPrevious(true)
      }
      else {
        setSendingError({ open: true, config: { title: "Lamentablemente no se pudo procesar su solicitud", message: 'Por favor, intente nuevamente más tarde.' } })
        setFinished(true)
      }
    },
    onError: error => {
      console.log(error)
      setSendingError({ open: true, config: { title: "Lamentablemente no se pudo procesar su solicitud", message: 'Por favor, intente nuevamente más tarde.' } })
      setFinished(true)
    },
    onSettled: () => setShowConfirm(false)
  })

  const onFinish = async (values: any)  => {
    // fix a bug con el name ownerDocument del formulario.
    // Si coloco ese nombre en el name, fallan todos los selects de Ant design al clickear en ellos
    // Posible conflicto con JQUERY
    const { ownerDNI, documentation, ...request } = values
    request.ownerDocument = ownerDNI ?? null
    request.type = type
    sendRequest(request)
  }
  const title = `Certificá tu inmueble ${type ? `(${Types[type]})` : ''}`
  return (
    <Row style={{ position: 'relative', backgroundColor: '#F8F8F8', fontFamily: 'open-sans', padding: '0 10px', minHeight }}>
      <div className="w-full flex flex-column align-items-center lg:mt-8 lg:px-7 md:px-5 px-4">
        <Row style={{ marginTop: '1.5rem', width: '100%' }}>
          <Breadcrumb 
            items={[
              { title: <Text style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>Home</Text> },
              { title: <Text>{title}</Text> }
            ]}
          />
        </Row>
        {!finished &&
          <Row style={{ paddingBottom: '15px', width: '100%' }} justify='start'>
            <Title style={styles.title} className="default-font" level={4}>{title}</Title>
            <Divider style={{ margin: '5px 0' }}/>
            <Form
              style={{ width: '100%' }}
              {...{ form, onFinish, layout: 'vertical', validateTrigger: "onBlur" }}>
              <Row style={{ width: '100%',margin: '10px 0 0' }} justify='center'>
                <Alert
                  icon={
                    <span style={{ fontWeight: 'bold', lineHeight: 1, marginTop: '6px' }}>*</span>
                  }
                  showIcon
                  style={{ width: '100%', fontSize: '13px' }}
                  type="warning"
                  message="Debés completar todos los campos obligatorios (*) para poder confirmar la solicitud"/>
              </Row>
              <PropertyValidateForm form={form} isValid={isValid} setIsValid={setIsValid} captchaValue={captchaValue} existPrevious={existPrevious} setExistPrevious={setExistPrevious}/>
              {isValid &&
                <>
                  {type === REQUEST_TYPES.agency &&
                    <AgencyDataForm form={form} setHasAgreement={setHasAgreement} />
                  }
                  {(hasAgreement || type === REQUEST_TYPES.owner) &&
                    <>
                      <OwnerDataForm type={type} form={form} />
                      <PropertyDataForm form={form} />
                      <UploadDocumentationForm form={form} type={type} />
                      <Row style={{ width: '100%',margin: '30px 0' }} justify='center'>
                        <NewButton
                          styles={{ fontSize: '20px' }}
                          type="primary"
                          label={labelSubmitButton}
                          disabled={isSending || !captchaValue}
                          onClickHandler={async () => {
                            try {
                              const values = await form.validateFields();
                              if (values) setShowConfirm(true)
                            } catch (errors: any) {
                              notification.error({ duration: 3, message: "Es necesario cargar todos los campos obligatorios." })
                              const fieldName: string = errors.errorFields[0].name
                              form.scrollToField(fieldName)
                            }
                          }}/>
                      </Row>
                    </>
                  }
                </>
              }
              <div style={{ marginTop: '25px' }}>
                { renderRecaptcha() }
              </div>
            </Form>
          </Row>
        }
        {finished &&
          <Row style={{ paddingBottom: '15px', width: '100%', marginTop: '40px' }} justify='center'>
            <Title style={styles.title} className="default-font" level={1}>
              ¡Te confirmamos que recibimos tu solicitud!
            </Title>
            <Divider style={{ marginTop: '5px' }}/>
            <Text className="default-font bancor-color">
              El equipo de Bancor se comunicará con vos a la brevedad.
            </Text>
          </Row>
        }
        {sendingError.open &&
          <Row style={{ paddingBottom: '15px', width: '100%', marginTop: '40px' }} justify='center'>
            <Title style={styles.title} className="default-font" level={1}>
              {sendingError.config.title}
            </Title>
            <Divider style={{ marginTop: '5px' }}/>
            <Text className="default-font bancor-color">
              {sendingError.config.message}
            </Text>
          </Row>
        }
        {showConfirm &&
          <Modal
            title="Confirma el envío de la solicitud?"
            open={showConfirm}
            closable={false}
            maskClosable={false}
            keyboard={false}
            onCancel={() => setShowConfirm(false)}
            onOk={form.submit}
            okButtonProps={{ disabled: isSending }}
            okText="Confirmo."
            cancelText="Cancelo."
          >
            <Row style={{ width: '100%', margin: '25px 0' }}>
              <Text>
                Recuerde que este proceso puede demorar unos minutos, no cierre el navegador hasta recibir la confirmación.
              </Text>
            </Row>
          </Modal>
        }
      </div>
    </Row>
  )
}