import { Map } from '../components/home/Map';
import { useQuery } from '@tanstack/react-query';
import { Card } from '../components/common/Card';
import { RequestInfo } from '../components/property/RequestInfo';
import { Button } from '../components/common/Button';
import { useParams, useLocation } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { CalculatorForm } from '../components/home/CalculatorForm';
import { Masonry } from '../components/common/Masonry';
import api from '../api/properties';
import apiUvas from '../api';
import { Loading } from '../components/common/Loading';

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export const Property = () => {
  const { id } = useParams();
  const queryParams = useQueryParams()
  const previewKey = queryParams.get('previewKey');

  const [showCalculator, setShowCalculator] = useState(false);
  const [uvas, setUvas] = useState(0);

  const { isLoading: loadingProperty, data } = useQuery({
    queryKey: ['property', id, previewKey],
    queryFn: api.getProperty,
  });

  const { isLoading: loadingCurrentUva, data: currentUvaData } = useQuery({
    queryKey: ['currentUva'],
    queryFn: apiUvas.getCurrentUva,
  });

  const headerButton = <Button label="Calculadora de UVAs" className="w-full" onClick={(e: any) => setShowCalculator(true)} />;

  const Calculator = () => {
    return (
      <Dialog header="Calculadora de UVAs" visible={showCalculator} style={{ width: '50vw' }} className="custom-dialog" onHide={() => setShowCalculator(false)}>
        <CalculatorForm uvas={uvas} />
      </Dialog>
    );
  };

  useEffect(() => {
    if (data) {
      const total = (data.priceLow + data.priceHigh) / 2;
      setUvas(total);
    }
  }, [data]);

  return (
    <div className="w-full py-5 px-4 md:px-8 lg:px-8">
      {loadingProperty || loadingCurrentUva ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-column md:flex-row align-items-start md:align-items-center justify-content-between lg:mt-8">
            <div>
              <p className="text-xs">
                Appto Hipotecatios Bancor / {data.title} en {data.address}
              </p>
              <h3>
                {data.title} en {data.address}
              </h3>
            </div>
            <div className="price text-lg font-semibold" style={{ color: '#F0643C' }}>
              {data.priceLow} a {data.priceHigh} UVAs
            </div>
          </div>
          <p className="capitalize">
            <i className="pi pi-map-marker mr-2"></i>
            {data.neighborhood?.toLowerCase()}, {data.city?.toLowerCase()}, Argentina
          </p>
          <div className="grid">
            <div className="col-12 relative">
              <Masonry photos={data.photos} />
            </div>
            <div className="col-12 md:col-8 lg:col-8 flex flex-column align-items-center justify-content-start">
              <Card title="Descripción">
                <p className="m-0 text-base text-900">{data.description}</p>
              </Card>
              {data.ammenities?.length > 0 && (
                <Card title="Comodidades">
                  <div className="grid grid-nogutter">
                    {data.ammenities.map((item: any, index: any) => {
                      return (
                        <div className="col-12 md:col-3 mb-2 md:md-0" key={index}>
                          <span className="capitalize">
                            <i className="pi pi-check-circle mr-2"></i>
                            {item.toLowerCase()}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </Card>
              )}
              <Card title="Mapa">
                <div className="grid grid-nogutter">
                  <div className="col-12">
                    <div className="flex align-items-center justify-content-between mb-2 text-900 mr-3">
                      <span>Dirección</span> <span className="font-bold">{data.address}</span>
                    </div>
                    <div className="flex align-items-center justify-content-between mb-2 text-900 mr-3">
                      <span>Ciudad</span>
                      <span className="font-bold">{data.city}</span>
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    {!loadingProperty && (
                      <Map
                        data={[
                          {
                            location: {
                              lat: data.latitude,
                              lon: data.longitude,
                              id: Math.random(),
                            },
                          },
                        ]}
                        center={{
                          lat: data.latitude,
                          lng: data.longitude,
                        }}
                        zoom={16}
                      />
                    )}
                  </div>
                </div>
              </Card>
              <Card title="Inmobiliaria">
                <div className="grid grid-nogutter">
                  <div className="col-12">
                    {data.agency.name && (
                      <div className="flex flex-column md:flex-row align-items-start md:align-items-center justify-content-between mb-3 md:mb-2 text-900">
                        <span>Agente</span>
                        <span className="font-bold">{data.agency.name}</span>
                      </div>
                    )}
                    {data.agency.address && (
                      <div className="flex flex-column md:flex-row align-items-start md:align-items-center justify-content-between mb-3 md:mb-2 text-900">
                        <span>Dirección</span>
                        <span className="font-bold">{data.agency.address}</span>
                      </div>
                    )}
                    {data.agency.mobilePhone && (
                      <div className="flex flex-column md:flex-row align-items-start md:align-items-center justify-content-between mb-3 md:mb-2 text-900">
                        <span>Teléfono Móvil</span>
                        <span className="font-bold">{data.agency.mobilePhone}</span>
                      </div>
                    )}
                    {data.agency.email && (
                      <div className="flex flex-column md:flex-row align-items-start md:align-items-center justify-content-between mb-3 md:mb-2 text-900">
                        <span>Email</span> <span className="font-bold">{data.agency.email}</span>
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </div>
            <div className="col-12 md:col-4 lg:col-4 relative">
              <div className="sticky mb-4" style={{ top: '30px' }}>
                <Card header={headerButton}>
                  <div className="grid grid-nogutter">
                    <div className="col-12">
                      {data.addres && (
                        <div className="flex align-items-center justify-content-between mb-2 text-900 mr-3">
                          <span>Dirección</span>
                          <span className="font-bold">{data.address}</span>
                        </div>
                      )}
                      {data.type && (
                        <div className="flex align-items-center justify-content-between mb-2 text-900 mr-3">
                          <span>Tipo de propiedad</span>
                          <span className="font-bold">{data.type}</span>
                        </div>
                      )}
                      {data.rooms && (
                        <div className="flex align-items-center justify-content-between mb-2 text-900 mr-3">
                          <span>Habitaciones</span>
                          <span className="font-bold">{data.rooms}</span>
                        </div>
                      )}
                      {data.neighborhood && (
                        <div className="flex align-items-center justify-content-between mb-2 text-900 mr-3">
                          <span>Barrio</span>
                          <span className="font-bold">{data.neighborhood}</span>
                        </div>
                      )}
                      <div className="flex align-items-center justify-content-between mb-2 text-900 mr-3">
                        <span>Tipo de barrio</span>
                        <span className="font-bold">{data.openNeighborhood ? 'Abierto' : 'Cerrado'}</span>
                      </div>
                      <div className="flex align-items-center justify-content-between mb-2 text-900 mr-3">
                        <span>Cotización UVAs</span>
                        {loadingCurrentUva ? <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i> : <span className="font-bold">${currentUvaData.value}</span>}
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="col-12">
              <RequestInfo propertyId={id} />
            </div>
          </div>
        </>
      )}
      <Calculator />
    </div>
  );
};
