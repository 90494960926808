import axios from 'axios';

const url = window.url ? window.url : process.env.REACT_APP_API_URL as string;
const config = {
  headers: {
    accept: 'application/json, text/plain, */*',
    'accept-language': 'en-US,en;q=0.9',
    'content-type': 'application/json;charset=UTF-8',
  },
  mode: 'cors',
  credentials: 'include',
};

export default {
  getFilters: async () => {
    const time = new Date().getTime()
    const { data } = await axios.get(
      `${url}/search/searchFilters?cachebuster=${time}`,
      config
    );
    return data;
  },
};
