import { Form, Row, Col, Typography, Radio, Input, Alert, AlertProps } from "antd"
import { useQuery } from '@tanstack/react-query';
import agencyApi from "../../../api/agencies"
import cuitValidator from "../../../utils/CuitValidator";
import { buildRequiredRule, buildMaxRule } from "../../../utils/ruleBuilders";
import { AdressPhoneEmailForm } from "./AdressPhoneEmailForm";
import { useEffect } from "react";
import { REQUEST_TYPES } from "../../../utils/consts";
import { useRef } from "react";


const { Item } = Form
const { Title, Text } = Typography

const styles = {
  helpText: {
    color: '#464646',
    fontSize: '12px'
  },
  button: {
    fontSize: '20px'
  },
  formTitle: {
    fontSize: '16px',
    width: '100%',
    marginBottom: '20px',
    fontWeight: 700
  }
}

const alertConfig: AlertProps = {
  style: { marginBottom: '10px' },
  message:
    <Text>
      Si NO cuenta con convenio APPTO BANCOR; comunicarse vía correo electrónico para su gestión a la casilla: 
      <a className="bancor-color" href="mailto:apptobancor@bancor.com.ar"> apptobancor@bancor.com.ar</a>
    </Text>,
  showIcon: true,
  type: 'warning'
}

export const AgencyDataForm = (props: any) => {
  const { form, setHasAgreement } = props

  const agencyCuit = Form.useWatch('agencyCuit')
  const hasAgreementValue = Form.useWatch('agencyHasAgreement')
  const previousCuitRef = useRef<any>(null)

  useEffect(() => {
    setHasAgreement(hasAgreementValue)
  }, [hasAgreementValue])

  const validateCuit = (_: any, value: any) => {
    if (!cuitValidator(value)) return Promise.reject('El CUIL no es válido')
    else return Promise.resolve()
  }

  const { data: cuitExists, isRefetching, refetch } = useQuery({
    queryKey: ['validateCuit'],
    queryFn: () => agencyApi.validateAgencyCuit(agencyCuit).then(data => data),
    onError: (error: any) => {
      console.log(error)
    },
    initialData: {
      validProp: false,
      validatedProp: false,
      hasCertificate: false,
      hasRequest: false
    },
    enabled: false,
    refetchOnWindowFocus: false
  })

  const showAlert = !cuitExists.valid && cuitExists.valid !== undefined  && cuitValidator(agencyCuit)

  return (
    <Row gutter={[16, 0]} style={{ width: '100%', margin: '20px auto' }}>
      <Title style={styles.formTitle} className="default-font bancor-color" level={3}>
        Datos de la inmobiliaria
        <br />
        <small style={{ fontWeight: 'normal' }}>
          Completar con los datos del corredor matriculado que suscribió el convenio con Bancor
        </small>
      </Title>
      <Col xs={24} md={12}>
        <Item
          name="agencyName"
          label="Razón social"
          rules={[buildRequiredRule('Razón Social'), buildMaxRule(200)]}>
          <Input
            placeholder="Razón social"
          />
        </Item>
      </Col>
      <Col xs={24} md={12}>
        <Item
          name="agencyCuit"
          style={{ marginBottom: showAlert ? '6px' : '24px' }}
          label={
            <Text className="default-font">
              Número de C.U.I.T. <Text style={styles.helpText}>(Ingrese sólo números)</Text>
            </Text>}
          rules={[buildRequiredRule('C.U.I.T.'), { validator: validateCuit }]}>
          <Input
            placeholder="Número de C.U.I.T."
            onChange={e => form.setFieldValue('agencyCuit', e.target.value.replace(/[^0-9]/g, ''))}
            onBlur={e => {
              const currentValue = e.target.value
              if (currentValue !== previousCuitRef.current) {
                previousCuitRef.current = currentValue;
                if (cuitValidator(currentValue)) refetch()
              }
            }}
          />
        </Item>
        {showAlert && !isRefetching && <Alert { ...alertConfig } />}
      </Col>
      <Col xs={24} md={12}>
        <Item
          name="agencyHasAgreement"
          label="¿Tiene convenio APPTO Bancor?"
          rules={[buildRequiredRule('Tiene convenio APPTO Bancor')]}>
          <Radio.Group>
            <Radio value={true}>Si</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Item>
        {!hasAgreementValue && hasAgreementValue !== undefined && <Alert { ...alertConfig } />}
      </Col>
      <Col xs={24} md={12}>
        <Item
          name="agencyLicense"
          label="Número de matrícula"
          rules={[buildRequiredRule('N° de matrícula'), buildMaxRule(200)]}>
          <Input placeholder="CPI o CaCIC" />
        </Item>
      </Col>
      {hasAgreementValue &&
        <>
          <Col span={24}>
            <Title
              level={4}
              className="default-font"
              style={{ textAlign: "left", display: 'block', fontSize: '14px', fontWeight: 'normal' }}>
              Domicilio comercial
            </Title>
          </Col>
          <AdressPhoneEmailForm type={REQUEST_TYPES.agency} form={form} />
        </>
      }
    </Row>
  )
}