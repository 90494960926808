import { List, Row, Typography } from "antd"

type sectionPropsType = {
  sections: {
    lineSectionId: number,
    name: string,
    description: string,
    attachments: { attachmentId: number, attachmentName: string }[]
  }[]
}

const { Title, Link } = Typography

const styles = {
  name: {
    color: '#005f5a',
    marginTop: '15px',
    marginBottom: '6px',
    width: '100%',
    fontWeight: 'normal',
  }
}

export const LineSections = (props: sectionPropsType) => {
  const { sections } = props
  return (
    <Row style={{ width: '100%' }}>
      <List
        size="small"
        dataSource={sections ?? []}
        renderItem={({ lineSectionId, name, description, attachments }) => {
          return (
            <Row key={`${lineSectionId}-${name}`} style={{ paddingBottom: '15px' }} justify='start'>
              <Title style={styles.name} className='default-font' level={5}>{name}</Title>
              <div
                className="sections-description"
                dangerouslySetInnerHTML={{ __html: description }} />
              {attachments.length > 0 &&
                <ul>
                  {
                    attachments.map(({ attachmentId, attachmentName }) =>
                      <li key={`attachment-${attachmentId}-${attachmentName}`} style={{ listStyleType: 'disc' }}>
                        <Link
                          target="_blank"
                          href={`/creditLine/download/${attachmentId}`}
                          className="bancor-color default-font"
                          style={{ textDecoration: 'none' }}
                          >
                            {attachmentName}
                        </Link>
                      </li>
                    )
                  }
                </ul>
              }
            </Row>
          )
        }}
      />
    </Row>
  )
}