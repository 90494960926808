import { Form, Row, Col, Typography, Input, Checkbox, InputNumber } from "antd"
import cuitValidator from "../../../utils/CuitValidator";
import { buildRequiredRule, buildMaxRule, buildEmailRule } from "../../../utils/ruleBuilders";
import { entityPrefixNamesPreQualify } from '../../../utils/consts';
import { availableFormFields, formStyles } from '../PreQualify';
import { VisibleFormItem } from "./VisibleFormItem";
import { FORM_LINKS } from "./InitialModal";
import { formatterNumber, parserNumber } from "../../../utils/formatNumbers";
import React, {useState} from 'react';


const { Item } = Form
const { Title, Text, Link } = Typography


const TITLES_BY_ENTITY = {
  [entityPrefixNamesPreQualify.REQUESTER]: 'Datos de la persona solicitante',
  [entityPrefixNamesPreQualify.COSIGNER_ONE]: 'Garante (Suma ingresos)',
  [entityPrefixNamesPreQualify.COSIGNER_TWO]: 'Garante 2 (Suma ingresos)',
  [entityPrefixNamesPreQualify.SPOUSE]: 'Cónyuge / Conviviente',
}

const ALLOWED_DECLARED_INCOME_KEYS: string[] = ['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Backspace', 'Delete']

export const PersonalDataForm = (props: any) => {
  const { form, entityPrefix, isFormBlocked } = props

  const [showError, setShowError] = useState<boolean>(false)
  const [checkedValues, setCheckedValues] = useState<string[]>([]);

  const validateCuit = (_: any, value: any) => {
    if (!cuitValidator(value)) return Promise.reject('El CUIL no es válido')
    else return Promise.resolve()
  }

  const spouseIsCosigner = Form.useWatch('spouseIsCoSigner', form)
  const [cuilValue, setCuilValue] = useState('');
  const [phoneNumberValue, setPhoneNumberValue] = useState('');


  const validateCuitNotRepeated = (fields: any, value: any) => {
    const { field: fieldName } = fields
    const documentFields = [
      `${entityPrefixNamesPreQualify.REQUESTER}Document`,
      `${entityPrefixNamesPreQualify.SPOUSE}Document`,
      `${entityPrefixNamesPreQualify.COSIGNER_ONE}Document`,
      `${entityPrefixNamesPreQualify.COSIGNER_TWO}Document`
    ]
    const cuitFound = Object.entries(form.getFieldsValue(documentFields)).find(([field, cuit]) => cuit === value && field !== fieldName)
    if (cuitFound) return Promise.reject('Este CUIL ya ha sido utilizado en este formulario')
    else return Promise.resolve()
  }

  const isValidDeclaredIncomeInputKey = (key: any) => {
    if (ALLOWED_DECLARED_INCOME_KEYS.includes(key)) {
      return true;
    }
  }

  const handleChange = (newCheckedValues: string[]) => {
    if (newCheckedValues.includes(`${entityPrefix}Monotributista`) && newCheckedValues.includes(`${entityPrefix}Autónomo`)) {
      if (newCheckedValues.indexOf(`${entityPrefix}Autónomo`) < newCheckedValues.indexOf(`${entityPrefix}Monotributista`)) {
        setCheckedValues(newCheckedValues.filter(value => value !== `${entityPrefix}Autónomo`));
        form.setFieldValue(`${entityPrefix}HasAutonomousIncomes`, false)
      } else {
        setCheckedValues(newCheckedValues.filter(value => value !== `${entityPrefix}Monotributista`));
        form.setFieldValue(`${entityPrefix}HasMonotributeIncomes`, false)
      }
    } else {
      setCheckedValues(newCheckedValues);
    }
    setShowError(false);
  };

  const onCuilInputChange = (e:any) => {
    const newValue = e.target.value.replace(/[^0-9]/g, '');
    setCuilValue(newValue);
    form.setFieldValue(`${entityPrefix}Document`, newValue);
  }
  
  const onPhoneNumberChange = (e:any) => {
    const newValue = e.target.value.replace(/[^0-9]/g, '');
    setPhoneNumberValue(newValue);
    form.setFieldValue(`${entityPrefix}PhoneNumber`, newValue);
  }

const validation = () => {
  if(incomesOriginValidator(checkedValues)) {
    return Promise.resolve()
  } else {
    return Promise.reject('Debe seleccionar al menos un origen de ingresos')
  }
};

const incomesOriginValidator = (checkedValues: any) => {
  if(!checkedValues || checkedValues?.length < 1) {
    setShowError(true);
    return false
  }
  setShowError(false);
  return true
}

  return (
    <>
      <Row gutter={[16, 0]} style={{ width: '100%', margin: '20px auto 0' }}>
        {entityPrefix === entityPrefixNamesPreQualify.REQUESTER &&
          <Title style={formStyles.formTitle} className="default-font bancor-color" level={3}>
            {TITLES_BY_ENTITY[entityPrefix]}
          </Title>
        }
        {entityPrefix === entityPrefixNamesPreQualify.SPOUSE &&
          <Col span={24}>
            <Item
              valuePropName="checked"
              name="spouseIsCoSigner">
              <Checkbox disabled={isFormBlocked}>Es codeudor (Suma ingresos)</Checkbox>
            </Item>
          </Col>
        }
        <VisibleFormItem fieldName={availableFormFields.LAST_NAME} prefix={entityPrefix}>
          <Col xs={24} md={12}>
            <Item
              name={`${entityPrefix}LastName`}
              label="Apellido/s"
              rules={[buildRequiredRule('Apellido/s'), buildMaxRule(200)]}>
              <Input disabled={isFormBlocked} placeholder="Apellido/s" />
            </Item>
          </Col>
        </VisibleFormItem>
        <VisibleFormItem fieldName={availableFormFields.NAME} prefix={entityPrefix}>
          <Col xs={24} md={12}>
            <Item
              name={`${entityPrefix}Name`}
              label="Nombre/s"
              rules={[buildRequiredRule('Nombre/s'), buildMaxRule(200)]}>
              <Input disabled={isFormBlocked} placeholder="Nombre/s" />
            </Item>
          </Col>
        </VisibleFormItem>
        <VisibleFormItem fieldName={availableFormFields.EMAIL} prefix={entityPrefix}>
          <Col xs={24} md={12} lg={5}>
            <Item
              name={`${entityPrefix}Email`}
              label="Correo Electrónico"
              rules={[buildRequiredRule('Email'), buildMaxRule(200), buildEmailRule()]}>
              <Input disabled={entityPrefix === entityPrefixNamesPreQualify.REQUESTER} placeholder="Correo Electrónico" />
            </Item>
          </Col>
        </VisibleFormItem>
        <Col xs={24} md={12} lg={4}>
          <Item
            name={`${entityPrefix}Document`}
            style={{ marginBottom: '24px' }}
            label={
              <Text className="default-font">
                Número de C.U.I.L.
              </Text>
            }
            rules={[buildRequiredRule('C.U.I.L.'), { validator: validateCuit }, { validator: validateCuitNotRepeated } ]}>
            <Input
              placeholder="Número de C.U.I.L."
              value={cuilValue}
              onChange={onCuilInputChange}
            />
            <div style={{paddingLeft: '5px'}}>
            <Text style={formStyles.helpText}>(Ingrese sólo números)</Text>
            </div>
          </Item>
        </Col>
        <Col xs={24} md={12} lg={4}>
          <Item
            name={`${entityPrefix}PhoneNumber`}
            style={{ marginBottom: '24px' }}
            label={
              <Text className="default-font">
                Número de teléfono.
              </Text>
            }
            rules={[buildRequiredRule('Teléfono'), buildMaxRule(25)]}>
            <Input
              placeholder="Número de teléfono."
              value={phoneNumberValue}
              onChange={onPhoneNumberChange}
            />
            <div style={{paddingLeft: '5px'}}>
            <Text style={formStyles.helpText}>(Ingrese sólo números)</Text>
            </div>
          </Item>
        </Col>
        {(entityPrefix !== entityPrefixNamesPreQualify.SPOUSE || spouseIsCosigner ) &&
        <>
        <Col xs={24} md={12} lg={7}>
          <Text className='ant-form-item-required' style={formStyles.formRequired}>* </Text>
          <Text className="default-font">
            Marcá la(s) situación(es) laboral(es) a declarar.
          </Text>
          <Checkbox.Group style={{marginBottom: '24px' }} onChange={handleChange} value={checkedValues}>
              <Item
                rules={[{ validator: validation }]}
                noStyle
                valuePropName="checked"
                name={`${entityPrefix}HasMonotributeIncomes`}>
                <Checkbox value={`${entityPrefix}Monotributista`} style={{ width: '100%' }}>Monotributista</Checkbox>
              </Item>
              <Item
                rules={[{ validator: validation }]}
                noStyle
                valuePropName="checked"
                name={`${entityPrefix}HasAutonomousIncomes`}>
                <Checkbox value={`${entityPrefix}Autónomo`} style={{ width: '100%' }}>Autónomo</Checkbox>
              </Item>
              <Item
                rules={[{ validator: validation }]}
                noStyle
                valuePropName="checked"
                name={`${entityPrefix}HasEmployeeIncomes`}>
                <Checkbox value={`${entityPrefix}Relación de dependencia`} style={{ width: '100%' }}>Relación de dependencia</Checkbox>
              </Item>
              {showError &&
                <div style={{ color: "#ff4d4f" }}>
                  Al menos una fuente de ingresos es requerida.
                </div>
              }
          </Checkbox.Group>
        </Col>
          <Col xs={24} md={12} lg={4} style={{ display: 'flex', columnGap: '20px' }}>
            <div style={{ flexGrow: 1 }}>
              <Item
                style={{ marginBottom: "4px" }}
                name={`${entityPrefix}DeclaredIncome`}
                label={
                  <Text className="default-font" style={{ display: 'flex', alignItems: 'center' }}>
                    Ingresos Declarados
                  </Text>
                }
                rules={[buildRequiredRule('Ingresos Declarados')]}>
                <InputNumber<number>
                  style={{ width: '100%' }}
                  addonBefore="$"
                  disabled={isFormBlocked}
                  formatter={(value) => formatterNumber(value)}
                  parser={(value) => parserNumber(value)}
                  placeholder="Ingresos declarados"
                  min={1}
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key) && !isValidDeclaredIncomeInputKey(event.key)) {
                      event.preventDefault()
                    }
                  }}
                />
              </Item>
              <Text style={{ display: 'block', fontSize: '13px' }}>Conocé acá:
                <Link className="bancor-color" style={{ color: 'black', marginLeft: '7px' }} href={FORM_LINKS.INGRESO_DECLARADO.url} target="_blank">
                  {FORM_LINKS.INGRESO_DECLARADO.label}
                </Link>
              </Text>
            </div>
          </Col>
          </>
        }
      </Row>
    </>
  )
}