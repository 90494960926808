import axios from 'axios';
const url = window.url ? window.url : process.env.REACT_APP_API_URL as string;

const config = {
  headers: {
    accept: 'application/json, text/plain, */*',
    'accept-language': 'en-US,en;q=0.9',
    'content-type': 'application/json;charset=UTF-8',
  },
  mode: 'cors',
  credentials: 'include',
};

export default {
  sendRequest: async (type: 'agency' | 'owner' | undefined, request: any) => {
    if (!type) return
    const time = new Date().getTime()
    const { data } = await axios.post(
      `${url}/propertyRequest/${type}?cachebuster=${time}`,
      request,
      config
    );
    return data;
  },
  uploadFile: async (requestId: number, type: string | undefined, file: any) => {
    if (!type) return
    const time = new Date().getTime()
    const { data } = await axios.post(
      `${url}/propertyRequest/${requestId}/upload/?cachebuster=${time}`,
      file,
      { ...config, headers: { ...config.headers, 'Content-Type': 'multipart/form-data' }, params: { type } }
    );
    return data;
  },
  setSuccess: async (type: 'agency' | 'owner' | undefined, requestId: number) => {
    if (!type) return
    const time = new Date().getTime()
    const { data } = await axios.post(
      `${url}/propertyRequest/${type}/${requestId}/success?cachebuster=${time}`,
      {},
      config
    );
    return data;
  }
};