import { Rule } from "antd/es/form"

export const buildRequiredRule = (nameField: string) => ({
  required: true,
  message: `El campo ${nameField} es requerido.`
})

export const buildMaxRule = (value: number) => ({
  max: value,
  message: `La cantidad máxima de caracteres es ${value}`
})

export const buildEmailRule = (): Rule => ({ type: 'email', message: 'Ingrese un email válido.'})

export const buildLengthRule = (value: number) => ({
  len: value,
  message: `La cantidad requerida de caracteres es ${value}`
})