import { Col, Divider, List, Row, Typography } from "antd"

export type FaqCategoryPropsType = {
  faqsByCategory: FaqCategoryType[]
}

export type FaqCategoryType = {
  category: {
    id: number,
    nameEs: string
  },
  faqs: FaqType[] 
}

type FaqType = {
  faqId: number,
  question: string,
  response: string
}

const { Title } = Typography

const styles = {
  question: {
    color: '#005f5a',
    marginTop: '15px',
    marginBottom: '6px',
    width: '100%',
    fontWeight: 'normal',
  },
  category: {
    color: '#666',
    fontSize: '18px',
    fontWeight: 'normal',
    lineHeight: 1,
    marginTop: '35px'
  }
}

export const FaqCategoryList = (props: FaqCategoryPropsType) => {
  const { faqsByCategory } = props
  return (
    <Row style={{ width: '100%' }}>
      {faqsByCategory.map(({ category, faqs }) => 
        <Col span={24} style={{ padding: 0 }}>
          <Title style={styles.category} className="default-font" level={4}>{category.nameEs}</Title>
          <Divider style={{ marginTop: '15px' }}/>
          <List
            size="small"
            dataSource={faqs ?? []}
            renderItem={({ faqId, question, response }) => {
              return (
                <Row key={faqId} style={{ paddingBottom: '30px' }} justify='start'>
                  <Title className='default-font' style={styles.question} level={5}>{question}</Title>
                  <div dangerouslySetInnerHTML={{ __html: response }} />
                </Row>
              )
            }}
          />
        </Col>
      )
      }
    </Row>
  )
} 