import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { Property } from './pages/Property';
import { Header } from './components/common/Header';
import { Footer } from './components/common/Footer';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './globals.scss';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PropertySearch } from './pages/PropertySearch';
import { NewPropertySearchMap } from './pages/NewPropertySearchMap';
import { FAQ } from './pages/FAQ/FAQ';
import { CreditLine } from './pages/creditLine/CreditLine';
import { PropertyCertification } from './pages/propertyCertification/PropertyCertification';
import { LoanRequest } from './pages/loanRequest/LoanRequest';
import { ConfigProvider } from 'antd';
import { Login } from './pages/login/Login';
import { PreQualify } from './pages/preQualify/PreQualify';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

declare global {
  interface Window { url: string; googleMapApiKey: string; recaptchaApiKey: string; preQualifyCountLimit: string}
}

export default function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#275d5a',
          colorBgContainer: 'white',
          colorSuccess: '#275d5a',
          colorWarning: '#f0643c',
          colorWarningBorder: '#f0643c',
          colorInfo: '#275d5a',
          colorInfoBg: '#e3efef',
          colorInfoBorder: '#275d5a',
        },
        components: {
          Button: {
            defaultBg: '#f0643c',
            defaultColor: '#fff',
          }
        }
        
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/search" element={<PropertySearch />} />
            <Route path="/search/map" element={<NewPropertySearchMap />} />
            <Route path="/properties/:id" element={<Property />} />
            <Route path="/appto/properties/:id" element={<Property />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/creditLines/buy" element={<CreditLine />} />
            <Route path="/creditLines/develop" element={<CreditLine />} />
            <Route path="/creditLines/build" element={<CreditLine />} />
            <Route path="/propertyRequest/:type" element={<PropertyCertification />} />
            <Route path="/request/preQualify" element={<PreQualify />} />
            <Route path="/request/:type" element={<LoanRequest />} />
            <Route path="/login/auth" element={<Login />} />
            <Route path="/login/authenticate" element={<Login error={true} />} />
            <Route path="*" element={<Home />} />
          </Routes>
          <Footer />
        </Router>
      </QueryClientProvider>
    </ConfigProvider>
  );
}
