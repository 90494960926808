import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { useContext, useEffect, useState } from 'react';
import { Button } from '../common/Button';
import { useQuery } from '@tanstack/react-query';
import apiFilters from '../../api/filters';
import apiNeighborhoods from '../../api/neighborhoods';
import { FiltersContext } from '../../context';
import { AutoComplete } from 'primereact/autocomplete';
import { useNavigate } from 'react-router-dom';
import './Filters.scss';


export const Filters = (props: any) => {
  const filters = useContext(FiltersContext);
  const [type, setType] = useState('');
  const [query, setQuery] = useState('');
  const [neighborhoods, setNeighborhoods] = useState<any[]>([]);
  const navigate = useNavigate()

  const { data: filtersData } = useQuery({
    queryKey: ['filters'],
    queryFn: apiFilters.getFilters,
  });

  const { data: neighborhoodsData, isLoading : isLoadingNeighboorhoods,  refetch } = useQuery({
    queryKey: ['neighborhoods', query],
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: apiNeighborhoods.getNeighborhoods,
  });

  useEffect(() => {
    setNeighborhoods(neighborhoodsData);
  }, [neighborhoodsData]);

  const selectType = (e: any) => {
    setType(e.value);
    filters.types = [e.value];
    filters.initial = false;
    filters.max = 10000;
  };

  const handleInput = (e: any) => {
    setQuery(e.query.toLowerCase());
    search();
  };

  const handleSelect = (e: any) => {
    filters.initial = false;
    filters.max = 50;
    filters.query = e.value.name
    filters.longitude = e.value.longitude;
    filters.latitude = e.value.latitude;
    filters.location = {
      formatted_address: e.value.address,
    };
  };

  const search = () => {
    if(query.length > 4) {
      refetch();
    }
  }
  
  return (
    <div className="filters mx-2 md:mx-8 mb-6 border-1 border-400 flex flex-column md:flex-row align-items-center justify-content-between py-3 md:py-0">
      <div className="flex flex-column md:flex-row align-items-center flex-1 w-full md:w-auto">
        <Dropdown
          value={type}
          onChange={selectType}
          options={filtersData?.roomTypes}
          optionLabel="name"
          optionValue="id"
          placeholder="Departamento"
          className="w-full md:w-12rem border-none custom-dropdown"
        />
        <Divider layout="vertical" className="hidden md:inline" />
        <AutoComplete
          className="border-none custom-input w-full md:w-20rem flex-1"
          field="name"
          value={query}
          placeholder="Ingresá una ubicación..."
          suggestions={neighborhoods}
          completeMethod={handleInput}
          onChange={(e) => setQuery(e.value)}
          onSelect={handleSelect}
        />
      </div>
      <div style={{ columnGap: '10px' }} className="flex flex-row align-items-center justify-content-center w-full md:w-auto">
        <Button
          label="Buscar"
          icon="pi-search"
          className="cursor-pointer py-0.75 px-3"
          onClick={() => navigate('/search')}
        />
        <Button
          label="Buscar en mapa"
          icon="pi-map"
          className="cursor-pointer py-0.75 px-3"
          onClick={() => navigate('/search/map')}
        />
      </div>
    </div>
  );
};
