import { useQuery } from '@tanstack/react-query';
import api from '../../api/faqs';
import { Loading } from '../../components/common/Loading';
import { FaqCategoryList } from './components/FaqCategoryList';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Row, Typography } from 'antd';


const { Text } = Typography

export const FAQ = () => {
  const navigate = useNavigate()
  const { isLoading, data: faqsByCategory } = useQuery({
    queryKey: ['faqs'],
    queryFn: api.getFaqs
  });

  return (
    <Row style={{ position: 'relative', backgroundColor: '#F8F8F8', fontFamily: 'open-sans' }}>
      <div className="w-full flex flex-column align-items-center lg:mt-8 lg:px-7 px-4">
        <Row style={{ marginTop: '1.5rem', width: '100%' }}>
          <Breadcrumb
            items={[
              {
                title:
                  <Text style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>Home</Text>,
              },
              {
                title: <Text>Preguntas Frecuentes</Text>,
              }
            ]}
          />
        </Row>
        {isLoading
          ? <Loading />
          : <FaqCategoryList faqsByCategory={faqsByCategory} />
        }
      </div>
    </Row>
  )
}