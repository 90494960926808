import { Row } from 'antd';
import { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const SITE_KEY =  window.recaptchaApiKey ? window.recaptchaApiKey : process.env.REACT_APP_RECAPTCHA_KEY as string;

const useRecaptcha = () => {
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  useEffect(() => {
    // Necesitamos hacer algo con el value del captcha?
  }, [captchaValue]);

  const resetRecaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const renderRecaptcha = () => {
    return (
      <Row style={{ width: '100%', margin: '20px auto' }} justify="center">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={SITE_KEY}
          onChange={setCaptchaValue}
        />
      </Row>
    );
  };

  return { renderRecaptcha, captchaValue, resetRecaptcha };
};

export default useRecaptcha;
