import { Form, Row, Col, Typography, Input } from "antd"
import { buildRequiredRule, buildMaxRule } from "../../../utils/ruleBuilders";
import { REQUEST_TYPES } from "../../../utils/consts";
import { AdressPhoneEmailForm } from "./AdressPhoneEmailForm";

const { Item } = Form
const { Title } = Typography

const styles = {
  helpText: {
    color: '#464646',
    fontSize: '12px'
  },
  button: {
    fontSize: '20px'
  },
  formTitle: {
    fontSize: '16px',
    width: '100%',
    marginBottom: '20px',
    fontWeight: 700
  }
}

export const OwnerDataForm = (props: any) => {
  const { form, type } = props

  return (
    <Row gutter={[16, 0]} style={{ width: '100%', margin: '20px auto' }}>
      <Title style={styles.formTitle} className="default-font bancor-color" level={3}>
        Datos del Propietario del Inmueble
      </Title>
      <Col xs={24} md={type === REQUEST_TYPES.agency ? 24 : 12}>
        <Item
          name="ownerName"
          label="Nombre y Apellido"
          rules={[buildRequiredRule('Nombre y Apellido'), buildMaxRule(200)]}>
          <Input
            placeholder="Nombre y Apellido"
          />
        </Item>
      </Col>
      {type === REQUEST_TYPES.owner &&
        <>
          <Col xs={24} md={12}>
            <Item
              name="ownerDNI"
              label="DNI"
              rules={[buildRequiredRule('DNI')]}>
              <Input
                placeholder="DNI"
                onChange={e => form.setFieldValue('ownerDNI', e.target.value.replace(/[^0-9]/g, ''))}
              />
            </Item>
          </Col>
          <AdressPhoneEmailForm type={REQUEST_TYPES.owner} form={form} />
        </>
      }
        <Col span={24}>
          <Item
            name="ownerUsdAccount"
            label="CBU de Cuenta en Pesos Activa en alguna Entidad Financiera"
            rules={[buildRequiredRule('CBU de cuenta en pesos'), buildMaxRule(22)]}>
            <Input
              placeholder="CBU de Cuenta en Pesos Activa en alguna Entidad Financiera"
              onChange={e => form.setFieldValue('ownerUsdAccount', e.target.value.replace(/[^0-9/-]/g, ''))}
            />
          </Item>
        </Col>
        <Col span={24}>
          <Item
            name="ownerUsdAccountBank"
            label="Entidad Financiera"
            rules={[buildRequiredRule('Entidad Financiera'), buildMaxRule(200)]}>
            <Input placeholder="Entidad Financiera" />
          </Item>
        </Col>
    </Row>
  )
}