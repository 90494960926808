import { Alert, Breadcrumb, Col, Divider, Input, Row, Typography, Grid } from "antd"
import { useNavigate } from "react-router-dom"
import { NewButton } from "../../components/common/NewButton"
import { LoginOutlined } from "@ant-design/icons"
import { useState } from "react"

const { Text, Title } = Typography
const { useBreakpoint } = Grid

const urlServer = window.url ? window.url : process.env.REACT_APP_API_URL as string;
const actionURL = `${urlServer}/login/authenticate`

export const formStyles = {
  helpText: {
    color: '#464646',
    fontSize: '12px'
  },
  button: {
    fontSize: '20px'
  },
  formTitle: {
    fontSize: '16px',
    width: '100%',
    marginBottom: '20px',
    fontWeight: 700
  }
}

const styles = {
  title: {
    color: '#464646',
    fontSize: '24px',
    fontWeight: 'normal',
    lineHeight: 1,
    marginTop: '25px'
  }
}

export const Login = (props: { error?: boolean }) => {
  const navigate = useNavigate()
  const [showError, setShowError] = useState<boolean>(!!props.error)
  const { sm, md, lg } = useBreakpoint()
  const minHeight = lg ? 'calc(100vh - 118px)' : md ? 'calc(100vh - 260px)' : sm ? 'calc(100vh - 430px)' : 'calc(100vh - 90px)'

  return (
    <Row style={{ position: 'relative', backgroundColor: '#F8F8F8', fontFamily: 'open-sans', padding: '0 10px', minHeight }}>
      <div className="w-full flex flex-column align-items-center lg:mt-8 lg:px-7 md:px-5 px-4">
        <Row style={{ marginTop: '1.5rem', width: '100%' }}>
          <Breadcrumb 
            items={[
              { title: <Text className="bancor-color default-font" style={{ cursor: 'pointer', fontSize: '12px' }} onClick={() => navigate('/')}>Appto</Text> },
              { title: <Text className="default-font" style={{ fontSize: '12px' }}>Ingresar</Text> }
            ]}
          />
        </Row>
        <Row style={{ paddingBottom: '15px', width: '100%' }} justify='start'>
          <Title style={styles.title} className="default-font" level={4}>Ingresar</Title>
          <Divider style={{ margin: '5px 0' }}/>
          <form
            method="POST"
            action={actionURL}
            style={{ width: '100%', marginTop: '40px' }}
          >
            <Row style={{ width: '100%', justifyContent: 'center', marginBottom: "24px" }}>
              <Col xs={20} sm={20} md={12}>
                <Typography.Text className="default-font" style={{ display: 'block', marginBottom: "5px" }}>Nombre de usuario</Typography.Text>
                <Input name="username" placeholder="Nombre de usuario" required onBlur={() => setShowError(false)}/>
              </Col>
            </Row>
            <Row style={{ width: '100%', justifyContent: 'center' }}>
              <Col xs={20} sm={20} md={12}>
                <Typography.Text className="default-font" style={{ display: 'block', marginBottom: "5px" }}>Contraseña</Typography.Text>
                <Input.Password name="password" type="password" placeholder="Contraseña" required onBlur={() => setShowError(false)}/>
              </Col>
            </Row>
            {showError &&
              <Row style={{ width: '100%', justifyContent: 'center', marginTop: '15px' }}>
                <Col xs={20} sm={20} md={12}>
                  <Alert
                    closable
                    showIcon
                    style={{ fontSize: '13px' }}
                    type="error"
                    message="Credenciales incorrectas, por favor verifique el nombre de usuario o contraseña." />  
                </Col>
              </Row>
            }
            <Row style={{ width: '100%', justifyContent: 'center', marginTop: '20px' }}>
              <Col xs={20} sm={20} md={12} lg={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <NewButton
                  styles={{ fontSize: '14px' }}
                  type="primary"
                  icon={<LoginOutlined />}
                  label="Ingresar"
                  htmlType="submit"
                  />
              </Col>
              <Divider style={{ width: '100%', marginTop: '40px' }} />
            </Row>
          </form>
        </Row>
      </div>
    </Row>
  )
}