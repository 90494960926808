import axios from 'axios';
const url = window.url ? window.url : process.env.REACT_APP_API_URL as string;
const config = {
  headers: {
    accept: 'application/json, text/plain, */*',
    'accept-language': 'en-US,en;q=0.9',
    'content-type': 'application/json;charset=UTF-8',
  },
  mode: 'cors',
  credentials: 'include',
};

export default {
  getBanners: async () => {
    const time = new Date().getTime()
    const { data } = await axios.get(
      url + `/property/banners?cachebuster=${time}`,
      config
    );
    return data;
  },

  getCurrentUva: async () => {
    const time = new Date().getTime()
    const { data } = await axios.get(`${url}/currentUva?cachebuster=${time}`, config);
    return data;
  },

  getMyStatus: async (body: any) => {
    const time = new Date().getTime()
    const { data } = await axios.post(`${url}/myStatus?cachebuster=${time}`, body, config);
    return data;
  },

  postRequestSecondInbursement: async (body: any) => {
    const { data } = await axios.post(`${url}/requestInbursement`, body, config);
    return data;
  },

  postContact: async (body: any) => {
    const { data } = await axios.post(`${url}/contact`, body, config);
    return data;
  },
};
