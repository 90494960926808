import axios from 'axios';
import { GOOGLE_MAP_TYPES } from '../utils/consts';

const url = window.url ? window.url : process.env.REACT_APP_API_URL as string;
const config = {
  headers: {
    accept: 'application/json, text/plain, */*',
    'accept-language': 'en-US,en;q=0.9',
    'content-type': 'application/json;charset=UTF-8',
  },
  mode: 'cors',
  credentials: 'include',
};

export default {
  getNeighborhoods: async ({ queryKey }: any) => {
    const time = new Date().getTime()
    const [_, query] = queryKey;
    const { data } = await axios.get(
      `${url}/neighborhoods?cachebuster=${time}&query=${query}`,
      config
    );

    const respAddress :any = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        key : window.googleMapApiKey ?? process.env.REACT_APP_GOOGLE_MAP_API_KEY,
        address: query + ', provincia de Cordoba, Argentina',
        sensor: false
      }
    });
    respAddress.data?.results?.map((res:any) => {
      if (res.formatted_address !='Argentina') {
        data.push({
          enabled:true,
          normalizedName : res.formatted_address,
          id: Math.floor(Math.random() * 1000),
          name: res.formatted_address,
          address: res.formatted_address,
          latitude: res.geometry.location.lat,
          longitude: res.geometry.location.lng
        });
      }      
    })
    return data;
  },
  getNewCenterName: async (latlng: any) => {
    
    const respAddress :any = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        key : window.googleMapApiKey ?? process.env.REACT_APP_GOOGLE_MAP_API_KEY,
        latlng, 
        sensor: false
      }
    });
    const { data: { results } } = respAddress
    
    const neighborhood = results.find(({ types }: any) => types.includes(GOOGLE_MAP_TYPES.NEIGHBORHOOD))
    if (neighborhood) return neighborhood.formatted_address

    const location = results.find(({ types }: any) => types.includes(GOOGLE_MAP_TYPES.LOCALITY))
    if (location) return location.formatted_address
    
    const area = results.find(({ types }: any) => types.includes(GOOGLE_MAP_TYPES.AREA))
    if (area) return area.formatted_address
  }
};
