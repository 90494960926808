import { useQuery } from '@tanstack/react-query';
import api from '../../api/creditLines';
import { Loading } from '../../components/common/Loading';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Row, Typography } from 'antd';
import { Line } from './components/Line';
import { LineSections } from './components/LineSections';


const { Text } = Typography

export const CreditLine = () => {
  const navigate = useNavigate()
  const { isLoading, data: creditLines } = useQuery({
    queryKey: ['creditLine'],
    queryFn: api.getCreditLines
  });

  return (
    <Row style={{ position: 'relative', backgroundColor: '#F8F8F8', fontFamily: 'open-sans', padding: '0 10px' }}>
      <div className="w-full flex flex-column align-items-center lg:mt-8 lg:px-7 md:px-5 px-4">
        <Row style={{ marginTop: '1.5rem', width: '100%' }}>
          <Breadcrumb
            items={[
              {
                title:
                  <Text style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>Home</Text>,
              },
              { title: <Text>Casa Bancor</Text> }
            ]}
          />
        </Row>
        {isLoading
          ? <Loading />
          : <>
              <Line line={creditLines.line} />
              <LineSections sections={creditLines.sections} />
            </>
        }
      </div>
    </Row>
  )
}