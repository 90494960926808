import { Menu } from 'primereact/menu';
import { useEffect, useRef, useState } from 'react';
import './MenuItem.scss';

export const MenuItem = ({ item, activeMenu }: any) => {
  const menu = useRef(null as any);
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(item.key === activeMenu)
  }, [activeMenu])


  const handleMouseOver = (e: any) => {
    menu.current.show(e);
    setActive(true);
  };

  const handleMouseOut = (e: any) => {
    menu.current.hide(e);
    if (item.key !== activeMenu) setActive(false);
  };

  return (
    <div
      className={`flex justify-content-center mr-3 menu-item ${
        active && '--active'
      }`}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <Menu className="menu-options" model={item.items} popup ref={menu} />
      <a
        href=""
        className="cursor-pointer no-underline menu-item-button font-semibold py-2"
        onClick={(e: any) => e.preventDefault()}
      >
        {item.label}
        <span className="badge"></span>
        <i
          className="pi pi-chevron-down mx-2"
          style={{ fontSize: '.8rem' }}
        ></i>
      </a>
    </div>
  );
};
