import { useRef, useState } from "react";
import { Form, Row, Col, Typography, Input, Alert, AlertProps, notification, Grid } from "antd"
import { useMutation } from '@tanstack/react-query';
import { buildEmailRule, buildLengthRule, buildRequiredRule } from "../../../utils/ruleBuilders";
import { PREQUALIFY_STEP_SECTIONS as STEP_SECTIONS } from "../../../utils/consts";
import { formStyles } from "../PreQualify";
import { NewButton } from "../../../components/common/NewButton";
import preQualifiesApi from "../../../api/preQualifies";
import useRecaptcha from "../../../hooks/useReCaptcha";
import './EmailValidation.css'


const { Item } = Form
const { Title, Text } = Typography
const { useBreakpoint } = Grid

notification.config({
  top: 80, // Ajusta el valor según tu necesidad
});

const MESSAGES: any = {
  over_limit: <span>
    Alcanzaste el limite de simulaciones permitidas por mes calendario. Por favor escribinos a
    <a className="bancor-color" style={{ fontWeight: '600' }} href="mailto:creditoshipotecarios@bancor.com.ar"> creditoshipotecarios@bancor.com.ar</a>
  </span>,
  ERROR_GENERATING_CODE: 'El código no pudo ser enviado. Intentá nuevamente.',
  ERROR_VALIDATING_CODE: 'El código no pudo ser validado. Intentá nuevamente.',
  invalid_code: 'El código ingresado no es válido. Intentá nuevamente.'
}

const ALERT_MESSAGES = {
  [STEP_SECTIONS.STEP_1]: "Recibirás un código en tu correo. Puede tardar unos minutos, así que revisa también tu carpeta de spam o correo no deseado.",
  [STEP_SECTIONS.STEP_2]: "Ingresa el código recibido en tu casilla de correo y verifícalo para continuar."
}

export const EmailValidation = (props: any) => {

  const { form, formId, currentStep, setCurrentStep } = props

  const [showAlert, setShowAlert] = useState({ show: false, message: '' })
  const { renderRecaptcha, captchaValue } = useRecaptcha();
  const previousEmailRef = useRef<string | null>(null)
  const requesterEmail = Form.useWatch('requesterEmail', form)
  const verificationCode = Form.useWatch('verificationCode', form)
  const { xs, sm, md } = useBreakpoint()

  const { mutate: sendCode, isLoading: isSending } = useMutation({
    mutationFn: async () => preQualifiesApi.sendVerificationCode({ requesterEmail, formId, recaptchaResponse: captchaValue }).then(data => data),
    onSuccess: ({ success }) => {
      if (!success) {
        setShowAlert({ show: true, message: MESSAGES.ERROR_GENERATING_CODE })
      }
      else {
        setShowAlert({ show: false, message: '' })
        notification.success({ message: 'El código fue enviado correctamente. Revisa tu correo.', duration: 3, className: 'custom-notification' })
        setCurrentStep(STEP_SECTIONS.STEP_2)
      }
      return
    },
    onError: (error: any) => {
      console.log(error)
      setShowAlert({ show: true, message: MESSAGES[error.response.data.error] })
    }
  })

  const { mutate: validateCode, isLoading: isValidating } = useMutation({
    mutationFn: async () => preQualifiesApi.validateCode({ code: verificationCode, requesterEmail: form.getFieldValue('requesterEmail'), formId }).then(data => data),
    onSuccess: ({ success, error }) => {
      if (!success) {
        if (!error) setShowAlert({ show: true, message: MESSAGES.ERROR_VALIDATING_CODE })
        else setShowAlert({ show: true, message: MESSAGES[error] })
      }
      else {
        setShowAlert({ show: false, message: '' })
        notification.success({ message: 'El código fue validado correctamente.', duration: 2, className: 'custom-notification' })
        setCurrentStep(STEP_SECTIONS.STEP_3)
      }
      return
    },
    onError: (error: any) => {
      console.log(error)
      setShowAlert({ show: true, message: MESSAGES[error.response.data.error] })
    }
  })


  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (regex.test(email)) return true
    return false
  };
  
  const alertConfig: AlertProps = {
    style: { marginBottom: '10px' },
    message: showAlert.message,
    showIcon: true,
    type: 'warning'
  }

  return (
    <>
      {currentStep === STEP_SECTIONS.STEP_1 &&
        <Row gutter={[16, 0]} style={{ width: '100%', margin: '20px auto 0', alignItems: 'center' }}>
          <Col span={24}>
            <Title style={{ ...formStyles.formTitle, marginTop: '10px' }} className="default-font bancor-color" level={5}>
              Obtené tu código de verificación
            </Title>
          </Col>
          <Col xs={24} md={16}>
            <Item
              label={
                <Text className="default-font">
                  Email del solicitante
                </Text>
              }
              name="requesterEmail"
              rules={[buildRequiredRule('Email'), buildEmailRule()]}>
              <Input
                placeholder="Ingresá tu email"
                onChange={() => setShowAlert({ show: false, message: '' })}
              />
            </Item>
          </Col>
          <Col xs={24} md={8} style={{ marginTop: '4px' }}>
            <NewButton
              label="Solicitar Código"
              size="small"
              type="primary"
              loading={isSending}
              disabled={!requesterEmail || !validateEmail(requesterEmail) || !captchaValue || isSending}
              styles={{ fontSize: '16px', padding: '8px 15px' }}
              onClickHandler={() => {
                if (requesterEmail !== previousEmailRef.current) {
                  previousEmailRef.current = requesterEmail;
                  sendCode()
                }
              }}
            />
          </Col>
          { renderRecaptcha() }
        </Row>
      }
      {currentStep === STEP_SECTIONS.STEP_2 &&
        <Row gutter={[16, 0]} style={{ width: '100%', margin: '20px auto 0', alignItems: 'center' }}>
          <Col span={24}>
            <Title style={{ ...formStyles.formTitle, marginTop: '10px' }} className="default-font bancor-color" level={5}>
              Ingresá el código recibido y presioná el botón “Verificar Código”.
            </Title>
          </Col>
          <Col xs={24} md={16} style={{ display: 'flex', columnGap: '30px', alignItems: 'center', flexDirection: ((xs || sm) && !md) ? 'column' : 'row' }}>
              <Item
                label={
                  <Text className="default-font">
                    Código de Verificación
                  </Text>
                }
                name={`verificationCode`}
                rules={[buildRequiredRule('Código'), buildLengthRule(6)]}>
                <Input.OTP length={6} size="large" />
              </Item>
              <NewButton
                label="Verificar Código"
                size="small"
                type="primary"
                loading={isValidating}
                disabled={!verificationCode || isValidating || verificationCode.length !== 6}
                styles={{ fontSize: '16px', padding: '8px 15px', marginTop: '6px' }}
                onClickHandler={() => validateCode()}
              />
          </Col>
        </Row>   
      }
      <Row gutter={[16, 0]} style={{ width: '100%', margin: '20px auto 0', alignItems: 'center' }}>
        {!showAlert.show &&
          <Col span={24}>
            <Alert { ...alertConfig } type="info" message={ALERT_MESSAGES[currentStep]} />
          </Col>
        }
        {showAlert.show && !isSending &&
          <Col span={24}>
            <Alert { ...alertConfig } />
          </Col>
        }     
      </Row>     
        
    </>
  )
}