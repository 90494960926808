import { useEffect, useState } from 'react';
import { Carousel } from '../components/home/Carousel';
import { ExtraButtons } from '../components/home/ExtraButtons';
import { Filters } from '../components/home/Filters';
import { Properties } from '../components/home/Properties';
import { useQuery } from '@tanstack/react-query';
import api from '../api/properties';

export const Home = () => {
  
  const [properties, setProperties] = useState([])
  const [total, setTotal] = useState(0)
  const { isLoading: loadingProperties, data } = useQuery({
    queryKey: ['featuredProperties'],
    queryFn: api.getFeaturedProperties,
  });

  useEffect(() => {
    if (data) {
      const { data: properties, total } = data
      setTotal(total)
      setProperties(properties)
    }
  }, [data])

  return (
    <div
      className="w-full flex flex-column align-items-center"
      style={{ position: 'relative', backgroundColor: '#F8F8F8' }}>
      <div className="w-full flex flex-column align-items-center lg:mt-8 lg:px-7 px-4">
        <Carousel />
        <ExtraButtons />
        <div className="inline-flex align-items-center mt-4">
          <h3
            id="encontraTuHogar"
            className="text-3xl md:text-6xl bancor-color font-bold"
          >
            Encontrá tu hogar
          </h3>
        </div>
        <Filters isLoading={loadingProperties} />
        <Properties data={properties} initial={true} loading={loadingProperties} total={total} />
      </div>
    </div>
  );
};
