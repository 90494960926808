import { List, Modal, Typography, Grid, Row, Col } from "antd"
import { FilePdfOutlined } from "@ant-design/icons";
const url = window.url ? window.url : process.env.REACT_APP_API_URL as string;

const { Link } = Typography
const { useBreakpoint } = Grid

export const FORM_LINKS = {
  DDJJ_SALUD: { url: `${url}/assets/Formulario_DDJJ_de_Salud.pdf`, label: 'Formulario DDJJ de Salud' },
  EXAMEN_MEDICO_GENERAL: { url: `${url}/assets/Formulario_Examen_Medico_General.pdf`, label: 'Formulario Examen Medico General' },
  EXAMEN_MEDICO_CARDIOVASCULAR: { url: `${url}/assets/Formulario_Examen_Médico_Cardiovascular.pdf`, label: 'Formulario Examen Médico Cardiovascular' }
}

const INITIAL_MODAL_BY_TYPES: any = {
  default: {
    title: "Información importante",
    body:
    <Row style={{ width: '100%' }} justify="center">
      <Col span={20} className="default-font">
        <br/>
        Para procesar tu solicitud de préstamo, te detallamos los diferentes formularios que deberás <b>descargar, completar y adjuntar</b>:
        <br/>
        <br/>
        <b>Declaración Jurada de Salud</b> de quienes sumen ingresos.
        <br/>
        <b>Examen Médico General</b> y <b>Examen Médico Cardiovascular</b> sólo en caso de que seas el único titular del préstamo (no sumas ingresos), tengas 50 años o más y solicites un préstamo de 55000 UVAs o más. Ambos formularios deben ser suscriptos por los respectivos médicos con sus datos y firmas.
        <List
          style={{ marginTop: '20px' }}
          dataSource={[FORM_LINKS.DDJJ_SALUD, FORM_LINKS.EXAMEN_MEDICO_GENERAL, FORM_LINKS.EXAMEN_MEDICO_CARDIOVASCULAR]}
          size="small"
          renderItem={item =>
            <Link className="bancor-color" style={{ display: 'block', color: 'black' }} href={item.url} target="_blank">
              <FilePdfOutlined style={{ color: '#ff4d4f' }} /> {item.label}
            </Link>
          }
        />
      </Col>
    </Row>
  }
}

export const InitialModal = (props: any) => {
  const { open, onCancel, type } = props
  const currentModal = INITIAL_MODAL_BY_TYPES[type] && type ? INITIAL_MODAL_BY_TYPES[type] : INITIAL_MODAL_BY_TYPES.default
  const screens = useBreakpoint()

  const getModalWidth = () => {
    if (screens.xl || screens.xxl || screens.lg) return '75%';
    else if (screens.md) return '90%';
    else return '100%';
  }

  return (
    <Modal
      width={getModalWidth()}
      open={open}
      onCancel={onCancel}
      okText="Cerrar"
      onOk={onCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
      title={currentModal.title}
    >
      {currentModal.body}
    </Modal>
  )
}