import axios from 'axios';
const url = window.url ? window.url : process.env.REACT_APP_API_URL as string;

const config = {
  headers: {
    accept: 'application/json, text/plain, */*',
    'accept-language': 'en-US,en;q=0.9',
    'content-type': 'application/json;charset=UTF-8',
  },
  mode: 'cors',
  credentials: 'include',
};

export default {
  getProperties: async (body: any) => {
    const time = new Date().getTime()
    const { data } = await axios.post(
      `${url}/property/search?cachebuster=${time}`,
      body,
      config
    );
    return data;
  },
  getFeaturedProperties: async () => {
    const time = new Date().getTime()
    const { data } = await axios.get(
      `${url}/property/searchFeatured?cachebuster=${time}`,
      config
    );
    return data;
  },
  getProperty: async ({ queryKey }: any) => {
    const time = new Date().getTime()
    const [_, id, previewKey] = queryKey;
    const previewKeyQuery = previewKey ? `&previewKey=${previewKey}` : ''
    const { data } = await axios.get(`${url}/property/show/${id}?cachebuster=${time}${previewKeyQuery}`, config);
    return data;
  },
  getInitialPoint: async () => {
    const time = new Date().getTime()
    const { data } = await axios.get(
      `${url}/search/initialPoint?cachebuster=${time}`,
      config
    );
    return data;
  },
  validateProperty: async (rentas: string, matricula: string) => {
    const time = new Date().getTime()
    const { data } = await axios.get(
      `${url}/propertyRequest/validateProperty?cachebuster=${time}`,
      { ...config, params: { rentas, matricula } }
    );
    return data;
  },
  getFilters: async () => {
    const time = new Date().getTime()
    const { data } = await axios.get(
      `${url}/search/searchFilters?cachebuster=${time}`,
      config
    );
    return data;
  }
};