import { Divider } from 'primereact/divider';
import './Footer.scss';
const url = window.url ? window.url : process.env.REACT_APP_API_URL as string;

export const Footer = () => {
  return (
    <div className="footer w-screen flex flex-column">
      <div className="flex flex-column md:flex-row align-items-center justify-content-center py-3">
        <div className="flex align-items-center justify-content-center my-3 md:my-0 w-4">
          <img
            className="footer-logo mx-2"
            src={url + '/assets/appto-white.svg'}
            alt=""
          />
          <img
            className="footer-logo mx-2"
            src={url + '/assets/bancor-white.svg'}
            alt=""
          />
        </div>
        <div className="flex flex-column md:flex-row align-items-center justify-content-center">
          <a
            href={url + '/tos'}
            className="text-white no-underline mx-2 my-2 md:my-0"
          >
            Términos del servicio
          </a>
          <a
            href="https://www.argentina.gob.ar/aaip/datospersonales"
            className="text-white no-underline mx-2 my-2 md:my-0"
          >
            Protección de datos personales
          </a>
        </div>
        <div className="flex align-items-center justify-content-center my-4 md:my-0 w-4">
          <a
            href="https://www.facebook.com/BancoDeCordoba/"
            className="text-white no-underline mx-2"
          >
            <i
              className="pi pi-facebook mx-2"
              style={{ fontSize: '1rem', color: '#ffffff' }}
            ></i>
          </a>
          <a
            href="https://twitter.com/BancodeCordoba"
            className="text-white no-underline mx-2"
          >
            <i
              className="pi pi-twitter mx-2"
              style={{ fontSize: '1rem', color: '#ffffff' }}
            ></i>
          </a>
          <a
            href="https://www.instagram.com/bancodecordobaok/"
            className="text-white no-underline mx-2"
          >
            <i
              className="pi pi-instagram mx-2"
              style={{ fontSize: '1rem', color: '#ffffff' }}
            ></i>
          </a>
          <a
            href="https://www.youtube.com/user/YouBancor"
            className="text-white no-underline mx-2"
          >
            <i
              className="pi pi-youtube mx-2"
              style={{ fontSize: '1rem', color: '#ffffff' }}
            ></i>
          </a>
        </div>
      </div>
      <Divider className="custom-divider m-0" />
      <p className="text-white text-center text-xs md:text-md">
        2023 APPTO - Todos los derechos reservados
      </p>
    </div>
  );
};
