import { Form, Col, Typography, Select, Input } from "antd"
import { PROVINCE_OPTIONS } from "../../../utils/consts";
import { buildRequiredRule, buildMaxRule, buildEmailRule } from "../../../utils/ruleBuilders";

const { Item } = Form
const { Text } = Typography

const styles = {
  helpText: {
    color: '#464646',
    fontSize: '12px'
  },
  button: {
    fontSize: '20px'
  },
  formTitle: {
    fontSize: '16px',
    width: '100%',
    marginBottom: '20px',
    fontWeight: 700
  }
}

const handleCopyPaste = (e: any) => e.preventDefault()

export const AdressPhoneEmailForm = (props: any) => {
  const { form, type } = props

  return (
    <>
      <Col xs={24} md={12}>
        <Item
          name={`${type}Address`}
          label="Calle"
          rules={[buildRequiredRule('Calle'), buildMaxRule(200)]}>
          <Input placeholder="Calle" />
        </Item>
      </Col>
      <Col xs={24} md={12}>
        <Item
          name={`${type}AddressNumber`}
          label="Número"
          rules={[buildRequiredRule('Número'), buildMaxRule(200)]}>
          <Input placeholder="Número" />
        </Item>
      </Col>
      <Col xs={24} md={12}>
        <Item
          name={`${type}Province`}
          label="Provincia"
          initialValue={3}
          rules={[buildRequiredRule('Provincia')]}>
          <Select
            showSearch
            filterOption={(input, option) => (option?.label || '').toLowerCase().includes(input.toLowerCase())}
            options={PROVINCE_OPTIONS || []}
            disabled={!PROVINCE_OPTIONS.length}
            placeholder="Provincia" />
        </Item>
      </Col>
      <Col xs={24} md={12}>
        <Item
          name={`${type}PhoneNumber`}
          label={
            <Text className="default-font">
              Teléfono <Text style={styles.helpText}>(Ingrese sólo números)</Text>
            </Text>}
          rules={[buildRequiredRule('Teléfono'), { max: 25 }]}>
          <Input
            placeholder="Teléfono"
            onChange={e => form.setFieldValue(`${type}PhoneNumber`, e.target.value.replace(/[^0-9]/g, ''))}
          />
        </Item>
      </Col>
      <Col xs={24} md={12}>
        <Item
          name={`${type}Email`}
          label="Correo Electrónico"
          rules={[
            buildRequiredRule('Email'),
            buildMaxRule(200),
            buildEmailRule()
          ]}>
          <Input placeholder="Correo Electrónico" onCopy={handleCopyPaste} onPaste={handleCopyPaste} />
        </Item>
      </Col>
      <Col xs={24} md={12}>
        <Item
          name={`${type}EmailRepeat`}
          label="Repetir Correo Electrónico"
          dependencies={[`${type}Email`]}
          rules={[
            buildRequiredRule('Repetir Correo Electrónico'),
            buildEmailRule(),
            buildMaxRule(200),
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue(`${type}Email`) === value) return Promise.resolve();
                return Promise.reject('Los correos electrónicos no coinciden');
              },
            })
          ]}>
          <Input placeholder="Repetir Correo Electrónico" onCopy={handleCopyPaste} onPaste={handleCopyPaste} />
        </Item>
      </Col>
    </>
  )
}