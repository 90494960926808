import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import { CalculatorForm } from './CalculatorForm';
import { ReaderForm } from './ReaderForm';
import './ExtraButtons.scss';
import { DirectAccess } from './DirectAccess';


export const ExtraButtons = () => {
  const [showCalculator, setShowCalculator] = useState(false);
  const [showReader, setShowReader] = useState(false);

  const openCalculator = () => {
    setShowCalculator(true);
  };

  const openReader = () => {
    setShowReader(true);
  };

  const Calculator = () => {
    return (
      <Dialog header="Calculadora de UVAs" visible={showCalculator} className="custom-dialog w-full md:w-6" onHide={() => setShowCalculator(false)}>
        <CalculatorForm />
      </Dialog>
    );
  };

  const Reader = () => {
    return (
      <Dialog header="Buscá el estado de tu solicitud" visible={showReader} className="custom-dialog w-full md:w-6" onHide={() => setShowReader(false)}>
        <ReaderForm />
      </Dialog>
    );
  };

  const directAccess = [
    {
      callback: () => window.open('https://online.turnero.bancor.com.ar/'),
      icon: 'solicitar-turno.svg',
      label: 'Solicitá turno',
    },
    {
      callback: () => window.open(`https://aplicaciones.bancor.com.ar/563_Simulador_Web_Mvc`),
      icon: 'solicitar-simulacion.svg',
      label: 'Simulá tu préstamo',
    },
    {
      callback: openReader,
      icon: 'consultar-solicitud-2.svg',
      label: 'Consulta tu solicitud',
    },
    {
      callback: openCalculator,
      icon: 'cotizar-uvas.svg',
      label: 'Cotización de UVAs',
    },
  ];

  return (
    <div className="extra-buttons flex justify-content-center align-items-center gap-3 flex-wrap">
      {directAccess.map((access: any, index: any) => (
        <DirectAccess key={index} label={access.label} callback={access.callback} icon={access.icon} />
      ))}
      <Calculator />
      <Reader />
    </div>
  );
};
