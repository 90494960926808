import { GoogleMap, InfoWindow, useJsApiLoader, MarkerClusterer, MarkerF } from '@react-google-maps/api';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ZoomButtons } from '../common/ZoomButtons'
import { Loading } from '../common/Loading';
import { useNavigate } from 'react-router-dom';
import { FiltersContext } from '../../context';

const url = window.url ? window.url :  process.env.REACT_APP_API_URL as string;

export const Map = ({ data, center, zoom }: any) => {
  const navigate = useNavigate()
  const [map, setMap] = useState<any>(null);
  const [selectedMarker, setSelectedMarker] = useState<any>(null)
  const filters = useContext(FiltersContext);

  const gmapApiKey = window.googleMapApiKey ? window.googleMapApiKey : process.env.REACT_APP_GOOGLE_MAP_API_KEY as string;

  const containerStyle = {
    width: '100%',
    height: '400px',
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: gmapApiKey,
  });

  const onLoad = useCallback((map: any) => setMap(map), []);
  const onUnmount = useCallback((map: any) => setMap(null), []);

  const options = {
    disableDefaultUI: true,
    styles: [
      {
        featureType: 'water',
        stylers: [
          {
            color: '#7d7d7d',
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'landscape',
        stylers: [
          {
            color: '#fbfbfb',
          },
        ],
      },
      {
        featureType: 'road',
        stylers: [
          {
            saturation: -100,
          },
          {
            lightness: 45,
          },
        ],
      },
      {
        featureType: 'road.highway',
        stylers: [
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#eb5b38',
          },
        ],
      },
      {
        featureType: 'transit',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
  };

  const [customZoom, setCustomZoom] = useState(zoom);

  const handleZoomIn = () => {
    if (customZoom < 20) {
      setCustomZoom(customZoom + 1);
    }
  }
  
  const handleZoomOut = () => {
    if (customZoom > 0) {
      setCustomZoom(customZoom - 1);
    }
  }

  useEffect(()=>{
    if(data && isLoaded && map) {
      setCustomZoom(zoom)
      if(data.length > 0){
        let bounds = new window.google.maps.LatLngBounds();
        var len = filters.initial ? data.length : 3;
        for (var i = 0; i < len; i++) {
         if(data[i] && data[i].location){
          bounds.extend({lat:data[i].location.lat, lng:data[i].location.lon});
         }
        }
  
      }
      if(map){
        map.setCenter(center); 
      }

    }
  }, [data, isLoaded, map]);

  return isLoaded ? (
    <div className="w-full relative">
      <GoogleMap
        options={options}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={customZoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        clickableIcons={false}
      >
        <MarkerClusterer options={{styles:[{ url: `${url}/assets/marker_cluster.svg`, fontFamily: "Open Sans", height: 42, width: 42, textColor: '#FFFFFF', textSize: 14 }]}}>
          {(clusterer) =>
              data?.map((item: any) => {
                const position = {
                  lat: item.location.lat,
                  lng: item.location.lon,
                };
                item.position = position

                return (
                  <MarkerF
                    clusterer={clusterer}
                    key={item.location.id}
                    icon={{
                      url: `${url}/assets/marker.svg`,
                      scaledSize: { width: 30, height: 30 } as any,
                      anchor: { x: 15, y: 15 } as any
                    }}
                    position={position}
                    onClick={() => {
                      setSelectedMarker(item);
                    }}
                  />
                );
              })
           }
        </MarkerClusterer>

        {selectedMarker && (
          <InfoWindow
            position={selectedMarker.position}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div className="cursor-pointer" onClick={() => navigate(`properties/${selectedMarker.id}`)}>
              <div className="text-sm font-bold text-white m-2 pr-4" style={{ minWidth: '250px' }}>
                {selectedMarker.priceLow} a {selectedMarker.priceHigh} UVAs
              </div>
              <div
                className="overflow-hidden m-0 w-full"
                style={{ 
                  height: '150px',
                  width: '250px',
                  backgroundColor: '#999999',
                  backgroundImage: `url(${url}/property/photo/${selectedMarker.cover})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }}
              >
              </div>
              <div className="bg-white p-2">
                <h2 className="text-sm font-semibold text-orange-400 m-0 mb-1">{selectedMarker.city}</h2>
                <p className="text-xs text-900 m-0">
                  <i className="pi pi-map-marker mr-2 capitalize" style={{ fontSize: '1rem' }} />
                  {selectedMarker.coarseAddress.toLowerCase()}
                </p>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
      <ZoomButtons
      className="absolute"
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
      />
    </div>
  ) : (
    <Loading />
  );
};
