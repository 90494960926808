const url = window.url ? window.url : (process.env.REACT_APP_API_URL as string);

export const DirectAccess = (props: any) => {
  const onClick = (e: any) => {
    e.preventDefault();
    props.callback();
  };
  return (
    <a
      style={{ width: '200px', minWidth: '200px', backgroundColor: 'white' }}
      href={props.url}
      onClick={onClick}
      className="flex flex-column justify-content-end align-items-center no-underline cursor-pointer bancor-color gap-3 direct-access"
    >
      <img
        className="h-medium"
        src={`${url}/assets/${props.icon}`}
        alt={props.label}
      />
      <b className="text-sm">{props.label}</b>
    </a>
  );
};
