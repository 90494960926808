import { useContext, useEffect, useState, useCallback } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FiltersContext } from '../context';
import { SearchFilters } from '../components/home/SearchFilters';
import { Button } from '../components/common/Button';
import { useNavigate } from 'react-router-dom';
import { MAP_MAX_SEARCH, DEFAULT_RADIUS, GOOGLE_MAP_OPTIONS, INITIAL_ZOOM, MIN_ZOOM } from '../utils/consts';
import { Loading } from '../components/common/Loading';
import { GoogleMap, useJsApiLoader, MarkerClusterer, MarkerF, InfoWindow } from '@react-google-maps/api';
import { ZoomButtons } from '../components/common/ZoomButtons';
import neighborhoods from '../api/neighborhoods';
import api from '../api/properties';
import '../components/home/Map.scss'

const gmapApiKey = window.googleMapApiKey ? window.googleMapApiKey : process.env.REACT_APP_GOOGLE_MAP_API_KEY as string;
const url = window.url ? window.url :  process.env.REACT_APP_API_URL as string;

export const NewPropertySearchMap = () => {
  
  const contextFilters = useContext(FiltersContext);
  contextFilters.max = MAP_MAX_SEARCH
  contextFilters.random = true
  
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: gmapApiKey,
    libraries: ["geometry"]
  });
  const [customZoom, setCustomZoom] = useState(INITIAL_ZOOM);  
  const [loading, setLoading] = useState(false)
  const [map, setMap] = useState<any>(null);
  const [selectedMarker, setSelectedMarker] = useState<any>(null)
  const [properties, setProperties] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [newQueryLocation, setNewQueryLocation] = useState()
  const navigate = useNavigate()

  const onLoad = useCallback((map: any) => setMap(map), []);
  const onUnmount = useCallback((map: any) => setMap(null), []);
 
  const { isLoading: loadingProperties, mutate } = useMutation({
    mutationKey: ['properties'],
    mutationFn: api.getProperties,
  });

  const { isLoading: loadingCenter, data: initialPoint } = useQuery({
    queryKey: ['initialPoint'],
    queryFn: api.getInitialPoint,
  });
  
  const onSearch = () => {
    mutate(
      { ...contextFilters, radius: DEFAULT_RADIUS, random: contextFilters.query !== '' ? false : true },
      {
        onSuccess: (data) => {
          setProperties(data.data);
          setTotal(data.total);
        },
      }
    );
  };

  useEffect(() => onSearch(), []);

  useEffect(() => {
    if (properties && initialPoint && isLoaded && map) {
      map.setCenter({
        lat: contextFilters.latitude ?? initialPoint.latitude,
        lng: contextFilters.longitude ?? initialPoint.longitude
      });
      if (properties.length > 0) {
        let bounds = new window.google.maps.LatLngBounds();
        var len = contextFilters.initial ? properties.length : 3;
        for (var i = 0; i < len; i++) {
         if (properties[i] && properties[i].location) {
          bounds.extend({ lat: properties[i].location.lat, lng: properties[i].location.lon });
         }
        }
        setProperties(properties)
      }
    }
  }, [isLoaded, map]);

  useEffect(() => {
    if (map) {
      reCalculateCenter()
      getProperties()
    }
  }, [customZoom])

  const getRadius = (bounds: any) => {
    if (!bounds) return null;
    const radius = google.maps.geometry.spherical.computeDistanceBetween(
      bounds.getCenter(),
      new google.maps.LatLng(bounds.getNorthEast().lat(), bounds.getCenter().lng())
    );
    return `${Number(Math.ceil((radius / 1000)).toFixed(0))}km`;
  }

  const reCalculateCenter = () => {
    const newCenter = map.getCenter();
    getNewCenterName(`${newCenter.lat()},${newCenter.lng()}`)
    if (newCenter) {
      contextFilters.latitude = newCenter.lat()
      contextFilters.longitude = newCenter.lng()
      contextFilters.radius = getRadius(map.getBounds()) ?? '50km'
    }
  }

  const onDragEnd = () => {
    reCalculateCenter()
    getProperties()
  }

  const handleZoomIn = () => {
    if (customZoom < 20) {
      setCustomZoom(prev => prev + 1);
    }
  }
  
  const handleZoomOut = () => {
    if (customZoom > MIN_ZOOM) {
      setCustomZoom(prev => prev - 1);
    }
  }
  
  const getNewCenterName = async (latLng: string) => {
    const newCenter = await neighborhoods.getNewCenterName(latLng)
    contextFilters.query = newCenter
    setNewQueryLocation(newCenter)
  }

  const getProperties = async () => {
    setLoading(true)
    try {
      const { data } = await api.getProperties(contextFilters)
      setProperties(data)
    } catch (error) {
    } finally { setLoading(false) }
  }

  return (
    <div
      className="w-full flex flex-column align-items-center"
      style={{ position: 'relative', backgroundColor: '#F8F8F8' }}>
        <div className="w-full flex flex-column align-items-center lg:mt-8 lg:px-7 px-4">
          <div className="w-full flex flex-column align-items-center mt-6">
            <SearchFilters newQueryLocation={newQueryLocation} onSearch={onSearch}/>
            <div className="w-full py-5 px-3">
              <div className="flex justify-content-end align-items-center mb-4">
                <Button
                  label="Ver listado"
                  icon="pi-map"
                  className="cursor-pointer py-0.75 px-3"
                  onClick={() => navigate('/search')}
                />
              </div>
              {(!loadingCenter && isLoaded && !loadingProperties && properties)
                ? <div className="w-full relative">
                    {loading &&
                      <Loading classes="loading-map" />
                    }
                    <GoogleMap
                      options={GOOGLE_MAP_OPTIONS}
                      mapContainerStyle={{ width: '100%', height: '500px' }}
                      zoom={customZoom}
                      onLoad={onLoad}
                      onDragEnd={onDragEnd}
                      onUnmount={onUnmount}
                      clickableIcons={false}
                    >
                      <MarkerClusterer
                        onClick={(e) => {
                          const clusterCenter = e.getCenter()
                          if (clusterCenter) {
                            contextFilters.latitude = clusterCenter.lat()
                            contextFilters.longitude = clusterCenter.lng()
                          }

                          if (map.getZoom() >= 20 && e.getSize() > 1) navigate('/search')
                        }}
                        options={{styles:[{ url: `${url}/assets/marker_cluster.svg`, fontFamily: "Open Sans", height: 42, width: 42, textColor: '#FFFFFF', textSize: 14 }]}}>
                        {(clusterer) =>
                            properties?.map((item: any) => {
                              const position = { lat: item.location.lat, lng: item.location.lon };
                              item.position = position
                              return (
                                <MarkerF
                                  clusterer={clusterer}
                                  key={item.location.id}
                                  icon={{
                                    url: `${url}/assets/marker.svg`,
                                    scaledSize: { width: 30, height: 30 } as any,
                                    anchor: { x: 15, y: 15 } as any
                                  }}
                                  position={position}
                                  onClick={() => setSelectedMarker(item)}
                                />
                              );
                            })
                          }
                      </MarkerClusterer>
                      {selectedMarker && (
                        <InfoWindow
                          position={selectedMarker.position}
                          onCloseClick={() => setSelectedMarker(null)}
                        >
                          <div className="cursor-pointer" onClick={() => navigate(`/properties/${selectedMarker.id}`)}>
                            <div className="text-sm font-bold text-white m-2 pr-4" style={{ minWidth: '250px' }}>
                              {selectedMarker.priceLow} a {selectedMarker.priceHigh} UVAs
                            </div>
                            <div
                              className="overflow-hidden m-0 w-full"
                              style={{
                                height: '150px',
                                width: '250px',
                                backgroundColor: '#999999',
                                backgroundImage: `url(${url}/property/photo/${selectedMarker.cover})`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                              }}
                            >
                            </div>
                            <div className="bg-white p-2">
                              <h2 className="text-sm font-semibold text-orange-400 m-0 mb-1">{selectedMarker.city}</h2>
                              <p className="text-xs text-900 m-0">
                                <i className="pi pi-map-marker mr-2 capitalize" style={{ fontSize: '1rem' }} />
                                {selectedMarker.coarseAddress.toLowerCase()}
                              </p>
                            </div>
                          </div>
                        </InfoWindow>
                      )}
                    </GoogleMap>
                    <ZoomButtons
                      className="absolute"
                      disabled={ customZoom === MIN_ZOOM }
                      onZoomIn={handleZoomIn}
                      onZoomOut={handleZoomOut}
                    />
                  </div>
                : <Loading />
              }
            </div>
          </div>
        </div>
      </div>
  );
};
