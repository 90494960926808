import { useState } from "react";
import { Form, Col, Typography, Row,  Upload, Button, Space, notification, Tooltip } from "antd"
import { QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons";
import './UploadDocumentationForm.scss'
import { ACCEPT_FILE } from "../../../utils/consts";

const { Item } = Form
const { Text, Title, Paragraph } = Typography

const styles = {
  helpText: {
    color: '#464646',
    fontSize: '12px'
  },
  button: {
    fontSize: '20px'
  },
  formTitle: {
    fontSize: '16px',
    width: '100%',
    marginBottom: '20px',
    fontWeight: 700
  },
  required: {
    fontFamily: 'SimSun, sans-serif',
    lineHeight: 1,
    fontSize: '14px',
    color: '#ff4d4f',
    marginInlineEnd: '4px',
    display: 'inline-block'
  }
}


const FILES_BY_TYPE = [
  {
    name: 'CBU',
    label: 'Constancia CBU de cuenta pesos del titular del inmueble',
    active: true,
    order: 1,
    accept: ACCEPT_FILE.PDF
  },
  {
    name: 'Escritura',
    label: 'Escritura (presentar boletos de compra venta o cesiones posteriores si corresponde)',
    active: true,
    order: 2,
    accept: ACCEPT_FILE.PDF,
    description:
      <Paragraph style={{ color: 'white' }}>
        Para inmuebles APPTO primera escritura presentar protocolización administrativa de planos/reglamento de copropiedad o escritura antecedente a nombre del titular anterior. <br/> En caso de declaratoria de herederos enviar junto a la escritura el tracto abreviado que habilite la venta. 
      </Paragraph>
  },
  {
    name: 'Informe de Matricula',
    label: 'Informe de matrícula del mes en curso',
    active: true,
    order: 3,
    accept: ACCEPT_FILE.PDF
  },
  {
    name: 'Cedulón de Rentas',
    label: 'Cedulón de Rentas del año en curso',
    active: true,
    order: 4,
    accept: ACCEPT_FILE.PDF
  },
  {
    name: 'Plano de subdivisión',
    label: 'Si es dúplex o housing debe presentar de manera obligatoria el plano de subdivisión aprobado por la Municipalidad',
    active: true,
    order: 5,
    accept: ACCEPT_FILE.PDF
  }
]

export const UploadDocumentationForm = (props: any) => {
  const [fileUploads, setFileUploads] = useState<any>({})

  const propertyType = Form.useWatch('propertyType')

  const checkFileSize = (file: File, maxSize: number) => {
    const sizeInMB = file.size / 1024 / 1024;
    return sizeInMB <= maxSize;
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) return e;
    return e && e.fileList;
  };

  const customRequest = ({ file, onSuccess, onError } :any) => {
    // Custom request logic if needed
    setTimeout(() => {
      onSuccess();
    }, 0);
  };

  return (
    <Row gutter={[16, 0]} style={{ width: '100%', margin: '0 auto' }}>
      <Title style={styles.formTitle} className="default-font bancor-color" level={3}>
        Documentación Adjunta
        <br />
        <small style={{ fontWeight: 'normal' }}>
          Las fotos deberán ser enviadas formato JPG. La documentación a continuación debe ser enviada en formato PDF, un sólo archivo por documento solicitado y no deben superar los 50 MB
        </small>
      </Title>
      {FILES_BY_TYPE.map((filetype, index) => {
        let required = true
        if (propertyType !== 'DUPLEX' && filetype.name === "Plano de subdivisión") required = false

        return (
          <Col span={24} key={filetype.name}>
            <Item
              name={["documentation", filetype.name]}
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[{ required, message: 'Seleccione al menos 1 archivo.' }]}
            >
              <Upload
                className="certification-upload"
                listType="picture"
                onChange={e => {
                  const { fileList } = e
                  setFileUploads((prev: any) => ({ ...prev, [`${index}${filetype.name}`]: !!fileList.length }))
                }}
                beforeUpload={(file) => {
                  if (file.type !== 'application/pdf') {
                    notification.error({ duration: 2, message: 'Sólo se aceptan archivos PDF' })
                    return Upload.LIST_IGNORE;
                  }
                  return false
                }}
                accept={filetype.accept}
                multiple
              >
                <Space style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <Text>
                    {required && <Text style={styles.required}>*</Text>}
                    {filetype.label}
                    {filetype.description &&
                      <Tooltip title={<Text style={{ color: 'white' }}>{filetype.description}</Text>}>
                        <QuestionCircleOutlined style={{ marginLeft: '6px' }} className="bancor-color" />
                      </Tooltip>
                    }
                  </Text>
                  <Button icon={<UploadOutlined />}>
                    {fileUploads[`${index}${filetype.name}`] ? "Subir otro" : "Subir"}
                  </Button>
                </Space>
              </Upload>
            </Item>
          </Col> 
          )
        })
      }
      <Col span={24}>
        <Item
          name={["documentation", "Foto"]}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            { required: true, message: 'Seleccione al menos 4 imágenes.' },
            { type: 'array', min: 4, max: 15, message: 'Seleccione entre 4 y 15 imágenes.' },
          ]}
        >
          <Upload
            className="certification-upload"
            listType="picture"
            onChange={e => {
              const { fileList } = e
              setFileUploads((prev: any) => ({ ...prev, [`fotos-propiedad`]: !!fileList.length }))
            }}
            beforeUpload={(file) => {
              if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
                notification.error({ duration: 2, message: 'Sólo se aceptan archivos JPG, JPEG o PNG' })
                return Upload.LIST_IGNORE;
              }
              if (!checkFileSize(file, 50)) {
                notification.error({ duration: 2, message: 'El tamaño de la imagen debe ser menor o igual a 50MB.' })
                return Upload.LIST_IGNORE;
              }
              return false;
            }}
            customRequest={customRequest}
            accept={ACCEPT_FILE.IMAGE}
            multiple
          >
            <Space style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <Text><Text style={styles.required}>*</Text>Fotos <small>(Mínimo 4 fotos, Máximo 15 fotos)</small></Text>
              <Button icon={<UploadOutlined />}>
                {fileUploads[`fotos-propiedad`] ? "Subir otro" : "Subir"}
              </Button>
            </Space>
          </Upload>
        </Item>
      </Col>
    </Row>
  )
}