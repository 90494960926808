import { Button } from "antd"


type ButtonType = {
  label?: string,
  icon?: any,
  styles?: any,
  onClickHandler?: any,
  type?: 'link' | 'default' | 'primary' | 'dashed' | undefined
  danger?: boolean
  size?: 'large' | 'middle' | 'small',
  disabled?: boolean,
  loading?: boolean,
  htmlType?: 'submit'
}

const defaultStyles = {
  borderRadius: '30px',
  padding: '10px 30px',
  height: 'unset',
  lineHeight: 1
}

export const NewButton = (props: ButtonType) => {
  const { label, icon, styles, onClickHandler, loading, type, danger, size, disabled, htmlType } = props
  return (
    <Button
      size={size}
      icon={icon}
      loading={loading}
      type={type}
      style={{ ...defaultStyles, ...styles }}
      danger={danger}
      disabled={disabled}
      onClick={onClickHandler}
      htmlType={htmlType}
    >
      {label}
    </Button>
  )
}