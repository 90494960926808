import { useMutation } from '@tanstack/react-query';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button } from '../common/Button';
import api from '../../api';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Message } from 'primereact/message';
import { Controller, useForm } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import CuitValidator from '../../utils/CuitValidator';

const SITE_KEY =  window.recaptchaApiKey ? window.recaptchaApiKey : process.env.REACT_APP_RECAPTCHA_KEY as string;

export const SecondInbursementForm = () => {
  const [checkedTos, setCheckedTos] = useState(false);
  const [tosVisible, setTosVisible] = useState(false);
  const { control, handleSubmit, formState: { errors } } = useForm();

  const [requestResult, setRequestResult] = useState<any>(null);
  const [recaptchaResponse, setRecaptchaResponse] = useState<string>('');
  const { isLoading: loadingSecondInbursement, mutate } = useMutation({
    mutationKey: ['requestSecondInbursement'],
    mutationFn: api.postRequestSecondInbursement,
  });

  const handleRecaptcha = (response: string | null) => {
    if (response) {
      setRecaptchaResponse(response);
    }
  };

  const onSearch = (values:any) => {
    mutate(
      {
        cuit: values.cuit,
        agreeTos: checkedTos,
        recaptchaResponse,
      },
      {
        onSuccess: (data) => {
          setRequestResult(data);
        },
      }
    );
  };

  return (
    <div className="grid">
      {requestResult ? (
        <div className='col-12'>
          <Message
            style={{
                border: 'solid #005f5a',
                borderWidth: '0 0 0 6px',
                color: '#005f5a'
            }}
            className="border-success w-full justify-content-start"
            severity="success"
            text={requestResult.status}
            />         
        </div>
      ) : (
        <>
        <form onSubmit={handleSubmit(onSearch)} className="col-12">
          <div className="col-12">
            <div className="flex flex-column gap-2">
              <label>
              Ingresá tu C.U.I.T. o C.U.I.L. <small>(Obligatorio) Ingresá sólo números</small>
              </label>
              <Controller name="cuit" control={control} rules={{ required: 'El C.U.I.T. o C.U.I.L. es obligatorio', 
              validate: {
                cuit: v => {
                  return CuitValidator(v);
                }
              } }} render={({ field, fieldState }: any) => (
                <InputText placeholder="C.U.I.T. o C.U.I.L." id={field.name} {...field} className={'w-full ' + classNames({ 'p-invalid': fieldState.invalid })} />
              )} />
              {errors['cuit'] && <small className="p-error">El número ingresado no es un C.U.I.T. o C.U.I.L. válido</small>}
            </div>
          </div>
          <div className="col-12">
            <div className="flex flex-column gap-2 align-items-center">
              <ReCAPTCHA lang="es" sitekey={SITE_KEY} onChange={handleRecaptcha} />
            </div>
          </div>
          <div className='col-12'>
            <div className="flex gap-2 justify-content-center">
                <Checkbox onChange={e => setCheckedTos(e.checked ? true: false)} checked={checkedTos}>
                </Checkbox>
                <span>Acepto los <a style={{cursor:'pointer'}} onClick={()=>{setTosVisible(true)}}>Términos y Condiciones</a></span>
            </div>        
          </div>
          <div className="col-12 flex align-items-end justify-content-end mt-4">
            <Button
              type="submit"
              disabled={!recaptchaResponse||!checkedTos}
              loading={loadingSecondInbursement}
              label="Solicitar desembolso"
              className="cursor-pointer"
            /> 
          </div>
        </form>
        </>
      )}

      <Dialog header="Términos y Condiciones"
        visible={tosVisible}
        className="custom-dialog w-full md:w-6"
        onHide={() => setTosVisible(false)}>
        <div className="modal-body" style={{padding:24, margin:0}} ng-click="close()">
        <p>
          Aceptando electrónicamente los presentes términos y condiciones manifiesto en carácter de
          declaración jurada:
        </p>
        <p>
          Que he cumplido las condiciones y plazos pactados para la ejecución de la obra (de conformidad
          al proyecto, planos y demás documentación integrante de la operatoria, presentados para la
          obtención del préstamo) bajo el desembolso anterior.
          </p>
          <p>
          Conocer y aceptar que el Banco podrá en cualquier momento, previo o posterior a la liquidación
          del desembolso que se solicita, inspeccionar la obra y certificar el avance de la misma.
          </p>
          <p>
          Conocer y aceptar que el desembolso solicitado, por el monto acordado (según lo convenido en
          contrato de mutuo), será acreditado en la cuenta de caja de ahorro vinculada al préstamo. Si el
          préstamo hipotecario fue acordado en UVAs, el desembolso será liquidado a su equivalente en
          pesos a la cotización de la UVA del día de la efectivización.
          </p>
          <p>
          Conocer y aceptar que la acreditación del monto referido en la cuenta mencionada constituirá a
          todos los efectos prueba suficiente de liquidación del desembolso solicitado bajo el préstamo.
          </p>

          <p>
          </p>
        </div>

      </Dialog>
    </div>
  );
};
