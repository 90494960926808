import { Button as PrimeButton } from 'primereact/button';
import './Button.scss';

export const Button = (props: any) => {
  const icon = props.icon ? `pi ${props.icon}` : undefined;
  if(!props.label) {
    return (<PrimeButton
      icon={icon}
      rounded
      className={`appto-button-icon cursor-pointer ${props.className}`}
      style={props.styles}
      onClick={props.onClick}
      disabled={props.disabled ?? false}
    />)
  }
  else {
    return (<PrimeButton
      label={props.label}
      icon={icon}
      type={props.type ? props.type : 'button'}
      className={`appto-button cursor-pointer ${props.className}`}
      loading={props.loading}
      disabled={props.disabled}
      onClick={props.onClick}
    />)
  } 
};
