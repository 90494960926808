import axios from 'axios';
import { REQUEST_TYPES } from '../utils/consts';
import { RequestType } from '../pages/loanRequest/types/loanRequestTypes';
const url = window.url ? window.url : process.env.REACT_APP_API_URL as string;

const config = {
  headers: {
    accept: 'application/json, text/plain, */*',
    'accept-language': 'en-US,en;q=0.9',
    'content-type': 'application/json;charset=UTF-8',
  },
  mode: 'cors',
  credentials: 'include',
};

export default {
  validateCuit: async (cuit: string, type: string) => {
    const time = new Date().getTime()
    const { data } = await axios.get(
      `${url}/request/validateCuit/${cuit}/${type}?cachebuster=${time}`,
      config
    );
    return data;
  },
  validateCertificate: async (certificate: string, type: string | undefined) => {
    if (!type) return
    const time = new Date().getTime()
    const { data } = await axios.get(
      `${url}/request/validateCertificate?cachebuster=${time}`,
      { ...config, params: { type, certificate } }
    );
    return data;
  },
  sendRequest: async (type: RequestType | undefined, request: any) => {
    if (!type) return
    const time = new Date().getTime()
    const { data } = await axios.post(
      `${url}/request/${type}?cachebuster=${time}`,
      request,
      config
    );
    return data;
  },
  uploadFile: async (requestId: number, type: string | undefined, file: any) => {
    if (!type) return
    const time = new Date().getTime()
    const { data } = await axios.post(
      `${url}/request/${requestId}/upload/?cachebuster=${time}`,
      file,
      { ...config, headers: { ...config.headers, 'Content-Type': 'multipart/form-data' }, params: { type } }
    );
    return data;
  },
  setSuccess: async (type: RequestType | undefined, requestId: number) => {
    if (!type) return
    const time = new Date().getTime()
    const { data } = await axios.post(
      `${url}/request/${type}/${requestId}/success?cachebuster=${time}`,
      {},
      config
    );
    return data;
  }
};