import { InputText } from 'primereact/inputtext';
import { Button } from '../common/Button';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useMutation } from '@tanstack/react-query';
import { classNames } from 'primereact/utils';

import api from '../../api';
import './RequestInfo.scss';
import { Controller, useForm } from 'react-hook-form';
import { Message } from 'primereact/message';

const SITE_KEY = window.recaptchaApiKey ? window.recaptchaApiKey : process.env.REACT_APP_RECAPTCHA_KEY as string;

export const RequestInfo = (props:any) => {
  const [requestResult, setRequestResult] = useState<any>(null);
  const [recaptchaResponse, setRecaptchaResponse] = useState<string>('');

  const { control, handleSubmit, formState: {} } = useForm();

  const handleRecaptcha = (response: string | null) => {
    if (response) {
      setRecaptchaResponse(response);
    }
  };

  const { isLoading: loadingContact, mutate } = useMutation({
    mutationKey: ['contact'],
    mutationFn: api.postContact,
  });

  const onSend = (data:any) => {
    if(data && data.email && data.name && data.phone) {
      mutate(
        {
          email:data.email,
          name:data.name,
          phone:data.phone,
          recaptchaResponse,
          property: props.propertyId,
        },
        {
          onSuccess: (res) => {
            setRequestResult(res);
          },
        }
      ); 
    }
  };

  return (
    <form onSubmit={handleSubmit(onSend)}>
    {
            requestResult&&requestResult.success &&
            <div className="request-info w-full p-3 md:p-5 flex flex-column w-full align-items-center justify-content-between">
            <div className='col-12'>
                <Message
                  style={{
                      border: 'solid #005f5a',
                      borderWidth: '0 0 0 6px',
                      color: '#005f5a'
                  }}
                  className="border-success w-full justify-content-start"
                  severity="success"
                  text={"¡Gracias por tu interés!"}
                  />         
              </div>
              </div>
    } 
    {
      !requestResult &&
    <div className="request-info w-full p-3 md:p-5 flex flex-column w-full align-items-center justify-content-between">
      <h2 className="m-1">¿Querés conocer más acerca de esta propiedad?</h2>
      <p className="m-2">
        Dejá tus datos y se los enviaremos a la inmobiliaria para que te
        contacten
      </p>
      <div className="grid w-full mt-2 md:mt-5">
        <div className="col-12 md:col-3 flex align-items-end">
          <div className="flex flex-column gap-2 w-full">
            <label htmlFor="type">Nombre</label>
            <Controller name="name" control={control} rules={{ required: 'El nombre es obligatorio' }} render={({ field, fieldState }: any) => (
              <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
            )} />
          </div>
        </div>
        <div className="col-12 md:col-3 flex align-items-end">
          <div className="flex flex-column gap-2 w-full">
            <label htmlFor="type">Email</label>
            <Controller name="email" control={control}  rules={{ required: 'El mail es obligatorio', 
              maxLength: 50,
              pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,         
            }} render={({ field, fieldState }: any) => (
              <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
            )} />
          </div>
        </div>
        <div className="col-12 md:col-3 flex align-items-end">
          <div className="flex flex-column gap-2 w-full">
            <label htmlFor="type">Teléfono</label>
            <Controller name="phone" control={control}  rules={{ required: 'El mail es obligatorio', 
              minLength: 5,
              pattern: /^[0-9]+$/,         
            }} render={({ field, fieldState }: any) => (
              <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
            )} />
          </div>
        </div>
        <div className="col-12 md:col-3 flex align-items-end mt-4 md:mt-0">
            <ReCAPTCHA
              className="recaptcha"
              sitekey={SITE_KEY}
              onChange={handleRecaptcha}
            />
        </div>

        <div className="col-12 flex align-items-center justify-content-center mt-4">
          <Button type="submit" loading={loadingContact} label="Enviar" className="cursor-pointer" />
        </div>

      </div>
    </div>
    }
    </form>
  );
};
