import { Form, Row, Col, Typography, Radio, Input, Alert } from "antd"
import { NewButton } from "../../../components/common/NewButton"
import { useQuery, useQueryClient } from '@tanstack/react-query';
import propertyApi from "../../../api/properties"
import { buildRequiredRule } from "../../../utils/ruleBuilders";
import { useEffect, useState } from "react";


const { Item } = Form
const { Title, Text } = Typography

type IsValidPropType = {
  validProp?: boolean,
  hasRequest?: boolean,
  hasCertificate?: boolean,
  validatedProp?: boolean,
}

const styles = {
  helpText: {
    color: '#464646',
    fontSize: '12px'
  },
  button: {
    fontSize: '20px'
  },
  formTitle: {
    fontSize: '16px',
    width: '100%',
    marginBottom: '20px',
    fontWeight: 700
  }
}

const ALERT_MESSAGES = {
  hasRequest: 'No es posible ingresar la solicitud porque ya existe una solicitud para este inmueble',
  hasCertificate: 'No es posible ingresar la solicitud porque existe un certificado APPTO vigente para este inmueble'
}

export const PropertyValidateForm = (props: any) => {
  const queryClient = useQueryClient()
  const { form, setIsValid, isValid, captchaValue, setExistPrevious, existPrevious } = props
  const [isCompleted, setIsCompleted] = useState<boolean>(false)

  const propertyType = Form.useWatch('propertyType') 
  const propertyRentas = Form.useWatch('propertyRentas') 
  const propertyMatricula = Form.useWatch('propertyMatricula')
  useEffect(() => {
    if (propertyType && propertyRentas && propertyMatricula) setIsCompleted(true)
  }, [propertyType, propertyMatricula, propertyRentas])

  useEffect(() => {
    if (existPrevious) refetch()
  }, [existPrevious])

  const { data: isValidProp, isRefetching, refetch } = useQuery<IsValidPropType>({
    queryKey: ['validateProp'],
    queryFn: () => propertyApi.validateProperty(propertyRentas, propertyMatricula).then(data => ({
      ...data,
      validProp: data.valid,
      validatedProp: true
    })),
    onSuccess: data => {
      if (data.validProp) { 
        setIsValid(true)
        setExistPrevious(false)
      }
    },
    onError: (error: any) => {
      console.log(error)
      queryClient.setQueryData(
        ['validateProp'],
        {
          validProp: false,
          hasRequest: true,
          hasCertificate: false,
          validatedProp: true
        }
      )
      setExistPrevious(false)
    },
    initialData: {
      validProp: false,
      validatedProp: false,
      hasCertificate: false,
      hasRequest: false
    },
    enabled: false
  })

  const { validProp, validatedProp, hasCertificate, hasRequest } = isValidProp

  let messageAlert = ""
  if (hasRequest) messageAlert = ALERT_MESSAGES.hasRequest
  if (hasCertificate) messageAlert = ALERT_MESSAGES.hasCertificate
  
  return (
    <Row gutter={[16, 0]} style={{ width: '100%', margin: '15px auto 0' }}>
      <Title style={styles.formTitle} className="default-font bancor-color" level={3}>
        Datos de la propiedad
      </Title>
      <Col xs={24}>
        <Item name="propertyType" label="Tipo de Propiedad" rules={[buildRequiredRule('Tipo de Propiedad')]}>
          <Radio.Group disabled={isValid}>
            <Radio value={"PH"}>Departamento</Radio>
            <Radio value={"HOUSE"}>Casa</Radio>
            <Radio value={"DUPLEX"}>Dúplex</Radio>
            <Radio value={"LOT"}>Terreno</Radio>
          </Radio.Group>
        </Item>
      </Col>
      <Col xs={24} md={12}>
        <Item
          name="propertyRentas"
          label="Número de rentas"
          help={<Text style={styles.helpText}>Ingrese solo números</Text>}
          rules={[{ required: true }]}>
          <Input
            disabled={isValid}
            placeholder="Número de rentas"
            onChange={e => form.setFieldValue('propertyRentas', e.target.value.replace(/[^0-9]/g, ''))}
          />
        </Item>
      </Col>
      <Col xs={24} md={12}>
        <Item
          name="propertyMatricula"
          label="Número de matrícula"
          help={<Text style={styles.helpText}>Ingrese sólo números y "/" en caso de ser propiedad horizontal</Text>}
          rules={[{ required: true }]}>
          <Input
            disabled={isValid}
            placeholder="Número de matrícula"
            onChange={e => form.setFieldValue('propertyMatricula', e.target.value.replace(/[^0-9/]/g, ''))}
          />
        </Item>
      </Col>
      {((validatedProp && !validProp) || existPrevious) &&
        <Col span={24}>
          <Alert
            style={{ marginTop: '10px' }}
            showIcon
            type="error"
            message={messageAlert}/>
        </Col>
      }
      {!validProp &&
        <Col xs={24} style={{ textAlign: 'center' }}>
          <Row style={{ margin: '25px 0' }} justify='center'>
            {isCompleted &&
              <NewButton
                label="Continuar"
                type="primary"
                disabled={isRefetching || !captchaValue}
                styles={styles.button}
                onClickHandler={() => refetch()}
              />
            }
          </Row>
        </Col>
      }
    </Row>
  )
}