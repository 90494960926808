import { Card as PrimeCard } from 'primereact/card';
import './Card.scss';

export const Card = ({ title, children, header }: any) => {
  const template = () => {
    if (!header)
      return <div className="text-xl font-semibold text-900">{title}</div>;
    else return header;
  };

  return (
    <PrimeCard header={template} className="custom-card mb-4 w-full">
      {children}
    </PrimeCard>
  );
};
