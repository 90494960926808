import { useMutation } from '@tanstack/react-query';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button } from '../common/Button';
import api from '../../api';
import { Message } from 'primereact/message';

const SITE_KEY =  window.recaptchaApiKey ? window.recaptchaApiKey : process.env.REACT_APP_RECAPTCHA_KEY as string;

export const ReaderForm = () => {
  const [requestCode, setRequestCode] = useState('');
  const [requestResult, setRequestResult] = useState<any>(null);
  const [recaptchaResponse, setRecaptchaResponse] = useState<string>('');
  const { isLoading: loadingMyStatus, mutate } = useMutation({
    mutationKey: ['myStatus'],
    mutationFn: api.getMyStatus,
  });

  const handleRecaptcha = (response: string | null) => {
    if (response) {
      setRecaptchaResponse(response);
    }
  };

  const onSearch = () => {
    mutate(
      {
        id: requestCode,
        recaptchaResponse,
      },
      {
        onSuccess: (data) => {
          setRequestResult(data);
        },
      }
    );
  };

  return (
    <div className="grid">
      {requestResult ? (
        <div className='col-12'>
          <Message
            style={{
                borderWidth: '0 0 0 6px',
            }}
            className={`${requestResult.success ? 'border-success':'border-error'} w-full justify-content-start`}
            severity={requestResult.success ? "success": 'error'}
            text={requestResult.success ? requestResult.status : requestResult.message}
            />         
        </div>
      ) : (
        <>
          <div className="col-12">
            <div className="flex flex-column gap-2">
              <label>Ingresá el número de tu solicitud y hacé clic en buscar</label>
              <InputText
                placeholder="Código"
                value={requestCode}
                onInput={(e: any) => setRequestCode(e.target.value)}
                className="w-full"
              />
            </div>
          </div>
          <div className="col-12">
            <div className="flex flex-column gap-2 align-items-center">
              <ReCAPTCHA sitekey={SITE_KEY} onChange={handleRecaptcha} />
            </div>
          </div>
        </>
      )}

      {!requestResult && 
        <div className="col-12 flex align-items-end justify-content-end mt-4">
          <Button
            disabled={!requestCode || !recaptchaResponse}
            loading={loadingMyStatus}
            label="Consultar"
            className="cursor-pointer"
            onClick={onSearch}
          /> 
        </div>
      }

    </div>
  );
};
