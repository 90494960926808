import { Form, Col, Typography, Select, Input, Row, Radio, Checkbox } from "antd"
import { NEIGHBOORHOOD_TYPE, PRICE_RANGES, PROPERTY_ROOM_OPTIONS, PROVINCE_OPTIONS } from "../../../utils/consts";
import { buildRequiredRule, buildMaxRule } from "../../../utils/ruleBuilders";
import propertyApi from "../../../api/properties";
import { useQuery } from "@tanstack/react-query";


const { Item } = Form
const { Title } = Typography

const styles = {
  helpText: {
    color: '#464646',
    fontSize: '12px'
  },
  button: {
    fontSize: '20px'
  },
  formTitle: {
    fontSize: '16px',
    width: '100%',
    marginBottom: '20px',
    fontWeight: 700
  }
}

const neighboorhoodTypeOptions = {
  [NEIGHBOORHOOD_TYPE.OPEN]: 'Abierto',
  [NEIGHBOORHOOD_TYPE.CLOSED]: 'Cerrado'
}

type AmenityType = { id: number, defaultName: string, enabled: boolean, searcheable: boolean}

type FiltersDataType = {
  amenities: AmenityType[],
  measureUnit: string,
  roomTypes: { id: number, name: string, enabled: boolean, searcheable: boolean }[]
  shorcuts: any[]
}

export const PropertyDataForm = (props: any) => {

  // Obtener las amenities
  const { data: filtersData } = useQuery<FiltersDataType>({
    queryKey: ['getAmenities'],
    queryFn: () => propertyApi.getFilters().then(data => data),
    onError: (error: any) => {
      console.log(error)
    },
    refetchOnWindowFocus: false,
    staleTime: 300000
  })

  const amenitiesOptions: { label: string, value: number }[] = []

  if (filtersData) {
    for (const amenity of filtersData.amenities) {
      if (amenity.enabled) amenitiesOptions.push({
        label: amenity.defaultName,
        value: amenity.id
      })
    }
  }

  return (
    <Row gutter={[16, 0]} style={{ width: '100%', margin: '0 auto' }}>
      <Title style={styles.formTitle} className="default-font bancor-color" level={3}>
        Datos del Inmueble
      </Title>
      <Col xs={24} md={8}>
        <Item name="propertyAddress" label="Calle" rules={[buildRequiredRule('Calle'), buildMaxRule(200)]}>
          <Input placeholder="Calle" />
        </Item>
      </Col>
      <Col xs={24} md={4}>
        <Item name="propertyAddressNumber" label="Número" rules={[buildRequiredRule('Número'), buildMaxRule(10)]}>
          <Input placeholder="Número" />
        </Item>
      </Col>
      <Col xs={24} md={6}>
        <Item name="propertyAddressFloor" label="Piso" rules={[buildMaxRule(10)]}>
          <Input placeholder="Piso" />
        </Item>
      </Col>
      <Col xs={24} md={6}>
        <Item name="propertyAddressAppartment" label="Departamento o Unidad Funcional" rules={[buildMaxRule(20)]}>
          <Input placeholder="Departamento o Unidad Funcional" />
        </Item>
      </Col>
      <Col xs={24} md={12}>
        <Item name="propertyNeighborhood" label="Barrio" rules={[buildRequiredRule('Barrio'), buildMaxRule(200)]}>
          <Input placeholder="Barrio" />
        </Item>
      </Col>
      <Col xs={24} md={12}>
        <Item
          name="propertyNeighborhoodType"
          initialValue={NEIGHBOORHOOD_TYPE.OPEN}
          label="Tipo de Barrio"
          rules={[buildRequiredRule('Tipo de Barrio')]}>
          <Radio.Group>
            <Radio value={NEIGHBOORHOOD_TYPE.OPEN}>{neighboorhoodTypeOptions[NEIGHBOORHOOD_TYPE.OPEN]}</Radio>
            <Radio value={NEIGHBOORHOOD_TYPE.CLOSED}>{neighboorhoodTypeOptions[NEIGHBOORHOOD_TYPE.CLOSED]}</Radio>
          </Radio.Group>
        </Item>
      </Col>
      <Col xs={24} md={8}>
        <Item name="propertyCity" label="Localidad" rules={[buildRequiredRule('Localidad'), buildMaxRule(200)]}>
          <Input placeholder="Localidad" />
        </Item>
      </Col>
      <Col xs={24} md={8}>
        <Item
          name="PropertyProvince"
          label="Provincia"
          initialValue={3}
          rules={[buildRequiredRule('Provincia')]}>
          <Select
            showSearch
            filterOption={(input, option) => (option?.label || '').toLowerCase().includes(input.toLowerCase())}
            options={PROVINCE_OPTIONS || []}
            disabled={true}
            placeholder="Provincia"
            />
        </Item>
      </Col>
      <Col xs={24} md={8}>
        <Item name="propertyPostalCode" label="Código Postal" rules={[buildRequiredRule('Código Postal'), buildMaxRule(10)]}>
          <Input placeholder="Código Postal" />
        </Item>
      </Col>
      <Col xs={24} md={8} lg={6}>
        <Item
          name="propertyRooms"
          label="Cantidad de Dormitorios"
          initialValue={1}
          rules={[buildRequiredRule('Cantidad de Dormitorios')]}>
          <Select
            options={PROPERTY_ROOM_OPTIONS}
            placeholder="Cantidad de Dormitorios" />
        </Item>
      </Col>
      <Col xs={24} md={16} lg={18}>
        <Item name="propertyAmenities" label="Comodidades">
          <Checkbox.Group style={{ width: '100%' }}>
            <Row>
              {amenitiesOptions.map(({ value, label }) =>
                <Col key={`opId-${value}`} xs={12} lg={12} xl={6}>
                  <Checkbox value={value}>{label}</Checkbox>
                </Col>  
              )
            }
            </Row>
          </Checkbox.Group>
        </Item>
        <Item name="propertyExtraAmenities" label="Otras" rules={[buildMaxRule(200)]}>
          <Input placeholder="Otras comodidades" />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          name="priceRange"
          label="Rango de Precio"
          rules={[buildRequiredRule('Rango de Precio')]}>
          <Select
            options={PRICE_RANGES || []}
            disabled={!PRICE_RANGES.length}
            placeholder="Rango de Precio" />
        </Item>
      </Col>
    </Row>
  )
}