import { useContext, useEffect, useState } from 'react';
import { Properties } from '../components/home/Properties';
import { FiltersContext } from '../context';
import { useMutation } from '@tanstack/react-query';
import api from '../api/properties';
import { SearchFilters } from '../components/home/SearchFilters';

export const PropertySearch = () => {
  
  const filters = useContext(FiltersContext);
  const [properties, setProperties] = useState([]);
  const [total, setTotal] = useState(0);
  const [isNewSearch, setIsNewSearch] = useState(false)

  const { isLoading: loadingProperties, mutate } = useMutation({
    mutationKey: ['properties'],
    mutationFn: api.getProperties,
  });

  const onSearch = (newSearch: boolean = false) => {
    const newFilters = {
      ...filters,
      random: false,
      radius: '50km',
      offset: newSearch ? 0 : filters.offset
    }
    mutate(
      newFilters,
      {
        onSuccess: (data) => {
          setProperties(data.data);
          setTotal(data.total);
          setIsNewSearch(newSearch)
        },
      }
    );
  };

  const onPage = (event: any) => {
    filters.max = event.rows;
    filters.offset = event.first;
    onSearch(false);
  }

  useEffect(() => onSearch(true), []);

  return (
    <div
      className="w-full flex flex-column align-items-center"
      style={{ position: 'relative', backgroundColor: '#F8F8F8' }}>
        <div className="w-full flex flex-column align-items-center lg:mt-8 lg:px-7 px-4">
          <div className="w-full flex flex-column align-items-center mt-6">
            <SearchFilters onSearch={onSearch} isLoading={loadingProperties} />
            <Properties
              data={properties}
              initial={false}
              loading={loadingProperties}
              total={total}
              isNewSearch={isNewSearch}
              onPage={onPage} />
          </div>
        </div>
      </div>
  );
};
