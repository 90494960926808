import { useRef, useState } from "react";
import { Form, Row, Col, Typography, Input, Alert, AlertProps } from "antd"
import { useQuery } from '@tanstack/react-query';
import requestApi from "../../../api/requests"
import { buildRequiredRule } from "../../../utils/ruleBuilders";
import { formStyles } from "../LoanRequest";
import { NewButton } from "../../../components/common/NewButton";


const { Item } = Form
const { Title, Text } = Typography

export const CertificateValidation = (props: any) => {

  const { type, form, setShowForm, showForm, setIsCertificateExpired } = props

  const [showAlert, setShowAlert] = useState({ show: false, message: '' })
  const previousCertificateRef = useRef<string | null>(null)
  const certificate = Form.useWatch('certificate', form)

  const { isRefetching, refetch } = useQuery({
    queryKey: ['validateCertificate'],
    queryFn: () => requestApi.validateCertificate(certificate, type).then(data => data),
    onError: (error: any) => { console.log(error) },
    onSuccess: ({ valid, message, expired }) => {
      if (!valid) {
        setShowAlert({
          show: true,
          message
        })

        setShowForm(false)
      } else if (expired) {
        setIsCertificateExpired(true)
        setShowAlert({
          show: true,
          message: 'El código que ingresaste ya no tiene vigencia por ello deberás cargar adicionalmente el informe de matrícula del inmueble actualizado.'
        })
        setShowForm(true)
      }
      else {
        setShowAlert({ show: false, message: '' })
        setShowForm(true)
      }
      return
    },
    enabled: false,
    refetchOnWindowFocus: false
  })
  
  const alertConfig: AlertProps = {
    style: { marginBottom: '10px' },
    message: showAlert.message,
    showIcon: true,
    type: 'warning'
  }

  return (
    <>
      <Row gutter={[16, 0]} style={{ width: '100%', margin: '20px auto 0' }}>
        <Title style={formStyles.formTitle} className="default-font bancor-color" level={3}>
          Código Appto Vivienda
        </Title>
        <Col span={24}>
          <Item
            label={
              <Text className="default-font">
                Código <Text style={formStyles.helpText}>(Ingresa el código tal cual figura en el mail)</Text>
              </Text>
            }
            name="certificate"
            rules={[buildRequiredRule('Código Appto Vivienda')]}>
            <Input
              disabled={showForm}
              placeholder="Código Appto Vivienda"
              onChange={() => setShowAlert({ show: false, message: '' })}
            />
          </Item>
        </Col>
        {showAlert.show && !isRefetching && 
          <Col span={24}>
            <Alert { ...alertConfig } />
          </Col>
        }
      </Row>
      {!showForm &&
        <Row style={{ width: '100%', margin: '25px 0' }} justify='center'>
          <NewButton
            label="Continuar"
            type="primary"
            disabled={!certificate}
            styles={{ fontSize: '20px' }}
            onClickHandler={() => {
              if (certificate !== previousCertificateRef.current) {
                previousCertificateRef.current = certificate;
                refetch()
              }
            }}
          />
        </Row>
      }
    </>
  )
}