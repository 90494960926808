import { useContext, useEffect, useState } from 'react';
import { FiltersContext } from '../../context';
import { Dropdown } from 'primereact/dropdown';
import { Button } from '../common/Button';
import { useQuery } from '@tanstack/react-query';
import { AutoComplete } from 'primereact/autocomplete';
import { MultiSelect } from 'primereact/multiselect';
import apiFilters from '../../api/filters';
import apiNeighborhoods from '../../api/neighborhoods';
import './Filters.scss';
import { Slider } from 'primereact/slider';


const neighborhoodTypes = [
  { id: 'open', name: 'Abierto' },
  { id: 'closed', name: 'Cerrado' }
];
const roomsOptions = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4 +' },
];

export const SearchFilters = (props: any) => {

  const filters = useContext(FiltersContext);
  const [query, setQuery] = useState<any>(null);
  const [neighborhoods, setNeighborhoods] = useState<any[]>([]);
  const [types, setTypes] = useState([] as number[]);
  const [neighborhood, setNeighborhood] = useState(undefined);
  const [rooms, setRooms] = useState(undefined);
  const [amenities, setAmenities] = useState([] as number[]);
  const [priceRange, setPriceRange] = useState([20000, 400000]);

  useEffect(() => {
    if (!query) setQuery(filters.query)
    if (props?.newQueryLocation) setQuery(props?.newQueryLocation)
  }, [props?.newQueryLocation])

  const { data: filtersData } = useQuery({
    queryKey: ['filters'],
    queryFn: apiFilters.getFilters,
  });

  const { data: neighborhoodsData, isLoading : isLoadingNeighboorhoods,  refetch } = useQuery({
    queryKey: ['neighborhoods', query],
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: apiNeighborhoods.getNeighborhoods,
  });

  const applyFilters = () => {
    filters.amenities = amenities;
    filters.budgetMin = priceRange[0];
    filters.budgetMax = priceRange[1];
    filters.neighborhood = neighborhood;
    filters.rooms = rooms;
    filters.types = types;
    filters.initial = false;
    props.onSearch(true);
  };

  const handleInput = (e: any) => {
    setQuery(e.query.toLowerCase());
    search();
  };

  const handleSelect = (e: any) => {
    setQuery(e.value.name);
    filters.initial = false;
    filters.random = false;
    filters.query = e.value.name
    filters.longitude = e.value.longitude;
    filters.latitude = e.value.latitude;
    filters.location = {
      formatted_address: e.value.address,
    };
  };

  const search = () => {
    if(query.length > 4) {
      refetch();
    }
  }
  
  useEffect(() => {
    setNeighborhoods(neighborhoodsData);
  }, [neighborhoodsData]);

  const resetFilters = (e: any) => {
    e.preventDefault();
    filters.amenities = [];
    filters.budgetMin = 20000;
    filters.budgetMax = 400000;
    filters.neighborhood = undefined;
    filters.rooms = undefined;
    filters.types = [];
    setTypes(filters.types);
    setRooms(filters.rooms);
    setAmenities(filters.amenities);
    setPriceRange([filters.budgetMin, filters.budgetMax]);
    setNeighborhood(filters.neighborhood);
    props.onReset();
  };

  useEffect(() => {
    if (filters) {
      setTypes(filters.types);
      setRooms(filters.rooms);
      setAmenities(filters.amenities);
      setPriceRange([filters.budgetMin, filters.budgetMax]);
      setNeighborhood(filters.neighborhood);
    }
  }, [filters]);


  return (
    <div className='grid nested-grid w-full px-3'>
      <div className="md:col-10 p-0">
        <div className='search-filters grid'>
          <div className='col-12 md:col-5 lg:col-5 xl:col-6 mb-3 px-1 md:px-2'>
            <AutoComplete
              className="border-none custom-input w-full flex-1"
              field="name"
              value={query}
              placeholder="Ingresá ubicación..."
              suggestions={neighborhoods}
              completeMethod={handleInput}
              onChange={(e) => setQuery(e.value)}
              onSelect={handleSelect}
            />
          </div>
          <div className='col-12 sm:col-4 md:col-3 lg:col-3 xl:col-2 mb-3 px-1 md:px-2'>
            <span className="p-float-label">
              <Dropdown
                inputId='neighborhoodType'
                id="neighborhood"
                showClear
                value={neighborhood}
                onChange={(e) => setNeighborhood(e.value)}
                options={neighborhoodTypes}
                optionLabel="name"
                optionValue="id"
                className="w-full border-none"
              />
              <label htmlFor="neighborhoodType">Tipo de barrio</label>
            </span>
          </div>
          <div className="col-12 sm:col-8 md:col-4 lg:col-4 mb-3 px-1 md:px-2">
            <span className="p-float-label">  
              <MultiSelect
                className="w-full border-none"
                id="types"
                value={types}
                onChange={e => setTypes(e.value)}
                options={filtersData?.roomTypes}
                optionLabel="name"
                display='chip'
                optionValue="id"
                filter
                maxSelectedLabels={3}
              />
              <label htmlFor="types">Tipo de propiedad</label>
            </span>
          </div>
          <div className='col-12 sm:col-4 md:col-3 lg:col-3 xl:col-2 mb-3 px-1 md:px-2'>
            <span className="p-float-label">
              <Dropdown
                inputId='roomsQuantity'
                id="rooms"
                showClear
                value={rooms}
                onChange={(e) => setRooms(e.value)}
                options={roomsOptions}
                optionLabel="name"
                optionValue="id"
                className="w-full border-none"
              />
              <label htmlFor="roomsQuantity">Habitaciones</label>
            </span>
          </div>
          <div className="col-12 sm:col-8 md:col-6 lg:col-6 xl:col-7 mb-3 px-1 md:px-2">
            <span className="p-float-label">  
              <MultiSelect
                className="w-full border-none"
                id="amenities"
                value={amenities}
                onChange={e => setAmenities(e.value)}
                options={filtersData?.amenities.map((am: any) => ({ id: am.id, name: am.defaultName }))}
                optionLabel="name"
                optionValue="id"
                display="chip"
                filter
              />
              <label htmlFor="amenities">Comodidades</label>
            </span>
          </div>
          <div className='col-12 md:col-3 lg:col-3 relative mb-3 px-1 md:px-2 px-3 md:px-2'>
            <label
              className='absolute'
              htmlFor="type"
              style={{ fontSize: '12px', top: '-0.75rem', left: '0.75rem', color: '#6c757d' }}>
              Rango de precios
            </label>
            <Slider
              className="mt-3 mx-2"
              value={priceRange as [number, number]}
              min={20000}
              max={400000}
              onChange={(e) => setPriceRange(e.value as number[])}
              range
            />
            <div className="w-full flex align-items-center justify-content-between mt-2">
              <span className="text-xs bancor-color">{priceRange[0]} UVAs</span>
              <span className="text-xs bancor-color">{priceRange[1]} UVAs</span>
            </div>
          </div>
        </div>
      </div>
      <div className='md:col-2 p-0' style={{ textAlign: 'right' }}>
        <Button
          label="Buscar"
          icon="pi-search"
          className="cursor-pointer w-full md:w-auto"
          onClick={applyFilters}
        />
      </div>
    </div>
  );
};
