import { Row, Typography, Form, Radio, Col, Input } from "antd"
import { formStyles, availableFormFields } from "../LoanRequest"
import { REQUEST_TYPES } from "../../../utils/consts"
import { AddressForm } from "./AddressForm"
import { buildMaxRule, buildRequiredRule } from "../../../utils/ruleBuilders"
import { VisibleFormItem } from "./VisibleFormItem"


const SEED_LOT_TYPES = {
  PROGRAM_WITH_DEED: 'PROGRAM_WITH_DEED',
  OWN_WITH_DEED: 'OWN_WITH_DEED',
  FAMILY: 'FAMILY'
}

const DEVELOP_LOT_TYPES = {
  EMPTY: 'EMPTY',
  BUILT_LESS_THAN_50: 'BUILT_LESS_THAN_50'
}

const PROPERTY_TYPES = { HOUSE: 'HOUSE', PH: 'PH' }
const TITLE_BY_TYPES = {
  [REQUEST_TYPES.seed]: 'Información del Inmueble sobre el que se Construirá e Hipotecará y destino de los fondos',
  [REQUEST_TYPES["develop-buy"]]: 'Información del Inmueble a adquirir',
  [REQUEST_TYPES.develop]: 'Información del Inmueble sobre el que se Construirá e Hipotecará y destino de los fondos',
  [REQUEST_TYPES.build]: 'Información del Inmueble sobre el que se Construirá e Hipotecará y destino de los fondos',
  [REQUEST_TYPES.finish]: 'Información del Inmueble sobre el que se Construirá e Hipotecará y destino de los fondos', 
  [REQUEST_TYPES.reform]: 'Información del Inmueble sobre el que se Contruirá e Hipotecará y destino de los fondos', 
  [REQUEST_TYPES.buy]: 'Información de la propiedad a ser Comprada e Hipotecada'
}

const lotPropertySectionTitleByTypes = {
  default: 'Construyo sobre (Tildar la opción que corresponda)',
  [REQUEST_TYPES.develop]: 'El lote donde voy a construir se encuentra',
}

const { Title } =  Typography
const { Item } = Form

const lotTypesRadioOptions = {
  [REQUEST_TYPES.seed]: [
    { value: SEED_LOT_TYPES.PROGRAM_WITH_DEED, label: 'Lote de Programa Lo Tengo' },
    { value: SEED_LOT_TYPES.OWN_WITH_DEED, label: 'Lote propio, CON escritura o CON boleto de compra venta' },
    { value: SEED_LOT_TYPES.FAMILY, label: 'Lote de un familiar directo' }
  ],
  [REQUEST_TYPES.develop]: [
    { label: 'Baldío sin construcción', value: DEVELOP_LOT_TYPES.EMPTY },
    { label: 'Con una construcción acorde al plano presentado y con un grado de avance menor al 50%', value: DEVELOP_LOT_TYPES.BUILT_LESS_THAN_50 }
  ]

}

const propertyTypeOptions = [
  { value: PROPERTY_TYPES.HOUSE, label: 'Casa / Duplex' },
  { value: PROPERTY_TYPES.PH, label: 'Propiedad Horizontal' },
]

export const PropertyForm = (props: any) => {
  const { form, type, entityPrefix, isFormBlocked } = props
  
  const lotType = Form.useWatch(`${entityPrefix}LotType`, form)

  return (
    <Row gutter={[16, 0]} style={{ width: '100%', margin: '20px auto 0' }}>
      <Title style={formStyles.formTitle} className="default-font bancor-color" level={3}>
        {TITLE_BY_TYPES[type] ?? 'Datos de la propiedad'}
      </Title>
      <VisibleFormItem type={type} fieldName={availableFormFields.DEVELOPER_NAME} prefix={entityPrefix}>
        <Row  gutter={[16, 0]} style={{ width: '100%', margin: '0 auto' }}>
          <Col xs={12}>
            <Item
              name={`developerName`}
              label="Empresa desarrollista con acuerdo con Bancor"
              rules={[buildRequiredRule('Empresa desarrollista'), buildMaxRule(200)]}>
              <Input
                disabled={isFormBlocked}
                placeholder="Empresa desarrollista con acuerdo con Bancor"
              />
            </Item>
          </Col>
        </Row>
      </VisibleFormItem>
      <VisibleFormItem type={type} fieldName={availableFormFields.PROPERTY_LOT_TYPE} prefix={entityPrefix}>
        <Title style={formStyles.formTitle} className="default-font bancor-color" level={3}>
          {lotPropertySectionTitleByTypes[type] || lotPropertySectionTitleByTypes.default}
        </Title>
        <Col span={24}>
          <Item
            style={{ margin: '0 0 20px' }}
            name={`${entityPrefix}LotType`}
            initialValue={lotTypesRadioOptions[type]?.length ? lotTypesRadioOptions[type][0]?.value : null}
            rules={[buildRequiredRule('Tipo de lote')]}>
            <Radio.Group disabled={isFormBlocked}>
              {lotTypesRadioOptions[type]?.length && lotTypesRadioOptions[type].map((option :any) =><Radio key={option.value} value={option.value}>{option.label}</Radio>)}
            </Radio.Group>
          </Item>
        </Col>
      </VisibleFormItem>
      <AddressForm type={type} entityPrefix={entityPrefix} isFormBlocked={isFormBlocked} />
      <VisibleFormItem type={type} fieldName={availableFormFields.PROPERTY_TYPE} prefix={entityPrefix}>
        <Col span={24}>
          <Item
            name={`${entityPrefix}Type`}
            label="Tipo de propiedad"
            initialValue={PROPERTY_TYPES.HOUSE}
            rules={[buildRequiredRule('Tipo de propiedad')]}>
            <Radio.Group disabled={isFormBlocked}>
              {propertyTypeOptions.map((option :any) =><Radio key={option.value} value={option.value}>{option.label}</Radio>)}
            </Radio.Group>
          </Item>
        </Col>
      </VisibleFormItem>
      {lotType === SEED_LOT_TYPES.FAMILY &&
        <>
          <Col xs={24} md={12}>
            <Item
              name={`${entityPrefix}FamilyOwnerName`}
              label="Nombre y Apellido del Titular del terreno"
              rules={[buildRequiredRule('Nombre y Apellido'), buildMaxRule(200)]}>
              <Input
                disabled={isFormBlocked}
                placeholder="Nombre y Apellido del Titular del terreno"
              />
            </Item>
          </Col>
          <Col xs={24} md={12}>
            <Item
              name={`${entityPrefix}FamilyOwnerRelationship`}
              label="Parentesco con el titular o su cónyuge/conviviente"
              rules={[buildRequiredRule('Parentesco'), buildMaxRule(200)]}>
              <Input
                disabled={isFormBlocked}
                placeholder="Parentesco con el titular o su cónyuge/conviviente"
              />
            </Item>
          </Col>
        </>
      }
    </Row>
  )
}