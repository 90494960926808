import { Alert, Breadcrumb, Col, Divider, Form, Modal, Radio, Row, Select, Typography, notification, Grid } from "antd"
import { useParams, useNavigate, useLocation } from "react-router"
import { useState, useEffect } from "react"
import { UploadDocumentationForm } from "./components/UploadDocumentationForm"
import { NewButton } from "../../components/common/NewButton"
import { useMutation } from "@tanstack/react-query"
import { QUALIFY_LOAN_OPTIONS, QUALIFY_TYPES, REQUEST_TYPES, entityPrefixNames } from '../../utils/consts';
import requestsApi from "../../api/requests"
import { PersonalDataForm } from "./components/PersonalDataForm"
import { EmploymentDataForm } from "./components/EmploymentDataForm"
import { buildRequiredRule } from "../../utils/ruleBuilders"
import { PropertyForm } from "./components/PropertyForm"
import { InitialModal } from "./components/InitialModal"
import useRecaptcha from "../../hooks/useReCaptcha"
import { EntityPrefixType, FieldNameType, RequestType, SectionNameType } from './types/loanRequestTypes';
import { CertificateValidation } from "./components/CertificateValidation"


type ParamsType = { type?: RequestType }

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const { Text, Title } = Typography
const { Item } = Form
const { useBreakpoint } = Grid

export const formStyles = {
  helpText: {
    color: '#464646',
    fontSize: '12px'
  },
  button: {
    fontSize: '20px'
  },
  formTitle: {
    fontSize: '16px',
    width: '100%',
    marginBottom: '20px',
    fontWeight: 700
  }
}

// static consts and functions for intern forms
export const availableFormFields = {
  LAST_NAME: "LAST_NAME",
  NAME: "NAME",
  CUIL: 'CUIL',
  ADDRESS: 'ADDRESS',
  ADDRESS_NUMBER: 'ADDRESS_NUMBER',
  ADDRESS_FLOOR: 'ADDRESS_FLOOR',
  ADDRESS_APPARTMENT: 'ADDRESS_APPARTMENT',
  NEIGHBOORHOOD: 'NEIGHBOORHOOD',
  CITY: 'CITY',
  PROVINCE: 'PROVINCE',
  POSTAL_CODE: 'POSTAL_CODE',
  FAMILIAR_GROUP: 'FAMILIAR_GROUP',
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  PROPERTY_LOT_TYPE: 'PROPERTY_LOT_TYPE',
  PROPERTY_TYPE: 'PROPERTY_TYPE',
  DEVELOPER_NAME: 'DEVELOPER_NAME',
  SPOUSE_COSIGNER: "spouseIsCoSigner",
  EMPLOYER_PHONE: 'EMPLOYER_PHONE',
  EMPLOYER_CUIT: 'EMPLOYER_CUIT',
  INDEPENDENT_YEARS: 'INDEPENDENT_YEARS',
  INDEPENDENT_START: 'INDEPENDENT_START'
}

export const availableSections = {
  REQUESTER_INFO: "REQUESTER_INFO",
  SPOUSE_INFO: "SPOUSE_INFO",
  COSIGNER_ONE_INFO: "COSIGNER_ONE_INFO",
  COSIGNER_TWO_INFO: "COSIGNER_TWO_INFO",
  PROPERTY: "PROPERTY",
  LOAN_TYPE: "LOAN_TYPE",
  CERTIFICATE_VALIDATION: "CERTIFICATE"
}

const SECTIONS_NO_VISIBLE_BY_TYPE = {
  [REQUEST_TYPES.seed]: {
    [availableSections.COSIGNER_TWO_INFO]: true,
    [availableSections.CERTIFICATE_VALIDATION]: true,
    [availableSections.COSIGNER_TWO_INFO]: true,
  },
  [REQUEST_TYPES.qualify]: {
    [availableSections.COSIGNER_TWO_INFO]: true,
    [availableSections.PROPERTY]: true,
    [availableSections.CERTIFICATE_VALIDATION]: true,
  },
  [REQUEST_TYPES.build]: {
    [availableSections.CERTIFICATE_VALIDATION]: true,
    [availableSections.COSIGNER_TWO_INFO]: true,
  },
  [REQUEST_TYPES.finish]: {
    [availableSections.CERTIFICATE_VALIDATION]: true,
    [availableSections.COSIGNER_TWO_INFO]: true,
  },
  [REQUEST_TYPES.reform]: {
    [availableSections.CERTIFICATE_VALIDATION]: true,
    [availableSections.COSIGNER_TWO_INFO]: true,
  },
  [REQUEST_TYPES.develop]: {
    [availableSections.CERTIFICATE_VALIDATION]: true,
    [availableSections.COSIGNER_TWO_INFO]: true,
  },
  [REQUEST_TYPES["develop-buy"]]: {
    [availableSections.CERTIFICATE_VALIDATION]: true,
    [availableSections.COSIGNER_TWO_INFO]: true,
  },
  [REQUEST_TYPES.buy]: {
    [availableSections.COSIGNER_TWO_INFO]: true,
  },
}

const FIELDS_NO_VISIBLE = {
  [availableFormFields.ADDRESS]: {
    [REQUEST_TYPES.seed]: [
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ]
  },
  [availableFormFields.ADDRESS_FLOOR]: {
    [REQUEST_TYPES.seed]: [
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ]
  },
  [availableFormFields.ADDRESS_NUMBER]: {
    [REQUEST_TYPES.seed]: [
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ]
  },
  [availableFormFields.ADDRESS_APPARTMENT]: {
    [REQUEST_TYPES.seed]: [
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ]
  },
  [availableFormFields.NEIGHBOORHOOD]: {
    [REQUEST_TYPES.seed]: [
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ]
  },
  [availableFormFields.CITY]: {
    [REQUEST_TYPES.seed]: [
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ]
  },
  [availableFormFields.PROVINCE]: {
    [REQUEST_TYPES.seed]: [
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ]
  },
  [availableFormFields.POSTAL_CODE]: {
    [REQUEST_TYPES.seed]: [
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ]
  },
  [availableFormFields.EMAIL]: {
    [REQUEST_TYPES.seed]: [
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ]
  },
  [availableFormFields.PHONE]: {
    [REQUEST_TYPES.seed]: [
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ]
  },
  [availableFormFields.FAMILIAR_GROUP]: {
    [REQUEST_TYPES.seed]: [
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ],
    [REQUEST_TYPES.reform]: [
      entityPrefixNames.SPOUSE,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO
    ],
    [REQUEST_TYPES.build]: [
      entityPrefixNames.SPOUSE,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO
    ],
    [REQUEST_TYPES.finish]: [
      entityPrefixNames.SPOUSE,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO
    ],
    [REQUEST_TYPES["develop-buy"]]: [
      entityPrefixNames.SPOUSE
    ],
    [REQUEST_TYPES.develop]: [
      entityPrefixNames.SPOUSE
    ],
    [REQUEST_TYPES.buy]: [
      entityPrefixNames.SPOUSE
    ],
    [REQUEST_TYPES.qualify]: [
      entityPrefixNames.SPOUSE,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO
    ],
  },
  [availableFormFields.PROPERTY_LOT_TYPE]: {
    [REQUEST_TYPES.reform]: [
      entityPrefixNames.PROPERTY,
    ],
    [REQUEST_TYPES.build]: [
      entityPrefixNames.PROPERTY,
    ],
    [REQUEST_TYPES.finish]: [
      entityPrefixNames.PROPERTY,
    ],
    [REQUEST_TYPES.buy]: [
      entityPrefixNames.PROPERTY,
    ],
    [REQUEST_TYPES["develop-buy"]]: [
      entityPrefixNames.PROPERTY,
    ]
  },
  [availableFormFields.DEVELOPER_NAME]: {
    [REQUEST_TYPES.seed]: [
      entityPrefixNames.PROPERTY,
    ],
    [REQUEST_TYPES.build]: [
      entityPrefixNames.PROPERTY,
    ],
    [REQUEST_TYPES.finish]: [
      entityPrefixNames.PROPERTY,
    ],
    [REQUEST_TYPES.buy]: [
      entityPrefixNames.PROPERTY,
    ],
    [REQUEST_TYPES.reform]: [
      entityPrefixNames.PROPERTY,
    ],
  },
  [availableFormFields.PROPERTY_TYPE]: {
    [REQUEST_TYPES.seed]: [
      entityPrefixNames.PROPERTY,
    ],
    [REQUEST_TYPES["develop-buy"]]: [
      entityPrefixNames.PROPERTY,
    ]
  },
  [availableFormFields.EMPLOYER_PHONE]: {
    [REQUEST_TYPES.qualify]: [
      entityPrefixNames.REQUESTER,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ],
    [REQUEST_TYPES.seed]: [
      entityPrefixNames.REQUESTER,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ],
    [REQUEST_TYPES.develop]: [
      entityPrefixNames.REQUESTER,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ],
    [REQUEST_TYPES["develop-buy"]]: [
      entityPrefixNames.REQUESTER,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ],
    [REQUEST_TYPES.build]: [
      entityPrefixNames.REQUESTER,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ],
    [REQUEST_TYPES.finish]: [
      entityPrefixNames.REQUESTER,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ],
    [REQUEST_TYPES.buy]: [
      entityPrefixNames.REQUESTER,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ],
    [REQUEST_TYPES.reform]: [
      entityPrefixNames.REQUESTER,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ]
  },
  [availableFormFields.INDEPENDENT_START]: {
    [REQUEST_TYPES.seed]: [
      entityPrefixNames.REQUESTER,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ],
    [REQUEST_TYPES.develop]: [
      entityPrefixNames.REQUESTER,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ],
    [REQUEST_TYPES["develop-buy"]]: [
      entityPrefixNames.REQUESTER,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ],
    [REQUEST_TYPES.build]: [
      entityPrefixNames.REQUESTER,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ],
    [REQUEST_TYPES.finish]: [
      entityPrefixNames.REQUESTER,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ],
    [REQUEST_TYPES.buy]: [
      entityPrefixNames.REQUESTER,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ],
    [REQUEST_TYPES.reform]: [
      entityPrefixNames.REQUESTER,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ]
  },
  [availableFormFields.INDEPENDENT_YEARS]: {
    [REQUEST_TYPES.qualify]: [
      entityPrefixNames.REQUESTER,
      entityPrefixNames.COSIGNER_ONE,
      entityPrefixNames.COSIGNER_TWO,
      entityPrefixNames.SPOUSE
    ],
  },
}

const isSectionVisible = (type: RequestType, section: SectionNameType, forceShow: boolean | undefined = undefined) => {
  
  if (forceShow !== undefined && !forceShow) return false
  if (type === REQUEST_TYPES.qualify && section === availableSections.LOAN_TYPE) return true
  else if(section !== availableSections.LOAN_TYPE) return !SECTIONS_NO_VISIBLE_BY_TYPE[type]?.[section]
}

export const isFieldVisible = (fieldName: FieldNameType, type: RequestType, prefix: EntityPrefixType) => {
  if (!FIELDS_NO_VISIBLE[fieldName]?.[type]) return true
  const noVisibleByType = FIELDS_NO_VISIBLE[fieldName]?.[type]
  if (!noVisibleByType?.length) return true
  if (noVisibleByType.includes(prefix)) return false
  return true
}

const isValidLoanType = (loanType: string | null) => {
  if (!loanType) return false
  return Object.values(QUALIFY_TYPES).includes(loanType)
}

const INITIAL_SHOW_FORM_BY_TYPES = {
  [REQUEST_TYPES.buy]: false,
  default: true
}

const TITLE_BY_TYPES = {
  [REQUEST_TYPES.seed]: 'Solicitá tu préstamo para Programa Semilla',
  [REQUEST_TYPES["develop-buy"]]: 'Solicitá tu préstamo para Compra - Acuerdo Desarrollista',
  [REQUEST_TYPES.develop]: 'Solicitá tu préstamo para Construcción en lote con boleto de Compra Venta - Solo para lotes ubicados en loteos con ACUERDO DESARROLLISTA',
  [REQUEST_TYPES.build]: 'Solicitá tu préstamo para Construcción', 
  [REQUEST_TYPES.reform]: 'Solicitá tu préstamo para Ampliación',
  [REQUEST_TYPES.finish]: 'Solicitá tu préstamo para Terminación',
  [REQUEST_TYPES.qualify]: 'Quiero saber si califico',
  [REQUEST_TYPES.buy]: 'Solicitá tu préstamo para la compra'
}

const TURN_OFF_MODAL_BY_TYPES = {
  [REQUEST_TYPES.qualify]: true,
  [REQUEST_TYPES.seed]: true,
  default: true
}

type TitleByPrefixType = {
  [key in typeof entityPrefixNames[keyof typeof entityPrefixNames]]: {
    [key in typeof REQUEST_TYPES[keyof typeof REQUEST_TYPES] | "default"]: any
  }
}

const TITLE_BY_PREFIX_TYPES: TitleByPrefixType = {
  [entityPrefixNames.COSIGNER_ONE]: {
    default:
      <>
        ¿Tenés Garante?
        <br />
        <small style={{ fontWeight: 'normal', color: 'black' }}>
        Garante es una persona que suma ingresos pero no es cónyuge ni conviviente. Sólo están permitidos: Padre/Madre/Hijos.
        </small>
      </>,
    // ELIGIENDO UN TYPE EN PARTICULAR, podes setear un título de sección en forma específica para ese type
    //[REQUEST_TYPES.seed]: 'Título específico.',
  },
  [entityPrefixNames.COSIGNER_TWO]: {
    default: '¿Tenés Garante 2 (Suma ingresos)?'
  },
  [entityPrefixNames.SPOUSE]: {
    default: '¿Tiene cónyuge/conviviente?'
  }
}

export const CIVIL_STATUS_TYPES = {
  CIVIL_STATUS: 'civil_estatus',
  FAMILIAR_GROUP: 'familiar_group'
}

const styles = {
  title: {
    color: '#464646',
    fontSize: '24px',
    fontWeight: 'normal',
    lineHeight: 1,
    marginTop: '25px'
  }
}

const labelSubmitButton = "Confirmar Solicitud"

export const LoanRequest = () => {
  const { renderRecaptcha, captchaValue } = useRecaptcha();
  const { type } = useParams<ParamsType>()
  const query = useQuery()
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [finished, setFinished] = useState<boolean>(false)
  const [sendingError, setSendingError] = useState<boolean>(false)
  const [showInitialModal, setShowInitialModal] = useState<boolean>(!(type && TURN_OFF_MODAL_BY_TYPES[type]))
  const [isFormBlocked, setIsFormBlocked] = useState({ invalidCuils: [], blocked: false })
  const [showForm, setShowForm] = useState<boolean>((type && INITIAL_SHOW_FORM_BY_TYPES.hasOwnProperty(type)) ? INITIAL_SHOW_FORM_BY_TYPES[type] : INITIAL_SHOW_FORM_BY_TYPES.default)
  const [isCertificateExpired, setIsCertificateExpired] = useState<boolean>(false)
  const { sm, md, lg } = useBreakpoint()
  const minHeight = lg ? 'calc(100vh - 118px)' : md ? 'calc(100vh - 260px)' : sm ? 'calc(100vh - 430px)' : 'calc(100vh - 90px)'

  if (type && !Object.values(REQUEST_TYPES).includes(type)) navigate('/')
  
  // Esto impide que se pueda acceder al formulario anterior de calificaciones. 
  // TODO. Eliminar todo lo vinculado a REQUEST_TYPES.qualify y los loanType. 
  // En el use Effect dejar de escuchar los cambios y eliminar las loanTypeOptions que se usan en esa sección (eliminar la seccion entera.)
  if (type && type === REQUEST_TYPES.qualify) navigate('/')

  const loanType = query.get('loanType');
  if (type === REQUEST_TYPES.qualify && !isValidLoanType(loanType)) navigate('/')

  useEffect(() => {
    setFinished(false)
    setSendingError(false)
    setIsFormBlocked({ invalidCuils: [], blocked: false })
    setShowForm((type && INITIAL_SHOW_FORM_BY_TYPES.hasOwnProperty(type)) ? INITIAL_SHOW_FORM_BY_TYPES[type] : INITIAL_SHOW_FORM_BY_TYPES.default)
    setShowInitialModal(!(type && TURN_OFF_MODAL_BY_TYPES[type]))
    setIsCertificateExpired(false)

    form.resetFields()
    window.scrollTo(0, 0); 
  }, [type, loanType])

  // watchs
  const hasSpouse = Form.useWatch('hasSpouse', form)
  const hasCosignerOne = Form.useWatch('hasCosignerOne', form)
  const hasCosignerTwo = Form.useWatch('hasCosignerTwo', form)
  
  const loanOptions = loanType ? QUALIFY_LOAN_OPTIONS[loanType] : []

  const { mutate: sendRequest, isLoading: isSending } = useMutation({
    mutationFn: async (values: any) => {
      // primero creamos la nueva solicitud
      const { documentation, ...payload }: { documentation: Record<string, any[]>, [key: string]: any; } = values
      payload.type = type
      const { success: requestSuccess, request, existPrevious } = await requestsApi.sendRequest(type, payload)
      // recorremos todas las imagenes disponibles y las guardamos en el servidor.
      // recorremos todos los documents
      if (!request?.id) return ({ success: false, request, existPrevious })
      const uploadErrors: any[] = []
      for (const [fileType, files] of Object.entries(documentation)) {
        if (files?.length > 0) {
          for (const file of files) {
            const formData = new FormData()
            formData.append('file', file.originFileObj)
            const { success } = await requestsApi.uploadFile(request.id, fileType, formData)
            if (!success) {
              //que hacemos cuando haya un error en las imagenes
              uploadErrors.push({ fileType, file })
            }
          }
        }
      }

      // generamos el JSON y enviamos al CRM las cosas.
      const { success } = await requestsApi.setSuccess(type, request.id)

      return { success, request, uploadErrors }
    },
    onSuccess: ({ success, request, existPrevious }) => {
      if (success && request.id) setFinished(true)
      else if (existPrevious.length > 0) {
        setIsFormBlocked({ invalidCuils: existPrevious, blocked: true })
      }
      else setSendingError(true)
    },
    onError: error => {
      console.log(error)
      setSendingError(true)
    },
    onSettled: () => setShowConfirm(false)
  })

  const onFinish = async (values: any)  => sendRequest(values)

  const title = type ? TITLE_BY_TYPES[type] : 'Solicitá tu Préstamo'
  if (!type) return <></>

  return (
    <Row style={{ position: 'relative', backgroundColor: '#F8F8F8', fontFamily: 'open-sans', padding: '0 10px', minHeight }}>
      <div className="w-full flex flex-column align-items-center lg:mt-8 lg:px-7 md:px-5 px-4">
        <Row style={{ marginTop: '1.5rem', width: '100%' }}>
          <Breadcrumb 
            items={[
              { title: <Text className="bancor-color default-font" style={{ cursor: 'pointer', fontSize: '12px' }} onClick={() => navigate('/')}>Appto</Text> },
              { title: <Text className="default-font" style={{ fontSize: '12px' }}>{title}</Text> }
            ]}
          />
        </Row>
        {!finished &&
          <>
            <Row style={{ paddingBottom: '15px', width: '100%' }} justify='start'>
              <Title style={styles.title} className="default-font" level={4}>{title}</Title>
              <Divider style={{ margin: '5px 0' }}/>  
              <Form
                style={{ width: '100%' }}
                form={form}
                onFinish={onFinish}
                layout="vertical"
                validateTrigger="onBlur">
                {!isFormBlocked.blocked && showForm &&
                  <Row style={{ width: '100%',margin: '10px 0 0' }} justify='center'>
                    <Alert
                      icon={
                        <span style={{ fontWeight: 'bold', lineHeight: 1, marginTop: '6px' }}>*</span>
                      }
                      showIcon
                      style={{ width: '100%', fontSize: '13px' }}
                      type="warning"
                      message="Debés completar todos los campos obligatorios (*) para poder confirmar la solicitud"/>
                  </Row>
                }
                {isSectionVisible(type, availableSections.CERTIFICATE_VALIDATION) &&
                  <CertificateValidation
                    type={type}
                    showForm={showForm}
                    setShowForm={setShowForm}
                    setIsCertificateExpired={setIsCertificateExpired}
                    form={form} />
                }
                {isSectionVisible(type, availableSections.LOAN_TYPE) &&
                  <Row gutter={[16, 0]} style={{ width: '100%', margin: '20px auto 0' }}>
                    <Title style={formStyles.formTitle} className="default-font bancor-color" level={3}>
                      Seleccioná tu préstamo
                    </Title>
                    <Col span={24}>
                      <Item
                        noStyle
                        name="qualifyType"
                        initialValue={loanOptions?.[0]?.value}
                        rules={[buildRequiredRule('Préstamo')]}>
                        <Select
                          showSearch
                          style={{ width: '250px' }}
                          filterOption={(input, option) => (option?.label || '').toLowerCase().includes(input.toLowerCase())}
                          options={loanType ? loanOptions : []}
                          disabled={isFormBlocked.blocked}
                          placeholder="Seleccioná tu préstamo" />
                      </Item>
                    </Col>
                  </Row>
                }
                {isSectionVisible(type, availableSections.REQUESTER_INFO, showForm) &&
                  <>
                    <PersonalDataForm
                      form={form}
                      entityPrefix={entityPrefixNames.REQUESTER}
                      isFormBlocked={isFormBlocked.blocked}
                      invalidCuils={isFormBlocked.invalidCuils}
                      setIsFormBlocked={setIsFormBlocked}
                      type={type} />
                    <EmploymentDataForm form={form} entityPrefix={entityPrefixNames.REQUESTER} isFormBlocked={isFormBlocked.blocked} type={type} />
                  </>
                }
                {isSectionVisible(type, availableSections.SPOUSE_INFO, showForm) &&
                  <Row gutter={[16, 0]} style={{ width: '100%', margin: '20px auto 0' }}>
                    <Title style={formStyles.formTitle} className="default-font bancor-color" level={3}>
                      {TITLE_BY_PREFIX_TYPES[entityPrefixNames.SPOUSE].default}
                    </Title>
                    <Col span={24}>
                      <Item
                        noStyle
                        name="hasSpouse"
                        initialValue={false}
                        rules={[buildRequiredRule('Tiene cónyuge')]}>
                        <Radio.Group disabled={isFormBlocked.blocked}>
                          <Radio value={true}>Sí, tengo</Radio>
                          <Radio value={false}>No, no tengo</Radio>
                        </Radio.Group>
                      </Item>
                    </Col>
                  </Row>
                }
                {hasSpouse &&
                  <>
                    <PersonalDataForm
                      form={form}
                      entityPrefix={entityPrefixNames.SPOUSE}
                      isFormBlocked={isFormBlocked.blocked}
                      invalidCuils={isFormBlocked.invalidCuils}
                      setIsFormBlocked={setIsFormBlocked}
                      type={type} />
                    <EmploymentDataForm form={form} entityPrefix={entityPrefixNames.SPOUSE} isFormBlocked={isFormBlocked.blocked} type={type}/>
                  </>
                }
                {isSectionVisible(type, availableSections.COSIGNER_ONE_INFO, showForm) &&
                  <Row gutter={[16, 0]} style={{ width: '100%', margin: '20px auto 0' }}>
                    <Title style={formStyles.formTitle} className="default-font bancor-color" level={3}>
                      {TITLE_BY_PREFIX_TYPES[entityPrefixNames.COSIGNER_ONE].hasOwnProperty(type)
                        ? TITLE_BY_PREFIX_TYPES[entityPrefixNames.COSIGNER_ONE][type]
                        : TITLE_BY_PREFIX_TYPES[entityPrefixNames.COSIGNER_ONE].default
                      }
                    </Title>
                    <Col span={24}>
                      <Item
                        noStyle
                        name="hasCosignerOne"
                        initialValue={false}
                        rules={[buildRequiredRule('Tiene Garante')]}>
                        <Radio.Group disabled={isFormBlocked.blocked}>
                          <Radio value={true}>Sí, tengo</Radio>
                          <Radio value={false}>No, no tengo</Radio>
                        </Radio.Group>
                      </Item>  
                    </Col>
                  </Row>
                }
                {hasCosignerOne &&
                  <>
                    <PersonalDataForm
                      form={form}
                      entityPrefix={entityPrefixNames.COSIGNER_ONE}
                      isFormBlocked={isFormBlocked.blocked}
                      invalidCuils={isFormBlocked.invalidCuils}
                      setIsFormBlocked={setIsFormBlocked}
                      type={type} />
                    <EmploymentDataForm form={form} entityPrefix={entityPrefixNames.COSIGNER_ONE} isFormBlocked={isFormBlocked.blocked} type={type}/>
                  </>
                }
                {isSectionVisible(type, availableSections.COSIGNER_TWO_INFO, showForm) &&
                  <Row gutter={[16, 0]} style={{ width: '100%', margin: '20px auto 0' }}>
                    <Title style={formStyles.formTitle} className="default-font bancor-color" level={3}>
                      {TITLE_BY_PREFIX_TYPES[entityPrefixNames.COSIGNER_ONE].hasOwnProperty(type)
                        ? TITLE_BY_PREFIX_TYPES[entityPrefixNames.COSIGNER_ONE][type]
                        : TITLE_BY_PREFIX_TYPES[entityPrefixNames.COSIGNER_ONE].default
                      }
                    </Title>
                    <Col span={24}>
                      <Item
                        noStyle
                        name="hasCosignerTwo"
                        initialValue={false}
                        rules={[buildRequiredRule('Tiene Garante 2')]}>
                        <Radio.Group disabled={isFormBlocked.blocked}>
                          <Radio value={true}>Sí, tengo</Radio>
                          <Radio value={false}>No, no tengo</Radio>
                        </Radio.Group>
                      </Item>
                    </Col>
                  </Row>
                }
                {hasCosignerTwo &&
                  <>
                    <PersonalDataForm
                      form={form}
                      entityPrefix={entityPrefixNames.COSIGNER_TWO}
                      isFormBlocked={isFormBlocked.blocked}
                      invalidCuils={isFormBlocked.invalidCuils}
                      setIsFormBlocked={setIsFormBlocked}
                      type={type} />
                    <EmploymentDataForm form={form} entityPrefix={entityPrefixNames.COSIGNER_TWO} isFormBlocked={isFormBlocked.blocked} type={type}/>
                  </>
                }
                {isSectionVisible(type, availableSections.PROPERTY, showForm) &&
                  <PropertyForm form={form} type={type} entityPrefix={entityPrefixNames.PROPERTY} isFormBlocked={isFormBlocked.blocked}/>
                }
                {showForm &&
                  <UploadDocumentationForm
                    isCertificateExpired={isCertificateExpired}
                    form={form}
                    type={type}
                    isFormBlocked={isFormBlocked.blocked}/>
                }
              </Form>
            </Row>
            {isFormBlocked.blocked && showForm &&
              <Row gutter={[16, 0]} style={{ width: '100%',margin: '0 0 30px' }} justify='center'>
                <Alert
                  showIcon
                  style={{ width: '100%' }}
                  type="warning"
                  message={
                    <Text className="default-font" style={{ fontSize: '13px' }}>
                      La solicitud no puede ser enviada. Por favor comunicate con el Centro de Negocios a:
                      <a className="bancor-color" style={{ fontWeight: '600' }} href="mailto:solicitudesappto@bancor.com.ar"> solicitudesappto@bancor.com.ar</a>
                    </Text>
                  }/>
              </Row>
            }
            {!isFormBlocked.blocked && showForm && renderRecaptcha() }
            {!isFormBlocked.blocked && showForm &&
              <Row style={{ width: '100%',margin: '10px 0 40px' }} justify='center'>
                <NewButton
                  styles={{ fontSize: '20px' }}
                  type="primary"
                  label={labelSubmitButton}
                  disabled={isSending || !captchaValue}
                  onClickHandler={async () => {
                    try {
                      const values = await form.validateFields();
                      if (values) {
                        setShowConfirm(true)
                        setSendingError(false)
                      }
                    } catch (errors: any) {
                      notification.error({ duration: 3, message: "Es necesario cargar todos los campos obligatorios." })
                      const fieldName: string = errors.errorFields[0].name
                      form.scrollToField(fieldName)
                    }
                  }}/>
              </Row>
            }
          </>
        }
        {finished &&
          <Row style={{ width: '100%', height: 'calc(100vh - 250px)', alignItems: 'flex-start' }} justify='center'>
            <Row style={{ paddingBottom: '15px', width: '100%', marginTop: '60px' }} justify='center'>
              <Title style={styles.title} className="default-font" level={1}>
                ¡Te confirmamos que recibimos tu solicitud!
              </Title>
              <Divider style={{ marginTop: '5px' }}/>
              <Text className="default-font bancor-color">
                El equipo de Bancor se comunicará con vos a la brevedad.
              </Text>
            </Row>
          </Row>
        }
        {sendingError &&
          <Row style={{ paddingBottom: '15px', width: '100%' }} justify='center'>
            <Title style={styles.title} className="default-font" level={1}>
              Lamentablemente no se pudo procesar su solicitud
            </Title>
            <Divider style={{ marginTop: '5px' }}/>
            <Text className="default-font bancor-color">
              Por favor, intente nuevamente más tarde.
            </Text>
          </Row>
        }
        {showConfirm &&
          <Modal
            title="Confirma el envío de la solicitud?"
            open={showConfirm}
            closable={false}
            keyboard={false}
            maskClosable={false}
            onCancel={() => setShowConfirm(false)}
            onOk={form.submit}
            okButtonProps={{ disabled: isSending }}
            cancelButtonProps={{ disabled: isSending }}
            okText="Confirmo."
            cancelText="Cancelo."
          >
            <Row style={{ width: '100%', margin: '25px 0' }}>
              <Text>
                Recuerde que este proceso puede demorar unos minutos, no cierre el navegador hasta recibir la confirmación.
              </Text>
            </Row>
          </Modal>
        }
        {showInitialModal &&
          <InitialModal open={showInitialModal} onCancel={() => setShowInitialModal(false)} />
        }
      </div>
    </Row>
  )
}