// Contexts.js
import { createContext } from 'react';
import { DEFAULT_CENTER, DEFAULT_RADIUS } from '../utils/consts';


export const FiltersContext = createContext({
  location: {},
  sizeMin: 1,
  sizeMax: 200,
  bedroomSizeMin: 1,
  bedroomSizeMax: 40,
  bedroomSizeMinFt: 1,
  bedroomSizeMaxFt: 140,
  budgetMin: 20000,
  budgetMax: 400000,
  latitude: DEFAULT_CENTER.LAT,
  longitude: DEFAULT_CENTER.LNG,
  types: [] as number[],
  bedTypes: [] as number[],
  amenities: [] as number[],
  shortcuts: [] as number[],
  rentSale: 'both',
  furnished: false,
  neighborhood: undefined,
  rooms: undefined,
  max: 48,
  radius: DEFAULT_RADIUS,
  offset: 0,
  initial: false,
  random: true,
  query: ''
});
